//gemini: In page 1 of this pdf, return market updates for detached, condo, townhomess, for feb of 2025 2024 and one-year-change in json format of {"February 2025": { "Vancouver East": {detached: {"total_active_listings": {"2025": 537, "2024": 410, "one-year-change": "+31.0%"}}}}}. dont need to process table with JAnuary header. do the same for all of these files and add them to this json
export const marketDataGVRpercityAllMonths = {
	'February 2025': {
		'Burnaby North': {
			detached: {
				total_active_listings: {
					2025: '144',
					2024: '86',
					'one-year-change': '+ 67.4%'
				},
				sales: {
					2025: '18',
					2024: '16',
					'one-year-change': '+ 12.5%'
				},
				days_on_market_average: {
					2025: '44',
					2024: '52',
					'one-year-change': '- 15.4%'
				},
				mls_hpi_benchmark_price: {
					2025: '$2,085,400',
					2024: '$2,049,200',
					'one-year-change': '+ 1.8%'
				}
			},
			condo: {
				total_active_listings: {
					2025: '530',
					2024: '328',
					'one-year-change': '+ 61.6%'
				},
				sales: {
					2025: '94',
					2024: '85',
					'one-year-change': '+ 10.6%'
				},
				days_on_market_average: {
					2025: '33',
					2024: '33',
					'one-year-change': '0.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$732,000',
					2024: '$761,400',
					'one-year-change': '- 3.9%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: '44',
					2024: '36',
					'one-year-change': '+ 22.2%'
				},
				sales: {
					2025: '12',
					2024: '19',
					'one-year-change': '- 36.8%'
				},
				days_on_market_average: {
					2025: '12',
					2024: '33',
					'one-year-change': '- 63.6%'
				},
				mls_hpi_benchmark_price: {
					2025: '$911,000',
					2024: '$907,300',
					'one-year-change': '+ 0.4%'
				}
			}
		},
		tsawaassen: {
			detached: {
				total_active_listings: {
					2025: 124,
					2024: 79,
					'one-year-change': '+ 57.0%'
				},
				sales: {
					2025: 6,
					2024: 11,
					'one-year-change': '- 45.5%'
				},
				days_on_market_average: {
					2025: 32,
					2024: 54,
					'one-year-change': '- 40.7%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,654,800',
					2024: '$1,554,600',
					'one-year-change': '+ 6.4%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 79,
					2024: 39,
					'one-year-change': '+ 102.6%'
				},
				sales: {
					2025: 15,
					2024: 15,
					'one-year-change': '0.0%'
				},
				days_on_market_average: {
					2025: 47,
					2024: 32,
					'one-year-change': '+ 46.9%'
				},
				mls_hpi_benchmark_price: {
					2025: '$619,200',
					2024: '$680,900',
					'one-year-change': '- 9.1%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 37,
					2024: 23,
					'one-year-change': '+ 60.9%'
				},
				sales: {
					2025: 6,
					2024: 8,
					'one-year-change': '- 25.0%'
				},
				days_on_market_average: {
					2025: 33,
					2024: 25,
					'one-year-change': '+ 32.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,007,800',
					2024: '$1,007,700',
					'one-year-change': '+ 0.0%'
				}
			}
		},
		whistler: {
			detached: {
				total_active_listings: {
					2025: 86,
					2024: 71,
					'one-year-change': '+ 21.1%'
				},
				sales: {
					2025: 5,
					2024: 5,
					'one-year-change': '0.0%'
				},
				days_on_market_average: {
					2025: 98,
					2024: 101,
					'one-year-change': '- 3.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$2,159,300',
					2024: '$2,312,500',
					'one-year-change': '- 6.6%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 103,
					2024: 92,
					'one-year-change': '+ 12.0%'
				},
				sales: {
					2025: 27,
					2024: 28,
					'one-year-change': '- 3.6%'
				},
				days_on_market_average: {
					2025: 52,
					2024: 47,
					'one-year-change': '+ 10.6%'
				},
				mls_hpi_benchmark_price: {
					2025: '$588,000',
					2024: '$736,000',
					'one-year-change': '- 20.1%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 73,
					2024: 69,
					'one-year-change': '+ 5.8%'
				},
				sales: {
					2025: 8,
					2024: 11,
					'one-year-change': '- 27.3%'
				},
				days_on_market_average: {
					2025: 93,
					2024: 29,
					'one-year-change': '+ 220.7%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,384,800',
					2024: '$1,643,200',
					'one-year-change': '- 15.7%'
				}
			}
		},
		squamish: {
			detached: {
				total_active_listings: {
					2025: '58',
					2024: '72',
					'one-year-change': '- 19.4%'
				},
				sales: {
					2025: '18',
					2024: '18',
					'one-year-change': '0.0%'
				},
				days_on_market_average: {
					2025: '82',
					2024: '35',
					'one-year-change': '+ 134.3%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,464,000',
					2024: '$1,464,500',
					'one-year-change': '- 0.0%'
				}
			},
			condo: {
				total_active_listings: {
					2025: '64',
					2024: '49',
					'one-year-change': '+ 30.6%'
				},
				sales: {
					2025: '10',
					2024: '5',
					'one-year-change': '+ 100.0%'
				},
				days_on_market_average: {
					2025: '12',
					2024: '17',
					'one-year-change': '- 29.4%'
				},
				mls_hpi_benchmark_price: {
					2025: '$544,000',
					2024: '$646,200',
					'one-year-change': '- 15.8%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: '38',
					2024: '30',
					'one-year-change': '+ 26.7%'
				},
				sales: {
					2025: '20',
					2024: '11',
					'one-year-change': '+ 81.8%'
				},
				days_on_market_average: {
					2025: '33',
					2024: '44',
					'one-year-change': '- 25.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$863,200',
					2024: '$1,011,000',
					'one-year-change': '- 14.6%'
				}
			}
		},
		'West Vancouver': {
			detached: {
				total_active_listings: {
					2025: 436,
					2024: 417,
					'one-year-change': '+ 4.6%'
				},
				sales: {
					2025: 29,
					2024: 40,
					'one-year-change': '- 27.5%'
				},
				days_on_market_average: {
					2025: 55,
					2024: 47,
					'one-year-change': '+ 17.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$3,127,700',
					2024: '$3,096,400',
					'one-year-change': '+ 1.0%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 98,
					2024: 100,
					'one-year-change': '- 2.0%'
				},
				sales: {
					2025: 7,
					2024: 12,
					'one-year-change': '- 41.7%'
				},
				days_on_market_average: {
					2025: 35,
					2024: 34,
					'one-year-change': '+ 2.9%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,215,800',
					2024: '$1,371,300',
					'one-year-change': '- 11.3%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 28,
					2024: 15,
					'one-year-change': '+ 86.7%'
				},
				sales: {
					2025: 1,
					2024: 2,
					'one-year-change': '- 50.0%'
				},
				days_on_market_average: {
					2025: 1,
					2024: 27,
					'one-year-change': '- 96.3%'
				},
				mls_hpi_benchmark_price: {
					2025: '$0',
					2024: '$0',
					'one-year-change': '--'
				}
			}
		},
		'vancouver west': {
			detached: {
				total_active_listings: {
					2025: 646,
					2024: 518,
					'one-year-change': '+ 24.7%'
				},
				sales: {
					2025: 36,
					2024: 67,
					'one-year-change': '- 46.3%'
				},
				days_on_market_average: {
					2025: 47,
					2024: 42,
					'one-year-change': '+ 11.9%'
				},
				mls_hpi_benchmark_price: {
					2025: '$3,493,200',
					2024: '$3,397,900',
					'one-year-change': '+ 2.8%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 1738,
					2024: 1425,
					'one-year-change': '+ 22.0%'
				},
				sales: {
					2025: 236,
					2024: 262,
					'one-year-change': '- 9.9%'
				},
				days_on_market_average: {
					2025: 43,
					2024: 43,
					'one-year-change': '0.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$815,700',
					2024: '$839,500',
					'one-year-change': '- 2.8%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 253,
					2024: 205,
					'one-year-change': '+ 23.4%'
				},
				sales: {
					2025: 27,
					2024: 31,
					'one-year-change': '- 12.9%'
				},
				days_on_market_average: {
					2025: 28,
					2024: 46,
					'one-year-change': '- 39.1%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,414,300',
					2024: '$1,420,500',
					'one-year-change': '- 0.4%'
				}
			}
		},
		'BURNABY SOUTH': {
			detached: {
				total_active_listings: {
					2025: 141,
					2024: 98,
					'one-year-change': '+ 43.9%'
				},
				sales: {
					2025: 11,
					2024: 16,
					'one-year-change': '- 31.3%'
				},
				days_on_market_average: {
					2025: 29,
					2024: 25,
					'one-year-change': '+ 16.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$2,207,300',
					2024: '$2,184,400',
					'one-year-change': '+ 1.0%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 392,
					2024: 281,
					'one-year-change': '+ 39.5%'
				},
				sales: {
					2025: 52,
					2024: 78,
					'one-year-change': '- 33.3%'
				},
				days_on_market_average: {
					2025: 43,
					2024: 37,
					'one-year-change': '+ 16.2%'
				},
				mls_hpi_benchmark_price: {
					2025: '$823,100',
					2024: '$840,500',
					'one-year-change': '- 2.1%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 41,
					2024: 37,
					'one-year-change': '+ 10.8%'
				},
				sales: {
					2025: 9,
					2024: 9,
					'one-year-change': '0.0%'
				},
				days_on_market_average: {
					2025: 47,
					2024: 18,
					'one-year-change': '+ 161.1%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,108,700',
					2024: '$1,074,100',
					'one-year-change': '+ 3.2%'
				}
			}
		},
		'port moody': {
			detached: {
				total_active_listings: {
					2025: 98,
					2024: 58,
					'one-year-change': '+ 69.0%'
				},
				sales: {
					2025: 10,
					2024: 7,
					'one-year-change': '+ 42.9%'
				},
				days_on_market_average: {
					2025: 36,
					2024: 24,
					'one-year-change': '+ 50.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$2,089,700',
					2024: '$2,028,100',
					'one-year-change': '+ 3.0%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 92,
					2024: 45,
					'one-year-change': '+ 104.4%'
				},
				sales: {
					2025: 19,
					2024: 31,
					'one-year-change': '- 38.7%'
				},
				days_on_market_average: {
					2025: 35,
					2024: 25,
					'one-year-change': '+ 40.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$718,000',
					2024: '$724,600',
					'one-year-change': '- 0.9%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 30,
					2024: 21,
					'one-year-change': '+ 42.9%'
				},
				sales: {
					2025: 9,
					2024: 8,
					'one-year-change': '+ 12.5%'
				},
				days_on_market_average: {
					2025: 31,
					2024: 17,
					'one-year-change': '+ 82.4%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,011,300',
					2024: '$1,025,900',
					'one-year-change': '- 1.4%'
				}
			}
		},
		'BURNANBY EAST': {
			detached: {
				total_active_listings: {
					2025: 36,
					2024: 33,
					'one-year-change': '+ 9.1%'
				},
				sales: {
					2025: 6,
					2024: 8,
					'one-year-change': '- 25.0%'
				},
				days_on_market_average: {
					2025: 12,
					2024: 30,
					'one-year-change': '- 60.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,959,700',
					2024: '$1,907,600',
					'one-year-change': '+ 2.7%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 78,
					2024: 54,
					'one-year-change': '+ 44.4%'
				},
				sales: {
					2025: 9,
					2024: 13,
					'one-year-change': '- 30.8%'
				},
				days_on_market_average: {
					2025: 36,
					2024: 48,
					'one-year-change': '- 25.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$784,900',
					2024: '$789,600',
					'one-year-change': '- 0.6%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 23,
					2024: 10,
					'one-year-change': '+ 130.0%'
				},
				sales: {
					2025: 5,
					2024: 3,
					'one-year-change': '+ 66.7%'
				},
				days_on_market_average: {
					2025: 56,
					2024: 6,
					'one-year-change': '+ 833.3%'
				},
				mls_hpi_benchmark_price: {
					2025: '$915,800',
					2024: '$890,500',
					'one-year-change': '+ 2.8%'
				}
			}
		},
		'Vancouver East': {
			detached: {
				total_active_listings: {
					2025: 537,
					2024: 410,
					'one-year-change': '+ 31.0%'
				},
				sales: {
					2025: 55,
					2024: 64,
					'one-year-change': '- 14.1%'
				},
				days_on_market_average: {
					2025: 40,
					2024: 35,
					'one-year-change': '+ 14.3%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,862,500',
					2024: '$1,833,000',
					'one-year-change': '+ 1.6%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 457,
					2024: 417,
					'one-year-change': '+ 9.6%'
				},
				sales: {
					2025: 92,
					2024: 117,
					'one-year-change': '- 21.4%'
				},
				days_on_market_average: {
					2025: 25,
					2024: 29,
					'one-year-change': '- 13.8%'
				},
				mls_hpi_benchmark_price: {
					2025: '$684,800',
					2024: '$707,700',
					'one-year-change': '- 3.2%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 98,
					2024: 100,
					'one-year-change': '- 2.0%'
				},
				sales: {
					2025: 26,
					2024: 21,
					'one-year-change': '+ 23.8%'
				},
				days_on_market_average: {
					2025: 17,
					2024: 23,
					'one-year-change': '- 26.1%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,169,800',
					2024: '$1,109,700',
					'one-year-change': '+ 5.4%'
				}
			}
		},
		'Maple Ridge': {
			detached: {
				total_active_listings: {
					2025: 471,
					2024: 426,
					'one-year-change': '+ 10.6%'
				},
				sales: {
					2025: 55,
					2024: 69,
					'one-year-change': '- 20.3%'
				},
				days_on_market_average: {
					2025: 39,
					2024: 39,
					'one-year-change': '0.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,250,400',
					2024: '$1,269,800',
					'one-year-change': '- 1.5%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 142,
					2024: 184,
					'one-year-change': '- 22.8%'
				},
				sales: {
					2025: 35,
					2024: 31,
					'one-year-change': '+ 12.9%'
				},
				days_on_market_average: {
					2025: 30,
					2024: 26,
					'one-year-change': '+ 15.4%'
				},
				mls_hpi_benchmark_price: {
					2025: '$522,500',
					2024: '$531,000',
					'one-year-change': '- 1.6%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 96,
					2024: 90,
					'one-year-change': '+ 6.7%'
				},
				sales: {
					2025: 38,
					2024: 43,
					'one-year-change': '- 11.6%'
				},
				days_on_market_average: {
					2025: 36,
					2024: 34,
					'one-year-change': '+ 5.9%'
				},
				mls_hpi_benchmark_price: {
					2025: '$771,100',
					2024: '$771,500',
					'one-year-change': '- 0.1%'
				}
			}
		},
		'Metro Vancouver': {
			detached: {
				total_active_listings: {
					2025: '4,692',
					2024: '3,841',
					'one-year-change': '+ 22.2%'
				},
				sales: {
					2025: '478',
					2024: '561',
					'one-year-change': '- 14.8%'
				},
				days_on_market_average: {
					2025: '45',
					2024: '47',
					'one-year-change': '- 4.3%'
				},
				mls_hpi_benchmark_price: {
					2025: '$2,006,100',
					2024: '$1,970,700',
					'one-year-change': '+ 1.8%'
				}
			},
			condo: {
				total_active_listings: {
					2025: '5,750',
					2024: '4,315',
					'one-year-change': '+ 33.3%'
				},
				sales: {
					2025: '977',
					2024: '1,093',
					'one-year-change': '- 10.6%'
				},
				days_on_market_average: {
					2025: '37',
					2024: '34',
					'one-year-change': '+ 8.8%'
				},
				mls_hpi_benchmark_price: {
					2025: '$747,500',
					2024: '$768,800',
					'one-year-change': '- 2.8%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: '1,425',
					2024: '1,085',
					'one-year-change': '+ 31.3%'
				},
				sales: {
					2025: '291',
					2024: '316',
					'one-year-change': '- 7.9%'
				},
				days_on_market_average: {
					2025: '33',
					2024: '32',
					'one-year-change': '+ 3.1%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,087,100',
					2024: '$1,100,600',
					'one-year-change': '- 1.2%'
				}
			}
		},
		'New Westminster': {
			detached: {
				total_active_listings: {
					2025: '100',
					2024: '82',
					'one-year-change': '+ 22.0%'
				},
				sales: {
					2025: '11',
					2024: '10',
					'one-year-change': '+ 10.0%'
				},
				days_on_market_average: {
					2025: '42',
					2024: '42',
					'one-year-change': '0.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,574,200',
					2024: '$1,561,200',
					'one-year-change': '+ 0.8%'
				}
			},
			condo: {
				total_active_listings: {
					2025: '299',
					2024: '177',
					'one-year-change': '+ 68.9%'
				},
				sales: {
					2025: '69',
					2024: '63',
					'one-year-change': '+ 9.5%'
				},
				days_on_market_average: {
					2025: '32',
					2024: '29',
					'one-year-change': '+ 10.3%'
				},
				mls_hpi_benchmark_price: {
					2025: '$657,600',
					2024: '$653,600',
					'one-year-change': '+ 0.6%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: '28',
					2024: '34',
					'one-year-change': '- 17.6%'
				},
				sales: {
					2025: '8',
					2024: '5',
					'one-year-change': '+ 60.0%'
				},
				days_on_market_average: {
					2025: '52',
					2024: '13',
					'one-year-change': '+ 300.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$950,700',
					2024: '$935,500',
					'one-year-change': '+ 1.6%'
				}
			}
		},
		'port coquitlam': {
			detached: {
				total_active_listings: {
					2025: 134,
					2024: 101,
					'one-year-change': '+ 32.7%'
				},
				sales: {
					2025: 18,
					2024: 15,
					'one-year-change': '+ 20.0%'
				},
				days_on_market_average: {
					2025: 28,
					2024: 26,
					'one-year-change': '+ 7.7%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,409,400',
					2024: '$1,376,200',
					'one-year-change': '+ 2.4%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 81,
					2024: 71,
					'one-year-change': '+ 14.1%'
				},
				sales: {
					2025: 28,
					2024: 32,
					'one-year-change': '- 12.5%'
				},
				days_on_market_average: {
					2025: 24,
					2024: 27,
					'one-year-change': '- 11.1%'
				},
				mls_hpi_benchmark_price: {
					2025: '$630,700',
					2024: '$643,500',
					'one-year-change': '- 2.0%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 39,
					2024: 25,
					'one-year-change': '+ 56.0%'
				},
				sales: {
					2025: 9,
					2024: 16,
					'one-year-change': '- 43.8%'
				},
				days_on_market_average: {
					2025: 20,
					2024: 20,
					'one-year-change': '0.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$956,300',
					2024: '$938,500',
					'one-year-change': '+ 1.9%'
				}
			}
		},
		'north vancover': {
			detached: {
				total_active_listings: {
					2025: 229,
					2024: 201,
					'one-year-change': '+ 13.9%'
				},
				sales: {
					2025: 48,
					2024: 42,
					'one-year-change': '+ 14.3%'
				},
				days_on_market_average: {
					2025: 35,
					2024: 20,
					'one-year-change': '+ 75.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$2,232,600',
					2024: '$2,185,400',
					'one-year-change': '+ 2.2%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 311,
					2024: 233,
					'one-year-change': '+ 33.5%'
				},
				sales: {
					2025: 78,
					2024: 91,
					'one-year-change': '- 14.3%'
				},
				days_on_market_average: {
					2025: 28,
					2024: 14,
					'one-year-change': '+ 100.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$804,100',
					2024: '$810,500',
					'one-year-change': '- 0.8%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 104,
					2024: 75,
					'one-year-change': '+ 38.7%'
				},
				sales: {
					2025: 21,
					2024: 26,
					'one-year-change': '- 19.2%'
				},
				days_on_market_average: {
					2025: 16,
					2024: 25,
					'one-year-change': '- 36.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,366,100',
					2024: '$1,336,700',
					'one-year-change': '+ 2.2%'
				}
			}
		},
		Ladner: {
			detached: {
				total_active_listings: {
					2025: 79,
					2024: 65,
					'one-year-change': '+ 21.5%'
				},
				sales: {
					2025: 11,
					2024: 10,
					'one-year-change': '+ 10.0%'
				},
				days_on_market_average: {
					2025: 38,
					2024: 79,
					'one-year-change': '- 51.9%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,398,600',
					2024: '$1,356,700',
					'one-year-change': '+ 3.1%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 22,
					2024: 4,
					'one-year-change': '+ 450.0%'
				},
				sales: {
					2025: 7,
					2024: 8,
					'one-year-change': '- 12.5%'
				},
				days_on_market_average: {
					2025: 37,
					2024: 18,
					'one-year-change': '+ 105.6%'
				},
				mls_hpi_benchmark_price: {
					2025: '$624,500',
					2024: '$699,400',
					'one-year-change': '- 10.7%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 34,
					2024: 14,
					'one-year-change': '+ 142.9%'
				},
				sales: {
					2025: 11,
					2024: 5,
					'one-year-change': '+ 120.0%'
				},
				days_on_market_average: {
					2025: 22,
					2024: 21,
					'one-year-change': '+ 4.8%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,041,400',
					2024: '$996,600',
					'one-year-change': '+ 4.5%'
				}
			}
		},
		richmond: {
			detached: {
				total_active_listings: {
					2025: '497',
					2024: '437',
					'one-year-change': '+ 13.7%'
				},
				sales: {
					2025: '40',
					2024: '69',
					'one-year-change': '- 42.0%'
				},
				days_on_market_average: {
					2025: '27',
					2024: '56',
					'one-year-change': '- 51.8%'
				},
				mls_hpi_benchmark_price: {
					2025: '$2,139,000',
					2024: '$2,177,000',
					'one-year-change': '- 1.7%'
				}
			},
			condo: {
				total_active_listings: {
					2025: '731',
					2024: '504',
					'one-year-change': '+ 45.0%'
				},
				sales: {
					2025: '98',
					2024: '107',
					'one-year-change': '- 8.4%'
				},
				days_on_market_average: {
					2025: '42',
					2024: '36',
					'one-year-change': '+ 16.7%'
				},
				mls_hpi_benchmark_price: {
					2025: '$733,800',
					2024: '$750,400',
					'one-year-change': '- 2.2%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: '266',
					2024: '150',
					'one-year-change': '+ 77.3%'
				},
				sales: {
					2025: '39',
					2024: '55',
					'one-year-change': '- 29.1%'
				},
				days_on_market_average: {
					2025: '39',
					2024: '45',
					'one-year-change': '- 13.3%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,109,300',
					2024: '$1,138,300',
					'one-year-change': '- 2.5%'
				}
			}
		},
		'pitt meadows': {
			detached: {
				total_active_listings: {
					2025: 53,
					2024: 46,
					'one-year-change': '+ 15.2%'
				},
				sales: {
					2025: 8,
					2024: 10,
					'one-year-change': '- 20.0%'
				},
				days_on_market_average: {
					2025: 14,
					2024: 39,
					'one-year-change': '- 64.1%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,179,600',
					2024: '$1,322,100',
					'one-year-change': '- 10.8%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 19,
					2024: 11,
					'one-year-change': '+ 72.7%'
				},
				sales: {
					2025: 8,
					2024: 7,
					'one-year-change': '+ 14.3%'
				},
				days_on_market_average: {
					2025: 75,
					2024: 24,
					'one-year-change': '+ 212.5%'
				},
				mls_hpi_benchmark_price: {
					2025: '$599,800',
					2024: '$605,000',
					'one-year-change': '- 0.9%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 17,
					2024: 14,
					'one-year-change': '+ 21.4%'
				},
				sales: {
					2025: 5,
					2024: 4,
					'one-year-change': '+ 25.0%'
				},
				days_on_market_average: {
					2025: 51,
					2024: 12,
					'one-year-change': '+ 325.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$836,400',
					2024: '$828,300',
					'one-year-change': '+ 1.0%'
				}
			}
		},
		'Burnaby East': {
			detached: {
				total_active_listings: {
					2025: 36,
					2024: 33,
					'one-year-change': '+ 9.1%'
				},
				sales: {
					2025: 6,
					2024: 8,
					'one-year-change': '- 25.0%'
				},
				days_on_market_average: {
					2025: 12,
					2024: 30,
					'one-year-change': '- 60.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,959,700',
					2024: '$1,907,600',
					'one-year-change': '+ 2.7%'
				}
			},
			condo: {
				total_active_listings: {
					2025: 78,
					2024: 54,
					'one-year-change': '+ 44.4%'
				},
				sales: {
					2025: 9,
					2024: 13,
					'one-year-change': '- 30.8%'
				},
				days_on_market_average: {
					2025: 36,
					2024: 48,
					'one-year-change': '- 25.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$784,900',
					2024: '$789,600',
					'one-year-change': '- 0.6%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: 23,
					2024: 10,
					'one-year-change': '+ 130.0%'
				},
				sales: {
					2025: 5,
					2024: 3,
					'one-year-change': '+ 66.7%'
				},
				days_on_market_average: {
					2025: 56,
					2024: 6,
					'one-year-change': '+ 833.3%'
				},
				mls_hpi_benchmark_price: {
					2025: '$915,800',
					2024: '$890,500',
					'one-year-change': '+ 2.8%'
				}
			}
		},
		COQUITLAM: {
			detached: {
				total_active_listings: {
					2025: '394',
					2024: '232',
					'one-year-change': '+ 69.8%'
				},
				sales: {
					2025: '40',
					2024: '45',
					'one-year-change': '- 11.1%'
				},
				days_on_market_average: {
					2025: '33',
					2024: '50',
					'one-year-change': '- 34.0%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,796,600',
					2024: '$1,799,500',
					'one-year-change': '- 0.2%'
				}
			},
			condo: {
				total_active_listings: {
					2025: '470',
					2024: '264',
					'one-year-change': '+ 78.0%'
				},
				sales: {
					2025: '88',
					2024: '104',
					'one-year-change': '- 15.4%'
				},
				days_on_market_average: {
					2025: '32',
					2024: '35',
					'one-year-change': '- 8.6%'
				},
				mls_hpi_benchmark_price: {
					2025: '$732,000',
					2024: '$742,200',
					'one-year-change': '- 1.4%'
				}
			},
			townhomes: {
				total_active_listings: {
					2025: '145',
					2024: '108',
					'one-year-change': '+ 34.3%'
				},
				sales: {
					2025: '31',
					2024: '37',
					'one-year-change': '- 16.2%'
				},
				days_on_market_average: {
					2025: '34',
					2024: '23',
					'one-year-change': '+ 47.8%'
				},
				mls_hpi_benchmark_price: {
					2025: '$1,056,100',
					2024: '$1,055,200',
					'one-year-change': '+ 0.1%'
				}
			}
		}
	}
};
