// Market data for real estate listings by city, area, and property type
/*
In page 2,3,4 of this pdf, return market updates for detached (page 2), condo(page3), townhomes(page 4), for feb of 2025 for each neighbourhood. record sales, active listings, benchamark price, one year change in json format of {
"Vancouver East": {
"detached": {
"Champlain Heights": {
"sales": 0,
"active_listings": 3,
"benchmark_price": "
0",
"one_year_change": ""
},
"condos": {
"sales": 0,
"active_listings": 3,
"benchmark_price": "$0",
"one_year_change": ""
},
}},
*/

export const marketDataGVRpercityAllMonths = {
	'February 2025': {
		'Vancouver East': {
			detached: {
				'Champlain Heights': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Collingwood VE': {
					sales: 2,
					active_listings: 95,
					benchmark_price: '$1,718,000',
					one_year_change: '0.4%'
				},
				'Downtown VE': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Fraser VE': {
					sales: 5,
					active_listings: 31,
					benchmark_price: '$1,935,200',
					one_year_change: '+ 8.8%'
				},
				'Fraserview VE': {
					sales: 1,
					active_listings: 28,
					benchmark_price: '$2,321,200',
					one_year_change: '+ 1.7%'
				},
				'Grandview Woodland': {
					sales: 6,
					active_listings: 35,
					benchmark_price: '$1,792,000',
					one_year_change: '- 0.6%'
				},
				Hastings: {
					sales: 0,
					active_listings: 6,
					benchmark_price: '$1,647,700',
					one_year_change: '- 1.8%'
				},
				'Hastings Sunrise': {
					sales: 3,
					active_listings: 15,
					benchmark_price: '$1,690,900',
					one_year_change: '+ 1.6%'
				},
				'Killarney VE': {
					sales: 10,
					active_listings: 42,
					benchmark_price: '$1,999,400',
					one_year_change: '+ 2.1%'
				},
				Knight: {
					sales: 3,
					active_listings: 44,
					benchmark_price: '$1,815,400',
					one_year_change: '+ 3.0%'
				},
				Main: {
					sales: 5,
					active_listings: 18,
					benchmark_price: '$2,086,100',
					one_year_change: '+ 6.5%'
				},
				'Mount Pleasant VE': {
					sales: 0,
					active_listings: 15,
					benchmark_price: '$1,875,100',
					one_year_change: '+ 0.2%'
				},
				'Renfrew Heights': {
					sales: 8,
					active_listings: 51,
					benchmark_price: '$1,791,400',
					one_year_change: '- 0.9%'
				},
				'Renfrew VE': {
					sales: 4,
					active_listings: 70,
					benchmark_price: '$1,766,500',
					one_year_change: '- 1.2%'
				},
				'South Marine': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$1,478,600',
					one_year_change: '+ 2.3%'
				},
				'South Vancouver': {
					sales: 5,
					active_listings: 52,
					benchmark_price: '$1,849,400',
					one_year_change: '- 2.3%'
				},
				Strathcona: {
					sales: 2,
					active_listings: 6,
					benchmark_price: '$1,578,700',
					one_year_change: '- 1.8%'
				},
				'Victoria VE': {
					sales: 1,
					active_listings: 23,
					benchmark_price: '$1,761,500',
					one_year_change: '+ 2.5%'
				}
			},
			condos: {
				'Champlain Heights': {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$794,700',
					one_year_change: '- 2.7%'
				},
				'Collingwood VE': {
					sales: 8,
					active_listings: 79,
					benchmark_price: '$590,600',
					one_year_change: '- 0.7%'
				},
				'Downtown VE': {
					sales: 8,
					active_listings: 50,
					benchmark_price: '$639,400',
					one_year_change: '- 2.9%'
				},
				'Fraser VE': {
					sales: 2,
					active_listings: 15,
					benchmark_price: '$746,400',
					one_year_change: '- 8.7%'
				},
				'Fraserview VE': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Grandview Woodland': {
					sales: 9,
					active_listings: 30,
					benchmark_price: '$685,600',
					one_year_change: '+ 5.6%'
				},
				Hastings: {
					sales: 6,
					active_listings: 10,
					benchmark_price: '$554,800',
					one_year_change: '+ 0.0%'
				},
				'Hastings Sunrise': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$570,900',
					one_year_change: '+ 3.2%'
				},
				'Killarney VE': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$616,400',
					one_year_change: '- 3.7%'
				},
				Knight: {
					sales: 4,
					active_listings: 6,
					benchmark_price: '$640,800',
					one_year_change: '+ 8.2%'
				},
				Main: {
					sales: 1,
					active_listings: 8,
					benchmark_price: '$921,100',
					one_year_change: '- 8.4%'
				},
				'Mount Pleasant VE': {
					sales: 26,
					active_listings: 99,
					benchmark_price: '$695,200',
					one_year_change: '- 6.0%'
				},
				'Renfrew Heights': {
					sales: 1,
					active_listings: 0,
					benchmark_price: '$427,900',
					one_year_change: '+ 8.4%'
				},
				'Renfrew VE': {
					sales: 5,
					active_listings: 10,
					benchmark_price: '$767,200',
					one_year_change: '+ 5.8%'
				},
				'South Marine': {
					sales: 12,
					active_listings: 81,
					benchmark_price: '$805,100',
					one_year_change: '- 4.5%'
				},
				'South Vancouver': {
					sales: 0,
					active_listings: 16,
					benchmark_price: '$627,000',
					one_year_change: '- 7.4%'
				},
				Strathcona: {
					sales: 7,
					active_listings: 38,
					benchmark_price: '$693,000',
					one_year_change: '- 5.8%'
				},
				'Victoria VE': {
					sales: 2,
					active_listings: 10,
					benchmark_price: '$805,200',
					one_year_change: '+ 1.6%'
				}
			},
			townhomes: {
				'Champlain Heights': {
					sales: 6,
					active_listings: 9,
					benchmark_price: '$1,038,500',
					one_year_change: '+ 4.8%'
				},
				'Collingwood VE': {
					sales: 4,
					active_listings: 10,
					benchmark_price: '$1,024,700',
					one_year_change: '+ 4.5%'
				},
				'Downtown VE': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Fraser VE': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$1,460,100',
					one_year_change: '+ 2.9%'
				},
				'Fraserview VE': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Grandview Woodland': {
					sales: 0,
					active_listings: 14,
					benchmark_price: '$1,457,700',
					one_year_change: '+ 5.4%'
				},
				Hastings: {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$1,270,800',
					one_year_change: '+ 5.1%'
				},
				'Hastings Sunrise': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Killarney VE': {
					sales: 1,
					active_listings: 5,
					benchmark_price: '$978,600',
					one_year_change: '+ 4.3%'
				},
				Knight: {
					sales: 4,
					active_listings: 4,
					benchmark_price: '$1,325,600',
					one_year_change: '- 2.1%'
				},
				Main: {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$1,243,100',
					one_year_change: '+ 2.1%'
				},
				'Mount Pleasant VE': {
					sales: 1,
					active_listings: 17,
					benchmark_price: '$1,206,800',
					one_year_change: '+ 9.5%'
				},
				'Renfrew Heights': {
					sales: 2,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Renfrew VE': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$1,042,600',
					one_year_change: '+ 5.2%'
				},
				'South Marine': {
					sales: 4,
					active_listings: 14,
					benchmark_price: '$1,066,400',
					one_year_change: '+ 4.1%'
				},
				'South Vancouver': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Strathcona: {
					sales: 2,
					active_listings: 8,
					benchmark_price: '$1,088,700',
					one_year_change: '+ 12.3%'
				},
				'Victoria VE': {
					sales: 1,
					active_listings: 4,
					benchmark_price: '$1,334,900',
					one_year_change: '+ 5.0%'
				}
			}
		},

		'Burnaby East': {
			detached: {
				'East Burnaby': {
					sales: 1,
					active_listings: 14,
					benchmark_price: '$1,987,000',
					one_year_change: '+ 3.0%'
				},
				'Edmonds BE': {
					sales: 3,
					active_listings: 13,
					benchmark_price: '$1,794,500',
					one_year_change: '-1.6%'
				},
				'The Crest': {
					sales: 2,
					active_listings: 9,
					benchmark_price: '$1,976,400',
					one_year_change: '+ 3.4%'
				}
			},
			condos: {
				'East Burnaby': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$639,700',
					one_year_change: '-1.2%'
				},
				'Edmonds BE': {
					sales: 9,
					active_listings: 77,
					benchmark_price: '$788,700',
					one_year_change: '-1.0%'
				},
				'The Crest': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			townhomes: {
				'East Burnaby': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Edmonds BE': {
					sales: 4,
					active_listings: 17,
					benchmark_price: '$792,700',
					one_year_change: '+ 1.4%'
				},
				'The Crest': {
					sales: 1,
					active_listings: 5,
					benchmark_price: '$1,159,900',
					one_year_change: '+ 3.5%'
				}
			}
		},

		'Maple Ridge': {
			detached: {
				Albion: {
					sales: 7,
					active_listings: 45,
					benchmark_price: '$1,227,100',
					one_year_change: '-3.5%'
				},
				'Cottonwood MR': {
					sales: 9,
					active_listings: 54,
					benchmark_price: '$1,291,600',
					one_year_change: '-2.4%'
				},
				'East Central': {
					sales: 7,
					active_listings: 57,
					benchmark_price: '$1,125,600',
					one_year_change: '-2.3%'
				},
				'North Maple Ridge': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: null
				},
				Northeast: {
					sales: 0,
					active_listings: 6,
					benchmark_price: '$1,731,600',
					one_year_change: '-1.3%'
				},
				'Northwest Maple Ridge': {
					sales: 3,
					active_listings: 31,
					benchmark_price: '$1,194,900',
					one_year_change: '-3.8%'
				},
				'Silver Valley': {
					sales: 6,
					active_listings: 62,
					benchmark_price: '$1,485,200',
					one_year_change: '+2.3%'
				},
				'Southwest Maple Ridge': {
					sales: 7,
					active_listings: 53,
					benchmark_price: '$1,021,700',
					one_year_change: '-10.7%'
				},
				'Thornhill MR': {
					sales: 7,
					active_listings: 23,
					benchmark_price: '$1,666,700',
					one_year_change: '+1.3%'
				},
				'Websters Corners': {
					sales: 2,
					active_listings: 30,
					benchmark_price: '$1,404,500',
					one_year_change: '+2.4%'
				},
				'West Central': {
					sales: 6,
					active_listings: 90,
					benchmark_price: '$1,094,600',
					one_year_change: '-1.1%'
				},
				Whonnock: {
					sales: 1,
					active_listings: 17,
					benchmark_price: '$1,579,700',
					one_year_change: '+5.8%'
				}
			},
			condos: {
				Albion: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Cottonwood MR': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'East Central': {
					sales: 20,
					active_listings: 81,
					benchmark_price: '$514,800',
					one_year_change: '-1.6%'
				},
				'North Maple Ridge': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				Northeast: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Northwest Maple Ridge': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$536,700',
					one_year_change: '-2.7%'
				},
				'Silver Valley': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Southwest Maple Ridge': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$419,200',
					one_year_change: '-0.7%'
				},
				'Thornhill MR': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Websters Corners': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'West Central': {
					sales: 15,
					active_listings: 58,
					benchmark_price: '$538,700',
					one_year_change: '-1.2%'
				},
				Whonnock: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				}
			},
			townhomes: {
				Albion: {
					sales: 7,
					active_listings: 16,
					benchmark_price: '$817,200',
					one_year_change: '+0.6%'
				},
				'Cottonwood MR': {
					sales: 14,
					active_listings: 15,
					benchmark_price: '$813,000',
					one_year_change: '+0.5%'
				},
				'East Central': {
					sales: 8,
					active_listings: 27,
					benchmark_price: '$753,800',
					one_year_change: '-1.1%'
				},
				'North Maple Ridge': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				Northeast: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Northwest Maple Ridge': {
					sales: 1,
					active_listings: 4,
					benchmark_price: '$698,200',
					one_year_change: '+0.4%'
				},
				'Silver Valley': {
					sales: 2,
					active_listings: 6,
					benchmark_price: '$850,200',
					one_year_change: '+0.0%'
				},
				'Southwest Maple Ridge': {
					sales: 0,
					active_listings: 7,
					benchmark_price: '$652,200',
					one_year_change: '+1.7%'
				},
				'Thornhill MR': {
					sales: 5,
					active_listings: 11,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Websters Corners': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'West Central': {
					sales: 1,
					active_listings: 10,
					benchmark_price: '$696,000',
					one_year_change: '-0.1%'
				},
				Whonnock: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				}
			}
		},

		Richmond: {
			detached: {
				'Boyd Park': {
					sales: 0,
					active_listings: 13,
					benchmark_price: '$2,142,800',
					one_year_change: '-3.4%'
				},
				'Bridgeport RI': {
					sales: 0,
					active_listings: 8,
					benchmark_price: '$1,831,800',
					one_year_change: '+3.6%'
				},
				Brighouse: {
					sales: 0,
					active_listings: 7,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Brighouse South': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Broadmoor: {
					sales: 3,
					active_listings: 37,
					benchmark_price: '$2,675,500',
					one_year_change: '+1.1%'
				},
				'East Cambie': {
					sales: 1,
					active_listings: 13,
					benchmark_price: '$1,893,500',
					one_year_change: '+2.6%'
				},
				'East Richmond': {
					sales: 0,
					active_listings: 13,
					benchmark_price: '$2,236,300',
					one_year_change: '-3.2%'
				},
				'Garden City': {
					sales: 2,
					active_listings: 16,
					benchmark_price: '$1,952,000',
					one_year_change: '+2.7%'
				},
				Gilmore: {
					sales: 0,
					active_listings: 9,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Granville: {
					sales: 4,
					active_listings: 38,
					benchmark_price: '$2,366,600',
					one_year_change: '-6.6%'
				},
				'Hamilton RI': {
					sales: 3,
					active_listings: 22,
					benchmark_price: '$1,357,500',
					one_year_change: '-5.1%'
				},
				Ironwood: {
					sales: 0,
					active_listings: 11,
					benchmark_price: '$1,668,800',
					one_year_change: '-7.5%'
				},
				Lackner: {
					sales: 1,
					active_listings: 15,
					benchmark_price: '$2,264,900',
					one_year_change: '-2.7%'
				},
				McLennan: {
					sales: 1,
					active_listings: 15,
					benchmark_price: '$2,461,200',
					one_year_change: '-6.2%'
				},
				'McLennan North': {
					sales: 0,
					active_listings: 7,
					benchmark_price: '$2,635,100',
					one_year_change: '+4.8%'
				},
				McNair: {
					sales: 3,
					active_listings: 17,
					benchmark_price: '$1,733,100',
					one_year_change: '-7.9%'
				},
				'Quilchena RI': {
					sales: 1,
					active_listings: 31,
					benchmark_price: '$2,063,900',
					one_year_change: '-7.8%'
				},
				'Riverdale RI': {
					sales: 2,
					active_listings: 32,
					benchmark_price: '$2,271,100',
					one_year_change: '-7.1%'
				},
				Saunders: {
					sales: 2,
					active_listings: 23,
					benchmark_price: '$2,399,600',
					one_year_change: '+1.8%'
				},
				'Sea Island': {
					sales: 1,
					active_listings: 4,
					benchmark_price: '$2,170,400',
					one_year_change: '+3.3%'
				},
				Seafair: {
					sales: 4,
					active_listings: 26,
					benchmark_price: '$2,616,400',
					one_year_change: '-1.7%'
				},
				'South Arm': {
					sales: 1,
					active_listings: 9,
					benchmark_price: '$2,335,100',
					one_year_change: '+1.7%'
				},
				'Steveston North': {
					sales: 3,
					active_listings: 27,
					benchmark_price: '$1,874,500',
					one_year_change: '+2.7%'
				},
				'Steveston South': {
					sales: 0,
					active_listings: 10,
					benchmark_price: '$2,100,900',
					one_year_change: '+2.0%'
				},
				'Steveston Village': {
					sales: 2,
					active_listings: 11,
					benchmark_price: '$2,014,500',
					one_year_change: '+3.1%'
				},
				'Terra Nova': {
					sales: 3,
					active_listings: 11,
					benchmark_price: '$2,250,500',
					one_year_change: '-6.6%'
				},
				'West Cambie': {
					sales: 0,
					active_listings: 24,
					benchmark_price: '$1,947,900',
					one_year_change: '+2.9%'
				},
				Westwind: {
					sales: 1,
					active_listings: 13,
					benchmark_price: '$2,213,400',
					one_year_change: '+2.7%'
				},
				Woodwards: {
					sales: 2,
					active_listings: 33,
					benchmark_price: '$2,011,700',
					one_year_change: '+3.1%'
				}
			},
			condos: {
				'Boyd Park': {
					sales: 1,
					active_listings: 8,
					benchmark_price: '$568,500',
					one_year_change: '+0.7%'
				},
				'Bridgeport RI': {
					sales: 2,
					active_listings: 10,
					benchmark_price: '$694,800',
					one_year_change: '-2.2%'
				},
				Brighouse: {
					sales: 41,
					active_listings: 375,
					benchmark_price: '$781,800',
					one_year_change: '-1.6%'
				},
				'Brighouse South': {
					sales: 15,
					active_listings: 68,
					benchmark_price: '$655,000',
					one_year_change: '-1.3%'
				},
				Broadmoor: {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$493,400',
					one_year_change: '-3.5%'
				},
				'East Cambie': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$463,300',
					one_year_change: '+2.7%'
				},
				'East Richmond': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$793,200',
					one_year_change: '-1.9%'
				},
				'Garden City': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Gilmore: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Granville: {
					sales: 1,
					active_listings: 7,
					benchmark_price: '$258,400',
					one_year_change: '-2.7%'
				},
				'Hamilton RI': {
					sales: 0,
					active_listings: 12,
					benchmark_price: '$826,800',
					one_year_change: '-2.5%'
				},
				Ironwood: {
					sales: 2,
					active_listings: 8,
					benchmark_price: '$624,000',
					one_year_change: '-3.1%'
				},
				Lackner: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				McLennan: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'McLennan North': {
					sales: 5,
					active_listings: 33,
					benchmark_price: '$712,400',
					one_year_change: '-0.9%'
				},
				McNair: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Quilchena RI': {
					sales: 1,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Riverdale RI': {
					sales: 3,
					active_listings: 4,
					benchmark_price: '$639,000',
					one_year_change: '-0.4%'
				},
				Saunders: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Sea Island': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Seafair: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$588,200',
					one_year_change: '+0.0%'
				},
				'South Arm': {
					sales: 2,
					active_listings: 9,
					benchmark_price: '$291,500',
					one_year_change: '-4.0%'
				},
				'Steveston North': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$530,500',
					one_year_change: '-1.2%'
				},
				'Steveston South': {
					sales: 7,
					active_listings: 16,
					benchmark_price: '$799,600',
					one_year_change: '+4.8%'
				},
				'Steveston Village': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Terra Nova': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'West Cambie': {
					sales: 17,
					active_listings: 169,
					benchmark_price: '$796,500',
					one_year_change: '-3.2%'
				},
				Westwind: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Woodwards: {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			townhomes: {
				'Boyd Park': {
					sales: 2,
					active_listings: 3,
					benchmark_price: '$753,400',
					one_year_change: '-6.8%'
				},
				'Bridgeport RI': {
					sales: 0,
					active_listings: 19,
					benchmark_price: '$1,117,900',
					one_year_change: '+2.7%'
				},
				Brighouse: {
					sales: 2,
					active_listings: 27,
					benchmark_price: '$1,063,400',
					one_year_change: '-1.7%'
				},
				'Brighouse South': {
					sales: 4,
					active_listings: 35,
					benchmark_price: '$1,027,400',
					one_year_change: '+3.7%'
				},
				Broadmoor: {
					sales: 1,
					active_listings: 7,
					benchmark_price: '$1,096,400',
					one_year_change: '-7.3%'
				},
				'East Cambie': {
					sales: 0,
					active_listings: 14,
					benchmark_price: '$890,500',
					one_year_change: '+0.2%'
				},
				'East Richmond': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Garden City': {
					sales: 0,
					active_listings: 10,
					benchmark_price: '$1,123,000',
					one_year_change: '-6.5%'
				},
				Gilmore: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Granville: {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$1,098,500',
					one_year_change: '-0.8%'
				},
				'Hamilton RI': {
					sales: 1,
					active_listings: 19,
					benchmark_price: '$917,600',
					one_year_change: '+0.9%'
				},
				Ironwood: {
					sales: 0,
					active_listings: 6,
					benchmark_price: '$1,080,400',
					one_year_change: '-1.3%'
				},
				Lackner: {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$1,280,000',
					one_year_change: '-6.6%'
				},
				McLennan: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'McLennan North': {
					sales: 6,
					active_listings: 34,
					benchmark_price: '$1,221,800',
					one_year_change: '+0.7%'
				},
				McNair: {
					sales: 1,
					active_listings: 0,
					benchmark_price: '$873,900',
					one_year_change: '+2.4%'
				},
				'Quilchena RI': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$837,900',
					one_year_change: '-6.8%'
				},
				'Riverdale RI': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$1,055,500',
					one_year_change: '-5.1%'
				},
				Saunders: {
					sales: 6,
					active_listings: 6,
					benchmark_price: '$1,025,800',
					one_year_change: '-6.9%'
				},
				'Sea Island': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Seafair: {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$932,000',
					one_year_change: '-8.1%'
				},
				'South Arm': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$1,183,200',
					one_year_change: '-6.7%'
				},
				'Steveston North': {
					sales: 1,
					active_listings: 9,
					benchmark_price: '$923,300',
					one_year_change: '-5.4%'
				},
				'Steveston South': {
					sales: 5,
					active_listings: 13,
					benchmark_price: '$1,173,500',
					one_year_change: '-7.9%'
				},
				'Steveston Village': {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$926,000',
					one_year_change: '-5.2%'
				},
				'Terra Nova': {
					sales: 0,
					active_listings: 13,
					benchmark_price: '$1,218,800',
					one_year_change: '-5.8%'
				},
				'West Cambie': {
					sales: 5,
					active_listings: 28,
					benchmark_price: '$1,198,500',
					one_year_change: '-2.1%'
				},
				Westwind: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$1,017,900',
					one_year_change: '-3.5%'
				},
				Woodwards: {
					sales: 2,
					active_listings: 9,
					benchmark_price: '$1,379,300',
					one_year_change: '-7.7%'
				}
			}
		},

		'Port Moody': {
			detached: {
				Anmore: {
					sales: 0,
					active_listings: 21,
					benchmark_price: '$3,124,100',
					one_year_change: '+ 5.6%'
				},
				'Barber Street': {
					sales: 0,
					active_listings: 8,
					benchmark_price: '$2,105,400',
					one_year_change: '- 0.7%'
				},
				Belcarra: {
					sales: 1,
					active_listings: 10,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'College Park PM': {
					sales: 2,
					active_listings: 6,
					benchmark_price: '$1,646,000',
					one_year_change: '- 0.6%'
				},
				Glenayre: {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$1,668,500',
					one_year_change: '- 0.5%'
				},
				'Heritage Mountain': {
					sales: 2,
					active_listings: 8,
					benchmark_price: '$2,019,500',
					one_year_change: '+ 4.5%'
				},
				'Heritage Woods PM': {
					sales: 1,
					active_listings: 18,
					benchmark_price: '$2,152,900',
					one_year_change: '+ 4.6%'
				},
				loco: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Mountain Meadows': {
					sales: 2,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'North Shore Pt Moody': {
					sales: 0,
					active_listings: 12,
					benchmark_price: '$1,881,600',
					one_year_change: '- 1.3%'
				},
				'Port Moody Centre': {
					sales: 1,
					active_listings: 13,
					benchmark_price: '$1,750,800',
					one_year_change: '- 2.8%'
				}
			},
			condos: {
				Anmore: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Barber Street': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Belcarra: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'College Park PM': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$492,200',
					one_year_change: '+ 0.3%'
				},
				Glenayre: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Heritage Mountain': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Heritage Woods PM': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				loco: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Mountain Meadows': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'North Shore Pt Moody': {
					sales: 4,
					active_listings: 18,
					benchmark_price: '$763,900',
					one_year_change: '- 1.3%'
				},
				'Port Moody Centre': {
					sales: 14,
					active_listings: 72,
					benchmark_price: '$719,900',
					one_year_change: '- 1.0%'
				}
			},
			townhomes: {
				Anmore: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Barber Street': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Belcarra: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'College Park PM': {
					sales: 2,
					active_listings: 8,
					benchmark_price: '$877,200',
					one_year_change: '- 7.7%'
				},
				Glenayre: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Heritage Mountain': {
					sales: 0,
					active_listings: 6,
					benchmark_price: '$1,098,300',
					one_year_change: '+ 0.4%'
				},
				'Heritage Woods PM': {
					sales: 3,
					active_listings: 4,
					benchmark_price: '$1,110,400',
					one_year_change: '0.8%'
				},
				loco: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Mountain Meadows': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'North Shore Pt Moody': {
					sales: 2,
					active_listings: 6,
					benchmark_price: '$768,300',
					one_year_change: '+ 1.1%'
				},
				'Port Moody Centre': {
					sales: 2,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Port Moody Centre': {
					sales: 3,
					active_listings: 6,
					benchmark_price: '$1,115,000',
					one_year_change: '- 0.3%'
				}
			}
		},

		'New Westminster': {
			detached: {
				Brunette: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Connaught Heights': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$1,649,300',
					one_year_change: '1.3%'
				},
				'Downtown NW': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Fraserview NW': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'GlenBrooke North': {
					sales: 1,
					active_listings: 6,
					benchmark_price: '$1,718,300',
					one_year_change: '+ 3.4%'
				},
				'Moody Park': {
					sales: 1,
					active_listings: 5,
					benchmark_price: '$1,617,000',
					one_year_change: '+ 1.7%'
				},
				'North Arm': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Quay: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Queens Park': {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$2,049,500',
					one_year_change: '0.8%'
				},
				Queensborough: {
					sales: 2,
					active_listings: 25,
					benchmark_price: '$1,380,000',
					one_year_change: '- 7.1%'
				},
				Sapperton: {
					sales: 2,
					active_listings: 5,
					benchmark_price: '$1,459,400',
					one_year_change: '+ 4.5%'
				},
				'The Heights NW': {
					sales: 0,
					active_listings: 15,
					benchmark_price: '$1,576,900',
					one_year_change: '+ 4.7%'
				},
				'Uptown NW': {
					sales: 1,
					active_listings: 11,
					benchmark_price: '$1,395,800',
					one_year_change: '+ 0.7%'
				},
				'West End NW': {
					sales: 0,
					active_listings: 12,
					benchmark_price: '$1,540,500',
					one_year_change: '- 0.3%'
				},
				'North Surrey': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			condos: {
				Brunette: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Connaught Heights': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Downtown NW': {
					sales: 23,
					active_listings: 72,
					benchmark_price: '$672,100',
					one_year_change: '- 0.0%'
				},
				'Fraserview NW': {
					sales: 6,
					active_listings: 21,
					benchmark_price: '$703,400',
					one_year_change: '+ 0.3%'
				},
				'GlenBrooke North': {
					sales: 2,
					active_listings: 6,
					benchmark_price: '$604,800',
					one_year_change: '+ 0.5%'
				},
				'Moody Park': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'North Arm': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Quay: {
					sales: 13,
					active_listings: 103,
					benchmark_price: '$729,400',
					one_year_change: '+ 2.8%'
				},
				'Queens Park': {
					sales: 1,
					active_listings: 0,
					benchmark_price: '$722,500',
					one_year_change: '+ 0.1%'
				},
				Queensborough: {
					sales: 4,
					active_listings: 24,
					benchmark_price: '$856,000',
					one_year_change: '- 3.1%'
				},
				Sapperton: {
					sales: 3,
					active_listings: 13,
					benchmark_price: '$551,700',
					one_year_change: '+ 2.0%'
				},
				'The Heights NW': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$580,800',
					one_year_change: '+ 0.9%'
				},
				'Uptown NW': {
					sales: 17,
					active_listings: 55,
					benchmark_price: '$574,700',
					one_year_change: '+ 0.5%'
				},
				'West End NW': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$416,900',
					one_year_change: '- 2.3%'
				},
				'North Surrey': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			townhomes: {
				Brunette: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Connaught Heights': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Downtown NW': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$789,100',
					one_year_change: '+ 0.9%'
				},
				'Fraserview NW': {
					sales: 2,
					active_listings: 7,
					benchmark_price: '$1,015,200',
					one_year_change: '+ 2.7%'
				},
				'GlenBrooke North': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$837,200',
					one_year_change: '+ 0.4%'
				},
				'Moody Park': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'North Arm': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Quay: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Queens Park': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Queensborough: {
					sales: 5,
					active_listings: 14,
					benchmark_price: '$1,005,700',
					one_year_change: '+ 1.3%'
				},
				Sapperton: {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'The Heights NW': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Uptown NW': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$750,100',
					one_year_change: '+ 0.1%'
				},
				'West End NW': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'North Surrey': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			}
		},

		'Burnaby East': {
			detached: {
				'East Burnaby': {
					sales: 1,
					active_listings: 14,
					benchmark_price: '$1,987,000',
					one_year_change: '+ 3.0%'
				},
				'Edmonds BE': {
					sales: 3,
					active_listings: 13,
					benchmark_price: '$1,794,500',
					one_year_change: '- 1.6%'
				},
				'The Crest': {
					sales: 2,
					active_listings: 9,
					benchmark_price: '$1,976,400',
					one_year_change: '+ 3.4%'
				}
			},
			condos: {
				'East Burnaby': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$639,700',
					one_year_change: '- 1.2%'
				},
				'Edmonds BE': {
					sales: 9,
					active_listings: 77,
					benchmark_price: '$788,700',
					one_year_change: '- 1.0%'
				},
				'The Crest': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			townhomes: {
				'East Burnaby': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Edmonds BE': {
					sales: 4,
					active_listings: 17,
					benchmark_price: '$792,700',
					one_year_change: '+ 1.4%'
				},
				'The Crest': {
					sales: 1,
					active_listings: 5,
					benchmark_price: '$1,159,900',
					one_year_change: '+ 3.5%'
				}
			}
		},

		'Port Coquitlam': {
			detached: {
				'Birchland Manor': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$1,336,000',
					one_year_change: '+ 1.0%'
				},
				'Central Pt Coquitlam': {
					sales: 1,
					active_listings: 15,
					benchmark_price: '$1,301,700',
					one_year_change: '+ 0.9%'
				},
				'Citadel PQ': {
					sales: 2,
					active_listings: 11,
					benchmark_price: '$1,507,200',
					one_year_change: '+ 2.6%'
				},
				'Glenwood PQ': {
					sales: 3,
					active_listings: 27,
					benchmark_price: '$1,352,200',
					one_year_change: '+ 1.9%'
				},
				'Lincoln Park PQ': {
					sales: 1,
					active_listings: 15,
					benchmark_price: '$1,324,900',
					one_year_change: '+ 1.3%'
				},
				'Lower Mary Hill': {
					sales: 2,
					active_listings: 7,
					benchmark_price: '$1,315,900',
					one_year_change: '+ 3.1%'
				},
				'Mary Hill': {
					sales: 1,
					active_listings: 11,
					benchmark_price: '$1,383,200',
					one_year_change: '+ 2.1%'
				},
				'Oxford Heights': {
					sales: 5,
					active_listings: 20,
					benchmark_price: '$1,435,300',
					one_year_change: '+ 1.9%'
				},
				Riverwood: {
					sales: 2,
					active_listings: 7,
					benchmark_price: '$1,554,400',
					one_year_change: '+ 3.1%'
				},
				'Woodland Acres PQ': {
					sales: 1,
					active_listings: 20,
					benchmark_price: '$1,475,700',
					one_year_change: '+ 3.2%'
				}
			},
			condos: {
				'Birchland Manor': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Central Pt Coquitlam': {
					sales: 21,
					active_listings: 60,
					benchmark_price: '$647,000',
					one_year_change: '- 1.4%'
				},
				'Citadel PQ': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Glenwood PQ': {
					sales: 5,
					active_listings: 19,
					benchmark_price: '$536,800',
					one_year_change: '- 5.6%'
				},
				'Lincoln Park PQ': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Lower Mary Hill': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Mary Hill': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Oxford Heights': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				Riverwood: {
					sales: 2,
					active_listings: 2,
					benchmark_price: '$688,700',
					one_year_change: '- 5.7%'
				},
				'Woodland Acres PQ': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				}
			},
			townhomes: {
				'Birchland Manor': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Central Pt Coquitlam': {
					sales: 1,
					active_listings: 4,
					benchmark_price: '$820,900',
					one_year_change: '- 0.3%'
				},
				'Citadel PQ': {
					sales: 1,
					active_listings: 3,
					benchmark_price: '$1,011,900',
					one_year_change: '+ 1.4%'
				},
				'Glenwood PQ': {
					sales: 1,
					active_listings: 8,
					benchmark_price: '$894,500',
					one_year_change: '+ 2.3%'
				},
				'Lincoln Park PQ': {
					sales: 6,
					active_listings: 20,
					benchmark_price: '$923,100',
					one_year_change: '+ 1.6%'
				},
				'Lower Mary Hill': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Mary Hill': {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$926,800',
					one_year_change: '- 0.6%'
				},
				'Oxford Heights': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$961,100',
					one_year_change: '+ 5.0%'
				},
				Riverwood: {
					sales: 5,
					active_listings: 20,
					benchmark_price: '$1,007,800',
					one_year_change: '+ 1.7%'
				},
				'Woodland Acres PQ': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				}
			}
		},

		'Pitt Meadows': {
			detached: {
				'Central Meadows': {
					sales: 2,
					active_listings: 18,
					benchmark_price: '$1,117,700',
					one_year_change: '-10.9%'
				},
				'Mid Meadows': {
					sales: 2,
					active_listings: 8,
					benchmark_price: '$1,192,000',
					one_year_change: '-9.3%'
				},
				'North Meadows PI': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'South Meadows': {
					sales: 4,
					active_listings: 11,
					benchmark_price: '$1,212,400',
					one_year_change: '-11.1%'
				},
				'West Meadows': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			condos: {
				'Central Meadows': {
					sales: 2,
					active_listings: 6,
					benchmark_price: '$546,100',
					one_year_change: '-1.0%'
				},
				'Mid Meadows': {
					sales: 2,
					active_listings: 9,
					benchmark_price: '$623,300',
					one_year_change: '-1.2%'
				},
				'North Meadows PI': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'South Meadows': {
					sales: 4,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'West Meadows': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			townhomes: {
				'Central Meadows': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$803,900',
					one_year_change: '+ 0.1%'
				},
				'Mid Meadows': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$777,100',
					one_year_change: '+ 0.1%'
				},
				'North Meadows Pl': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'South Meadows': {
					sales: 4,
					active_listings: 12,
					benchmark_price: '$888,400',
					one_year_change: '+ 1.8%'
				},
				'West Meadows': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			}
		},

		'North Vancouver': {
			detached: {
				'Blueridge NV': {
					sales: 4,
					active_listings: 7,
					benchmark_price: '$2,171,800',
					one_year_change: '+ 2.0%'
				},
				Boulevard: {
					sales: 2,
					active_listings: 13,
					benchmark_price: '$2,390,000',
					one_year_change: '- 0.1%'
				},
				Braemar: {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Calverhall: {
					sales: 1,
					active_listings: 9,
					benchmark_price: '$2,004,100',
					one_year_change: '+ 1.5%'
				},
				'Canyon Heights NV': {
					sales: 4,
					active_listings: 26,
					benchmark_price: '$2,463,000',
					one_year_change: '+ 5.6%'
				},
				'Capilano NV': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$2,440,100',
					one_year_change: '+ 9.0%'
				},
				'Central Lonsdale': {
					sales: 8,
					active_listings: 12,
					benchmark_price: '$2,054,300',
					one_year_change: '- 3.3%'
				},
				'Deep Cove': {
					sales: 0,
					active_listings: 15,
					benchmark_price: '$1,946,100',
					one_year_change: '+ 0.4%'
				},
				Delbrook: {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$2,390,700',
					one_year_change: '+ 3.3%'
				},
				Dollarton: {
					sales: 1,
					active_listings: 7,
					benchmark_price: '$2,515,100',
					one_year_change: '1.0%'
				},
				Edgemont: {
					sales: 2,
					active_listings: 13,
					benchmark_price: '$3,016,900',
					one_year_change: '+ 2.3%'
				},
				'Forest Hills NV': {
					sales: 0,
					active_listings: 10,
					benchmark_price: '$3,264,000',
					one_year_change: '+ 8.6%'
				},
				'Grouse Woods': {
					sales: 3,
					active_listings: 1,
					benchmark_price: '$2,382,400',
					one_year_change: '+ 2.2%'
				},
				Harbourside: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Indian Arm': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Indian River': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$1,953,700',
					one_year_change: '+ 1.0%'
				},
				'Lower Lonsdale': {
					sales: 1,
					active_listings: 7,
					benchmark_price: '$2,247,500',
					one_year_change: '6.4%'
				},
				'Lynn Valley': {
					sales: 8,
					active_listings: 21,
					benchmark_price: '$2,056,400',
					one_year_change: '+ 4.7%'
				},
				Lynnmour: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Mosquito Creek': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Norgate: {
					sales: 0,
					active_listings: 6,
					benchmark_price: '$1,747,600',
					one_year_change: '+ 2.6%'
				},
				Northlands: {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Pemberton Heights': {
					sales: 0,
					active_listings: 7,
					benchmark_price: '$2,310,500',
					one_year_change: '4.7%'
				},
				'Pemberton NV': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$1,663,000',
					one_year_change: '0.8%'
				},
				'Princess Park': {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$2,152,100',
					one_year_change: '+ 3.8%'
				},
				Queensbury: {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$1,937,700',
					one_year_change: '+ 1.0%'
				},
				'Roche Point': {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$1,775,400',
					one_year_change: '+ 0.6%'
				},
				'Seymour NV': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$1,967,500',
					one_year_change: '+ 4.8%'
				},
				Tempe: {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$2,368,200',
					one_year_change: '+ 7.5%'
				},
				'Upper Delbrook': {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$2,426,700',
					one_year_change: '+ 3.0%'
				},
				'Upper Lonsdale': {
					sales: 8,
					active_listings: 21,
					benchmark_price: '$2,238,800',
					one_year_change: '+ 4.2%'
				},
				Westlynn: {
					sales: 4,
					active_listings: 7,
					benchmark_price: '$1,860,700',
					one_year_change: '+ 3.8%'
				},
				'Westlynn Terrace': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Windsor Park NV': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$1,966,500',
					one_year_change: '- 1.3%'
				},
				'Woodlands-Sunshine-Cascade': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			condos: {
				'Blueridge NV': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Boulevard: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Braemar: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Calverhall: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Canyon Heights NV': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Capilano NV': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$1,032,400',
					one_year_change: '- 8.3%'
				},
				'Central Lonsdale': {
					sales: 7,
					active_listings: 53,
					benchmark_price: '$815,700',
					one_year_change: '- 2.4%'
				},
				'Deep Cove': {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Delbrook: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Dollarton: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Edgemont: {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$1,214,800',
					one_year_change: '- 6.8%'
				},
				'Forest Hills NV': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Grouse Woods': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Harbourside: {
					sales: 2,
					active_listings: 7,
					benchmark_price: '$928,500',
					one_year_change: '- 7.7%'
				},
				'Indian Arm': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Indian River': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$921,000',
					one_year_change: '+ 4.1%'
				},
				'Lower Lonsdale': {
					sales: 23,
					active_listings: 75,
					benchmark_price: '$789,000',
					one_year_change: '- 2.4%'
				},
				'Lynn Valley': {
					sales: 8,
					active_listings: 37,
					benchmark_price: '$937,700',
					one_year_change: '+ 3.3%'
				},
				Lynnmour: {
					sales: 11,
					active_listings: 42,
					benchmark_price: '$936,700',
					one_year_change: '+ 3.0%'
				},
				'Mosquito Creek': {
					sales: 1,
					active_listings: 10,
					benchmark_price: '$698,600',
					one_year_change: '- 1.7%'
				},
				Norgate: {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$810,500',
					one_year_change: '- 7.2%'
				},
				Northlands: {
					sales: 4,
					active_listings: 3,
					benchmark_price: '$1,038,500',
					one_year_change: '+ 4.0%'
				},
				'Pemberton Heights': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Pemberton NV': {
					sales: 7,
					active_listings: 52,
					benchmark_price: '$508,400',
					one_year_change: '8.2%'
				},
				'Princess Park': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Queensbury: {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Roche Point': {
					sales: 7,
					active_listings: 15,
					benchmark_price: '$814,400',
					one_year_change: '+ 5.6%'
				},
				'Seymour NV': {
					sales: 1,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Tempe: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Upper Delbrook': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Upper Lonsdale': {
					sales: 3,
					active_listings: 2,
					benchmark_price: '$723,700',
					one_year_change: '+ 4.0%'
				},
				Westlynn: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Westlynn Terrace': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Windsor Park NV': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Woodlands-Sunshine-Cascade': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			townhomes: {
				'Blueridge NV': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Boulevard: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Braemar: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Calverhall: {
					sales: 2,
					active_listings: 7,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Canyon Heights NV': {
					sales: 15,
					active_listings: 48,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Capilano NV': {
					sales: 4,
					active_listings: 42,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Central Lonsdale': {
					sales: 0,
					active_listings: 6,
					benchmark_price: '$1,409,600',
					one_year_change: '+ 7.3%'
				},
				'Deep Cove': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Delbrook: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Dollarton: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Edgemont: {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$1,938,900',
					one_year_change: '+ 7.6%'
				},
				'Forest Hills NV': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Grouse Woods': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Harbourside: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Indian Arm': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Indian River': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$1,430,600',
					one_year_change: '- 4.5%'
				},
				'Lower Lonsdale': {
					sales: 2,
					active_listings: 31,
					benchmark_price: '$1,487,300',
					one_year_change: '+ 6.9%'
				},
				'Lynn Valley': {
					sales: 3,
					active_listings: 9,
					benchmark_price: '$1,128,200',
					one_year_change: '- 4.9%'
				},
				Lynnmour: {
					sales: 4,
					active_listings: 13,
					benchmark_price: '$1,163,200',
					one_year_change: '- 4.1%'
				},
				'Mosquito Creek': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$1,265,000',
					one_year_change: '+ 6.8%'
				},
				Norgate: {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$1,240,900',
					one_year_change: '+ 8.0%'
				},
				Northlands: {
					sales: 2,
					active_listings: 2,
					benchmark_price: '$1,442,600',
					one_year_change: '- 4.8%'
				},
				'Pemberton Heights': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Pemberton NV': {
					sales: 1,
					active_listings: 15,
					benchmark_price: '$1,530,200',
					one_year_change: '+ 7.7%'
				},
				'Princess Park': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Queensbury: {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Roche Point': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$1,360,700',
					one_year_change: '- 4.0%'
				},
				'Seymour NV': {
					sales: 3,
					active_listings: 1,
					benchmark_price: '$1,259,600',
					one_year_change: '- 3.4%'
				},
				Tempe: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Upper Delbrook': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Upper Lonsdale': {
					sales: 1,
					active_listings: 5,
					benchmark_price: '$1,155,400',
					one_year_change: '+ 8.3%'
				},
				Westlynn: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$1,252,400',
					one_year_change: '- 4.4%'
				},
				'Westlynn Terrace': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Windsor Park NV': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Woodlands-Sunshine-Cascade': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			}
		},

		'Vancouver West': {
			detached: {
				Arbutus: {
					sales: 2,
					active_listings: 25,
					benchmark_price: '$3,606,400',
					one_year_change: '-6.3%'
				},
				Cambie: {
					sales: 1,
					active_listings: 61,
					benchmark_price: '$2,916,200',
					one_year_change: '-1.1%'
				},
				'Coal Harbour': {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Downtown VW': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Dunbar: {
					sales: 7,
					active_listings: 75,
					benchmark_price: '$3,334,500',
					one_year_change: '-0.6%'
				},
				'Fairview VW': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'False Creek': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Kerrisdale: {
					sales: 1,
					active_listings: 25,
					benchmark_price: '$3,796,000',
					one_year_change: '+5.3%'
				},
				Kitsilano: {
					sales: 4,
					active_listings: 33,
					benchmark_price: '$2,606,700',
					one_year_change: '-6.2%'
				},
				'MacKenzie Heights': {
					sales: 4,
					active_listings: 19,
					benchmark_price: '$3,947,900',
					one_year_change: '+4.5%'
				},
				Marpole: {
					sales: 3,
					active_listings: 38,
					benchmark_price: '$2,505,700',
					one_year_change: '+3.6%'
				},
				'Mount Pleasant VW': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$2,349,400',
					one_year_change: '+5.8%'
				},
				'Oakridge VW': {
					sales: 0,
					active_listings: 15,
					benchmark_price: '$3,658,100',
					one_year_change: '+8.8%'
				},
				'Point Grey': {
					sales: 5,
					active_listings: 87,
					benchmark_price: '$3,382,000',
					one_year_change: '+8.9%'
				},
				Quilchena: {
					sales: 2,
					active_listings: 21,
					benchmark_price: '$4,188,600',
					one_year_change: '-4.0%'
				},
				'S.W. Marine': {
					sales: 0,
					active_listings: 25,
					benchmark_price: '$3,387,700',
					one_year_change: '+6.7%'
				},
				Shaughnessy: {
					sales: 1,
					active_listings: 69,
					benchmark_price: '$4,770,900',
					one_year_change: '+2.8%'
				},
				'South Cambie': {
					sales: 0,
					active_listings: 9,
					benchmark_price: '$4,763,100',
					one_year_change: '+2.4%'
				},
				'South Granville': {
					sales: 2,
					active_listings: 77,
					benchmark_price: '$4,369,000',
					one_year_change: '+1.5%'
				},
				Southlands: {
					sales: 2,
					active_listings: 37,
					benchmark_price: '$3,770,500',
					one_year_change: '+4.4%'
				},
				'University VW': {
					sales: 1,
					active_listings: 21,
					benchmark_price: '$2,807,600',
					one_year_change: '+10.7%'
				},
				'West End W': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Yaletown: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			condos: {
				Arbutus: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Cambie: {
					sales: 9,
					active_listings: 80,
					benchmark_price: '$1,143,300',
					one_year_change: '+0.4%'
				},
				'Coal Harbour': {
					sales: 7,
					active_listings: 140,
					benchmark_price: '$1,075,600',
					one_year_change: '-15.5%'
				},
				'Downtown VW': {
					sales: 48,
					active_listings: 483,
					benchmark_price: '$691,800',
					one_year_change: '-1.5%'
				},
				Dunbar: {
					sales: 1,
					active_listings: 6,
					benchmark_price: '$1,036,700',
					one_year_change: '+4.0%'
				},
				'Fairview VW': {
					sales: 21,
					active_listings: 82,
					benchmark_price: '$812,200',
					one_year_change: '-0.7%'
				},
				'False Creek': {
					sales: 21,
					active_listings: 88,
					benchmark_price: '$903,100',
					one_year_change: '+6.4%'
				},
				Kerrisdale: {
					sales: 1,
					active_listings: 32,
					benchmark_price: '$1,145,700',
					one_year_change: '+1.2%'
				},
				Kitsilano: {
					sales: 27,
					active_listings: 52,
					benchmark_price: '$738,700',
					one_year_change: '+0.0%'
				},
				'MacKenzie Heights': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Marpole: {
					sales: 10,
					active_listings: 91,
					benchmark_price: '$684,000',
					one_year_change: '-0.8%'
				},
				'Mount Pleasant VW': {
					sales: 3,
					active_listings: 6,
					benchmark_price: '$818,300',
					one_year_change: '+7.3%'
				},
				'Oakridge VW': {
					sales: 7,
					active_listings: 26,
					benchmark_price: '$990,000',
					one_year_change: '+1.6%'
				},
				'Point Grey': {
					sales: 1,
					active_listings: 9,
					benchmark_price: '$876,700',
					one_year_change: '+0.8%'
				},
				Quilchena: {
					sales: 3,
					active_listings: 14,
					benchmark_price: '$1,285,700',
					one_year_change: '+2.8%'
				},
				'S.W. Marine': {
					sales: 0,
					active_listings: 11,
					benchmark_price: '$725,300',
					one_year_change: '-0.1%'
				},
				Shaughnessy: {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$1,215,700',
					one_year_change: '+2.7%'
				},
				'South Cambie': {
					sales: 0,
					active_listings: 52,
					benchmark_price: '$1,141,600',
					one_year_change: '-0.3%'
				},
				'South Granville': {
					sales: 0,
					active_listings: 11,
					benchmark_price: '$1,219,900',
					one_year_change: '+1.3%'
				},
				Southlands: {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$1,003,000',
					one_year_change: '+3.9%'
				},
				'University VW': {
					sales: 16,
					active_listings: 108,
					benchmark_price: '$1,017,500',
					one_year_change: '-8.9%'
				},
				'West End W': {
					sales: 27,
					active_listings: 207,
					benchmark_price: '$613,000',
					one_year_change: '-1.3%'
				},
				Yaletown: {
					sales: 34,
					active_listings: 233,
					benchmark_price: '$802,600',
					one_year_change: '-8.8%'
				}
			},
			townhomes: {
				Arbutus: {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Cambie: {
					sales: 3,
					active_listings: 44,
					benchmark_price: '$1,725,300',
					one_year_change: '-2.0%'
				},
				'Coal Harbour': {
					sales: 0,
					active_listings: 6,
					benchmark_price: '$1,760,300',
					one_year_change: '+7.0%'
				},
				'Downtown VW': {
					sales: 1,
					active_listings: 4,
					benchmark_price: '$1,193,000',
					one_year_change: '+10.9%'
				},
				Dunbar: {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Fairview VW': {
					sales: 4,
					active_listings: 18,
					benchmark_price: '$1,101,800',
					one_year_change: '+9.2%'
				},
				'False Creek': {
					sales: 3,
					active_listings: 6,
					benchmark_price: '$1,074,400',
					one_year_change: '-4.3%'
				},
				Kerrisdale: {
					sales: 0,
					active_listings: 7,
					benchmark_price: '$1,648,300',
					one_year_change: '-0.9%'
				},
				Kitsilano: {
					sales: 2,
					active_listings: 25,
					benchmark_price: '$1,176,300',
					one_year_change: '-11.4%'
				},
				'MacKenzie Heights': {
					sales: 1,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Marpole: {
					sales: 1,
					active_listings: 27,
					benchmark_price: '$1,605,600',
					one_year_change: '-1.7%'
				},
				'Mount Pleasant VW': {
					sales: 3,
					active_listings: 4,
					benchmark_price: '$1,316,800',
					one_year_change: '+10.7%'
				},
				'Oakridge VW': {
					sales: 1,
					active_listings: 12,
					benchmark_price: '$1,657,200',
					one_year_change: '+0.4%'
				},
				'Point Grey': {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$1,316,100',
					one_year_change: '-1.3%'
				},
				Quilchena: {
					sales: 2,
					active_listings: 3,
					benchmark_price: '$1,502,300',
					one_year_change: '-4.0%'
				},
				'S.W. Marine': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Shaughnessy: {
					sales: 1,
					active_listings: 6,
					benchmark_price: '$1,914,800',
					one_year_change: '-0.1%'
				},
				'South Cambie': {
					sales: 1,
					active_listings: 23,
					benchmark_price: '$1,607,300',
					one_year_change: '+2.8%'
				},
				'South Granville': {
					sales: 1,
					active_listings: 31,
					benchmark_price: '$1,604,100',
					one_year_change: '-7.2%'
				},
				Southlands: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'University VW': {
					sales: 0,
					active_listings: 11,
					benchmark_price: '$1,750,800',
					one_year_change: '+1.0%'
				},
				'West End W': {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$1,222,000',
					one_year_change: '+10.4%'
				},
				Yaletown: {
					sales: 1,
					active_listings: 13,
					benchmark_price: '$1,807,900',
					one_year_change: '+7.6%'
				}
			}
		},

		Coquitlam: {
			detached: {
				'Burke Mountain': {
					sales: 6,
					active_listings: 37,
					benchmark_price: '$2,178,600',
					one_year_change: '+ 1.7%'
				},
				'Canyon Springs': {
					sales: 1,
					active_listings: 4,
					benchmark_price: '$1,450,100',
					one_year_change: '- 2.6%'
				},
				'Cape Horn': {
					sales: 1,
					active_listings: 12,
					benchmark_price: '$1,497,900',
					one_year_change: '- 0.8%'
				},
				'Central Coquitlam': {
					sales: 9,
					active_listings: 33,
					benchmark_price: '$1,845,300',
					one_year_change: '- 1.5%'
				},
				Chineside: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$1,742,300',
					one_year_change: '- 1.1%'
				},
				'Coquitlam East': {
					sales: 7,
					active_listings: 21,
					benchmark_price: '$1,691,500',
					one_year_change: '+ 2.2%'
				},
				'Coquitlam West': {
					sales: 5,
					active_listings: 81,
					benchmark_price: '$1,865,500',
					one_year_change: '- 1.5%'
				},
				'Eagle Ridge CQ': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$1,281,700',
					one_year_change: '- 0.5%'
				},
				'Harbour Chines': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$2,115,500',
					one_year_change: '- 3.7%'
				},
				'Harbour Place': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$1,818,800',
					one_year_change: '- 3.1%'
				},
				Hockaday: {
					sales: 1,
					active_listings: 8,
					benchmark_price: '$1,794,000',
					one_year_change: '+ 0.7%'
				},
				Maillardville: {
					sales: 2,
					active_listings: 20,
					benchmark_price: '$1,808,300',
					one_year_change: '- 3.5%'
				},
				'Meadow Brook': {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$1,113,700',
					one_year_change: '- 1.7%'
				},
				'New Horizons': {
					sales: 1,
					active_listings: 3,
					benchmark_price: '$1,348,900',
					one_year_change: '+ 1.6%'
				},
				'North Coquitlam': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Park Ridge Estates': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Ranch Park': {
					sales: 1,
					active_listings: 108,
					benchmark_price: '$1,622,300',
					one_year_change: '+ 0.4%'
				},
				'River Springs': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$1,291,200',
					one_year_change: '+ 2.0%'
				},
				'Scott Creek': {
					sales: 1,
					active_listings: 4,
					benchmark_price: '$1,731,200',
					one_year_change: '- 2.9%'
				},
				'Summitt View': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Upper Eagle Ridge': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$1,661,400',
					one_year_change: '- 1.9%'
				},
				'Westwood Plateau': {
					sales: 4,
					active_listings: 42,
					benchmark_price: '$1,921,300',
					one_year_change: '+ 1.3%'
				},
				'Westwood Summit CQ': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			condos: {
				'Burke Mountain': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Canyon Springs': {
					sales: 2,
					active_listings: 4,
					benchmark_price: '$661,500',
					one_year_change: '+ 6.7%'
				},
				'Cape Horn': {
					sales: 0,
					active_listings: 9,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Central Coquitlam': {
					sales: 4,
					active_listings: 44,
					benchmark_price: '$583,600',
					one_year_change: '- 1.9%'
				},
				Chineside: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Coquitlam East': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$586,200',
					one_year_change: '- 2.1%'
				},
				'Coquitlam West': {
					sales: 51,
					active_listings: 255,
					benchmark_price: '$752,900',
					one_year_change: '- 3.7%'
				},
				'Eagle Ridge CQ': {
					sales: 2,
					active_listings: 2,
					benchmark_price: '$672,600',
					one_year_change: '0.8%'
				},
				'Harbour Chines': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Harbour Place': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				Hockaday: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				Maillardville: {
					sales: 3,
					active_listings: 28,
					benchmark_price: '$492,600',
					one_year_change: '+ 0.8%'
				},
				'Meadow Brook': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'New Horizons': {
					sales: 4,
					active_listings: 22,
					benchmark_price: '$792,200',
					one_year_change: '- 6.9%'
				},
				'North Coquitlam': {
					sales: 16,
					active_listings: 90,
					benchmark_price: '$753,300',
					one_year_change: '+ 2.2%'
				},
				'Park Ridge Estates': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Ranch Park': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'River Springs': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Scott Creek': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Summitt View': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Upper Eagle Ridge': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Westwood Plateau': {
					sales: 5,
					active_listings: 14,
					benchmark_price: '$753,200',
					one_year_change: '+ 6.1%'
				},
				'Westwood Summit CQ': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				}
			},
			townhomes: {
				'Burke Mountain': {
					sales: 16,
					active_listings: 62,
					benchmark_price: '$1,215,800',
					one_year_change: '+ 3.8%'
				},
				'Canyon Springs': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$731,400',
					one_year_change: '+ 0.2%'
				},
				'Cape Horn': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Central Coquitlam': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$673,100',
					one_year_change: '- 10.1%'
				},
				Chineside: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Coquitlam East': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$782,600',
					one_year_change: '- 7.5%'
				},
				'Coquitlam West': {
					sales: 5,
					active_listings: 38,
					benchmark_price: '$955,000',
					one_year_change: '- 9.1%'
				},
				'Eagle Ridge CQ': {
					sales: 1,
					active_listings: 3,
					benchmark_price: '$972,300',
					one_year_change: '+ 2.6%'
				},
				'Harbour Chines': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Harbour Place': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Hockaday: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Maillardville: {
					sales: 2,
					active_listings: 14,
					benchmark_price: '$714,300',
					one_year_change: '- 6.2%'
				},
				'Meadow Brook': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'New Horizons': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$1,169,400',
					one_year_change: '+ 2.2%'
				},
				'North Coquitlam': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Park Ridge Estates': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Ranch Park': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$701,300',
					one_year_change: '- 6.7%'
				},
				'River Springs': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Scott Creek': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$1,074,700',
					one_year_change: '+ 0.5%'
				},
				'Summitt View': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Upper Eagle Ridge': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$840,500',
					one_year_change: '+ 1.5%'
				},
				'Westwood Plateau': {
					sales: 6,
					active_listings: 13,
					benchmark_price: '$1,172,500',
					one_year_change: '- 1.2%'
				},
				'Westwood Summit CQ': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			}
		},
		'Burnaby South': {
			detached: {
				'Big Bend': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Buckingham Heights': {
					sales: 3,
					active_listings: 4,
					benchmark_price: '$2,995,600',
					one_year_change: '+ 5.8%'
				},
				'Burnaby Hospital': {
					sales: 0,
					active_listings: 7,
					benchmark_price: '$2,152,400',
					one_year_change: '+ 2.5%'
				},
				'Burnaby Lake': {
					sales: 1,
					active_listings: 14,
					benchmark_price: '$2,122,000',
					one_year_change: '+ 3.2%'
				},
				'Central Park BS': {
					sales: 0,
					active_listings: 17,
					benchmark_price: '$1,977,400',
					one_year_change: '+ 7.5%'
				},
				'Deer Lake': {
					sales: 0,
					active_listings: 11,
					benchmark_price: '$3,357,000',
					one_year_change: '+ 1.3%'
				},
				'Deer Lake Place': {
					sales: 1,
					active_listings: 6,
					benchmark_price: '$2,212,000',
					one_year_change: '+ 7.6%'
				},
				'Forest Glen BS': {
					sales: 2,
					active_listings: 13,
					benchmark_price: '$2,276,100',
					one_year_change: '+ 3.9%'
				},
				'Garden Village': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$2,295,700',
					one_year_change: '+ 6.6%'
				},
				'Greentree Village': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Highgate: {
					sales: 0,
					active_listings: 7,
					benchmark_price: '$1,924,300',
					one_year_change: '- 3.0%'
				},
				Metrotown: {
					sales: 0,
					active_listings: 10,
					benchmark_price: '$2,160,900',
					one_year_change: '- 5.6%'
				},
				Oaklands: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'South Slope': {
					sales: 2,
					active_listings: 26,
					benchmark_price: '$2,056,400',
					one_year_change: '- 3.8%'
				},
				Suncrest: {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$2,115,300',
					one_year_change: '- 0.3%'
				},
				'Upper Deer Lake': {
					sales: 2,
					active_listings: 14,
					benchmark_price: '$2,344,500',
					one_year_change: '+ 3.4%'
				}
			},
			condos: {
				'Big Bend': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Buckingham Heights': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Burnaby Hospital': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$642,100',
					one_year_change: '- 0.4%'
				},
				'Burnaby Lake': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Central Park BS': {
					sales: 2,
					active_listings: 7,
					benchmark_price: '$638,900',
					one_year_change: '- 2.1%'
				},
				'Deer Lake': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Deer Lake Place': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Forest Glen BS': {
					sales: 3,
					active_listings: 26,
					benchmark_price: '$794,500',
					one_year_change: '- 2.9%'
				},
				'Garden Village': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Greentree Village': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Highgate: {
					sales: 10,
					active_listings: 29,
					benchmark_price: '$750,300',
					one_year_change: '- 1.8%'
				},
				Metrotown: {
					sales: 34,
					active_listings: 308,
					benchmark_price: '$875,100',
					one_year_change: '- 3.4%'
				},
				Oaklands: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$720,500',
					one_year_change: '- 1.4%'
				},
				'South Slope': {
					sales: 3,
					active_listings: 17,
					benchmark_price: '$748,400',
					one_year_change: '+ 3.1%'
				},
				Suncrest: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Upper Deer Lake': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			townhomes: {
				'Big Bend': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Buckingham Heights': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Burnaby Hospital': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$986,600',
					one_year_change: '+ 6.3%'
				},
				'Burnaby Lake': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$1,204,700',
					one_year_change: '+ 3.0%'
				},
				'Central Park BS': {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$1,043,300',
					one_year_change: '+ 4.8%'
				},
				'Deer Lake': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Deer Lake Place': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Forest Glen BS': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$1,008,800',
					one_year_change: '+ 5.2%'
				},
				'Garden Village': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Greentree Village': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$975,100',
					one_year_change: '+ 8.1%'
				},
				Highgate: {
					sales: 2,
					active_listings: 7,
					benchmark_price: '$915,900',
					one_year_change: '+ 0.3%'
				},
				Metrotown: {
					sales: 4,
					active_listings: 16,
					benchmark_price: '$1,221,200',
					one_year_change: '+ 5.0%'
				},
				Oaklands: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$1,553,500',
					one_year_change: '+ 4.5%'
				},
				'South Slope': {
					sales: 2,
					active_listings: 5,
					benchmark_price: '$1,108,300',
					one_year_change: '+ 5.8%'
				},
				Suncrest: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Upper Deer Lake': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			}
		},

		Ladner: {
			detached: {
				'Annacis Island': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Delta Manor': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$1,361,200',
					one_year_change: '+ 3.7%'
				},
				'East Delta': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: null
				},
				Hawthorne: {
					sales: 4,
					active_listings: 19,
					benchmark_price: '$1,449,900',
					one_year_change: '+ 3.4%'
				},
				Holly: {
					sales: 3,
					active_listings: 17,
					benchmark_price: '$1,443,300',
					one_year_change: '+ 2.0%'
				},
				'Ladner Elementary': {
					sales: 0,
					active_listings: 13,
					benchmark_price: '$1,322,100',
					one_year_change: '+ 3.1%'
				},
				'Ladner Rural': {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Neilsen Grove': {
					sales: 3,
					active_listings: 5,
					benchmark_price: '$1,397,900',
					one_year_change: '+ 3.9%'
				},
				'Port Guichon': {
					sales: 1,
					active_listings: 9,
					benchmark_price: '$1,335,700',
					one_year_change: '+ 4.7%'
				},
				Tilbury: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Westham Island': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: null
				}
			},
			condos: {
				'Annacis Island': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Delta Manor': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$557,900',
					one_year_change: '- 7.7%'
				},
				'East Delta': {
					sales: 1,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				Hawthorne: {
					sales: 1,
					active_listings: 6,
					benchmark_price: '$598,300',
					one_year_change: '- 6.4%'
				},
				Holly: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Ladner Elementary': {
					sales: 1,
					active_listings: 10,
					benchmark_price: '$650,600',
					one_year_change: '8.9%'
				},
				'Ladner Rural': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Neilsen Grove': {
					sales: 2,
					active_listings: 4,
					benchmark_price: '$850,900',
					one_year_change: '- 8.9%'
				},
				'Port Guichon': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				Tilbury: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Westham Island': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				}
			},
			townhomes: {
				'Annacis Island': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Delta Manor': {
					sales: 1,
					active_listings: 3,
					benchmark_price: '$967,300',
					one_year_change: '+ 1.7%'
				},
				'East Delta': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: null
				},
				Hawthorne: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$852,900',
					one_year_change: '+ 1.7%'
				},
				Holly: {
					sales: 2,
					active_listings: 2,
					benchmark_price: '$874,800',
					one_year_change: '+ 0.2%'
				},
				'Ladner Elementary': {
					sales: 1,
					active_listings: 8,
					benchmark_price: '$807,100',
					one_year_change: '+ 0.4%'
				},
				'Ladner Rural': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Neilsen Grove': {
					sales: 6,
					active_listings: 19,
					benchmark_price: '$1,164,700',
					one_year_change: '+ 4.5%'
				},
				'Port Guichon': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				Tilbury: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				},
				'Westham Island': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: null
				}
			}
		},
		'Burnaby North': {
			detached: {
				'Brentwood Park': {
					sales: 2,
					active_listings: 13,
					benchmark_price: '$1,997,500',
					one_year_change: '-3.5%'
				},
				'Capitol Hill BN': {
					sales: 3,
					active_listings: 12,
					benchmark_price: '$2,038,900',
					one_year_change: '+1.4%'
				},
				Cariboo: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Central BN': {
					sales: 0,
					active_listings: 8,
					benchmark_price: '$1,864,900',
					one_year_change: '+7.2%'
				},
				'Forest Hills BN': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Government Road': {
					sales: 2,
					active_listings: 17,
					benchmark_price: '$2,361,600',
					one_year_change: '+3.4%'
				},
				'Lake City Industrial': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Montecito: {
					sales: 1,
					active_listings: 13,
					benchmark_price: '$2,057,400',
					one_year_change: '+0.8%'
				},
				Oakdale: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Parkcrest: {
					sales: 3,
					active_listings: 34,
					benchmark_price: '$2,082,100',
					one_year_change: '+0.3%'
				},
				'Simon Fraser Hills': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Simon Fraser Univer.': {
					sales: 1,
					active_listings: 4,
					benchmark_price: '$2,352,200',
					one_year_change: '+6.1%'
				},
				'Sperling-Duthie': {
					sales: 3,
					active_listings: 12,
					benchmark_price: '$2,087,900',
					one_year_change: '+0.7%'
				},
				'Sullivan Heights': {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Vancouver Heights': {
					sales: 0,
					active_listings: 8,
					benchmark_price: '$2,061,400',
					one_year_change: '+2.2%'
				},
				'Westridge BN': {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$2,449,700',
					one_year_change: '-1.8%'
				},
				'Willingdon Heights': {
					sales: 2,
					active_listings: 14,
					benchmark_price: '$1,946,400',
					one_year_change: '+4.4%'
				}
			},
			condos: {
				'Brentwood Park': {
					sales: 40,
					active_listings: 345,
					benchmark_price: '$798,500',
					one_year_change: '-5.7%'
				},
				'Capitol Hill BN': {
					sales: 2,
					active_listings: 21,
					benchmark_price: '$554,500',
					one_year_change: '-2.5%'
				},
				Cariboo: {
					sales: 3,
					active_listings: 10,
					benchmark_price: '$536,700',
					one_year_change: '+1.0%'
				},
				'Central BN': {
					sales: 3,
					active_listings: 2,
					benchmark_price: '$760,000',
					one_year_change: '-2.5%'
				},
				'Forest Hills BN': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Government Road': {
					sales: 1,
					active_listings: 11,
					benchmark_price: '$589,000',
					one_year_change: '+0.2%'
				},
				'Lake City Industrial': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Montecito: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Oakdale: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Parkcrest: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$836,200',
					one_year_change: '-2.9%'
				},
				'Simon Fraser Hills': {
					sales: 2,
					active_listings: 1,
					benchmark_price: '$472,700',
					one_year_change: '-3.2%'
				},
				'Simon Fraser Univer.': {
					sales: 10,
					active_listings: 69,
					benchmark_price: '$639,500',
					one_year_change: '-3.1%'
				},
				'Sperling-Duthie': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Sullivan Heights': {
					sales: 10,
					active_listings: 58,
					benchmark_price: '$664,400',
					one_year_change: '+0.4%'
				},
				'Vancouver Heights': {
					sales: 3,
					active_listings: 8,
					benchmark_price: '$796,100',
					one_year_change: '-2.3%'
				},
				'Westridge BN': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Willingdon Heights': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$618,600',
					one_year_change: '-2.2%'
				}
			},
			townhomes: {
				'Brentwood Park': {
					sales: 2,
					active_listings: 6,
					benchmark_price: '$1,066,600',
					one_year_change: '+1.1%'
				},
				'Capitol Hill BN': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$859,200',
					one_year_change: '+4.7%'
				},
				Cariboo: {
					sales: 3,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Central BN': {
					sales: 1,
					active_listings: 3,
					benchmark_price: '$974,100',
					one_year_change: '+3.1%'
				},
				'Forest Hills BN': {
					sales: 3,
					active_listings: 4,
					benchmark_price: '$997,500',
					one_year_change: '+1.3%'
				},
				'Government Road': {
					sales: 2,
					active_listings: 4,
					benchmark_price: '$1,097,600',
					one_year_change: '+1.7%'
				},
				'Lake City Industrial': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Montecito: {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$751,600',
					one_year_change: '-0.3%'
				},
				Oakdale: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Parkcrest: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Simon Fraser Hills': {
					sales: 1,
					active_listings: 3,
					benchmark_price: '$816,200',
					one_year_change: '+2.5%'
				},
				'Simon Fraser Univer.': {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$771,600',
					one_year_change: '-2.0%'
				},
				'Sperling-Duthie': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Sullivan Heights': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$799,000',
					one_year_change: '-2.4%'
				},
				'Vancouver Heights': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$1,010,300',
					one_year_change: '+2.5%'
				},
				'Westridge BN': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$716,400',
					one_year_change: '-1.0%'
				},
				'Willingdon Heights': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$1,016,400',
					one_year_change: '+3.5%'
				}
			}
		},

		'West Vancouver': {
			detached: {
				Altamont: {
					sales: 0,
					active_listings: 14,
					benchmark_price: '$5,120,800',
					one_year_change: '- 2.8%'
				},
				Ambleside: {
					sales: 3,
					active_listings: 36,
					benchmark_price: '$2,814,200',
					one_year_change: '+ 3.6%'
				},
				Bayridge: {
					sales: 1,
					active_listings: 9,
					benchmark_price: '$3,053,600',
					one_year_change: '+ 5.4%'
				},
				'British Properties': {
					sales: 4,
					active_listings: 79,
					benchmark_price: '$3,146,400',
					one_year_change: '- 5.8%'
				},
				'Canterbury WV': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Caulfeild: {
					sales: 2,
					active_listings: 27,
					benchmark_price: '$3,184,400',
					one_year_change: '+ 6.0%'
				},
				Cedardale: {
					sales: 0,
					active_listings: 6,
					benchmark_price: '$2,376,200',
					one_year_change: '- 6.9%'
				},
				Chartwell: {
					sales: 0,
					active_listings: 35,
					benchmark_price: '$4,907,500',
					one_year_change: '- 12.5%'
				},
				'Chelsea Park': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Cypress: {
					sales: 1,
					active_listings: 11,
					benchmark_price: '$4,107,000',
					one_year_change: '+ 2.3%'
				},
				'Cypress Park Estates': {
					sales: 1,
					active_listings: 17,
					benchmark_price: '$3,038,100',
					one_year_change: '- 1.6%'
				},
				'Deer Ridge WV': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Dundarave: {
					sales: 2,
					active_listings: 23,
					benchmark_price: '$3,317,800',
					one_year_change: '- 2.0%'
				},
				'Eagle Harbour': {
					sales: 0,
					active_listings: 12,
					benchmark_price: '$2,465,500',
					one_year_change: '+ 8.9%'
				},
				Eagleridge: {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Furry Creek': {
					sales: 1,
					active_listings: 5,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Gleneagles: {
					sales: 0,
					active_listings: 9,
					benchmark_price: '$3,019,300',
					one_year_change: '+ 4.9%'
				},
				Glenmore: {
					sales: 0,
					active_listings: 20,
					benchmark_price: '$2,196,400',
					one_year_change: '+ 0.9%'
				},
				'Horseshoe Bay WV': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$2,053,400',
					one_year_change: '+ 3.1%'
				},
				'Howe Sound': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Lions Bay': {
					sales: 1,
					active_listings: 18,
					benchmark_price: '$2,163,100',
					one_year_change: '+ 5.6%'
				},
				'Olde Caulfeild': {
					sales: 2,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Panorama Village': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Park Royal': {
					sales: 0,
					active_listings: 7,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Passage Island': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Queens: {
					sales: 4,
					active_listings: 9,
					benchmark_price: '$3,304,500',
					one_year_change: '+ 2.0%'
				},
				Rockridge: {
					sales: 1,
					active_listings: 8,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Sandy Cove': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Sentinel Hill': {
					sales: 0,
					active_listings: 19,
					benchmark_price: '$2,722,500',
					one_year_change: '- 3.0%'
				},
				'Upper Caulfeild': {
					sales: 1,
					active_listings: 4,
					benchmark_price: '$3,200,900',
					one_year_change: '+ 5.4%'
				},
				'West Bay': {
					sales: 1,
					active_listings: 10,
					benchmark_price: '$3,871,900',
					one_year_change: '+ 2.9%'
				},
				Westhill: {
					sales: 0,
					active_listings: 11,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Westmount WV': {
					sales: 1,
					active_listings: 12,
					benchmark_price: '$4,653,800',
					one_year_change: '+ 3.4%'
				},
				'Whitby Estates': {
					sales: 2,
					active_listings: 8,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Whytecliff: {
					sales: 1,
					active_listings: 3,
					benchmark_price: '$2,622,000',
					one_year_change: '+ 6.9%'
				}
			},
			condos: {
				Altamont: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Ambleside: {
					sales: 3,
					active_listings: 24,
					benchmark_price: '$910,100',
					one_year_change: '- 14.5%'
				},
				Bayridge: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'British Properties': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Canterbury WV': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Caulfeild: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Cedardale: {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Chartwell: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Chelsea Park': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Cypress: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Cypress Park Estates': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$2,040,900',
					one_year_change: '- 7.3%'
				},
				'Deer Ridge WV': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Dundarave: {
					sales: 1,
					active_listings: 17,
					benchmark_price: '$1,461,100',
					one_year_change: '- 6.6%'
				},
				'Eagle Harbour': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Eagleridge: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Furry Creek': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Gleneagles: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Glenmore: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Horseshoe Bay WV': {
					sales: 1,
					active_listings: 22,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Howe Sound': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Lions Bay': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Olde Caulfeild': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Panorama Village': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$1,426,800',
					one_year_change: '+ 2.7%'
				},
				'Park Royal': {
					sales: 1,
					active_listings: 25,
					benchmark_price: '$1,258,600',
					one_year_change: '- 5.3%'
				},
				'Passage Island': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Queens: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Rockridge: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Sandy Cove': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Sentinel Hill': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Upper Caulfeild': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'West Bay': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Westhill: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Westmount WV': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whitby Estates': {
					sales: 1,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Whytecliff: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			townhomes: {
				Altamont: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Ambleside: {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Bayridge: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'British Properties': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Canterbury WV': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Caulfeild: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Cedardale: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Chartwell: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Chelsea Park': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Cypress: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Cypress Park Estates': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Deer Ridge WV': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Dundarave: {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Eagle Harbour': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Eagleridge: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Furry Creek': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Gleneagles: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Glenmore: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Horseshoe Bay WV': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Howe Sound': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Lions Bay': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Olde Caulfeild': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Panorama Village': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Park Royal': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Passage Island': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Queens: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Rockridge: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Sandy Cove': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Sentinel Hill': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Upper Caulfeild': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'West Bay': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Westhill: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Westmount WV': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whitby Estates': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Whytecliff: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			}
		},

		Squamish: {
			detached: {
				Brackendale: {
					sales: 2,
					active_listings: 7,
					benchmark_price: '$1,308,700',
					one_year_change: '+ 2.2%'
				},
				'Brennan Center': {
					sales: 1,
					active_listings: 3,
					benchmark_price: '$1,525,000',
					one_year_change: '- 5.6%'
				},
				'Britannia Beach': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Business Park': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Dentville: {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Downtown SQ': {
					sales: 0,
					active_listings: 7,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Garibaldi Estates': {
					sales: 1,
					active_listings: 5,
					benchmark_price: '$1,390,100',
					one_year_change: '+ 4.3%'
				},
				'Garibaldi Highlands': {
					sales: 7,
					active_listings: 10,
					benchmark_price: '$1,562,400',
					one_year_change: '- 1.1%'
				},
				'Hospital Hill': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Northyards: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Paradise Valley': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Plateau: {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$1,790,400',
					one_year_change: '- 6.5%'
				},
				'Ring Creek': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Squamish Rural': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Tantalus: {
					sales: 3,
					active_listings: 2,
					benchmark_price: '$1,613,300',
					one_year_change: '- 8.5%'
				},
				'University Highlands': {
					sales: 0,
					active_listings: 11,
					benchmark_price: '$1,714,300',
					one_year_change: '- 6.4%'
				},
				'Upper Squamish': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Valleycliffe: {
					sales: 3,
					active_listings: 1,
					benchmark_price: '$1,260,100',
					one_year_change: '+ 1.2%'
				}
			},
			condos: {
				Brackendale: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Brennan Center': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Britannia Beach': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Business Park': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Dentville: {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$556,100',
					one_year_change: '15.6%'
				},
				'Downtown SQ': {
					sales: 7,
					active_listings: 46,
					benchmark_price: '$583,000',
					one_year_change: '15.7%'
				},
				'Garibaldi Estates': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$484,500',
					one_year_change: '- 16.1%'
				},
				'Garibaldi Highlands': {
					sales: 1,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Hospital Hill': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Northyards: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Paradise Valley': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Plateau: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Ring Creek': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Squamish Rural': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Tantalus: {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$484,500',
					one_year_change: '- 16.1%'
				},
				'University Highlands': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Upper Squamish': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Valleycliffe: {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$367,600',
					one_year_change: '- 21.5%'
				}
			},
			townhomes: {
				Brackendale: {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$868,600',
					one_year_change: '13.2%'
				},
				'Brennan Center': {
					sales: 1,
					active_listings: 3,
					benchmark_price: '$964,700',
					one_year_change: '14.5%'
				},
				'Britannia Beach': {
					sales: 1,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Business Park': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Dentville: {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$969,600',
					one_year_change: '- 14.0%'
				},
				'Downtown SQ': {
					sales: 4,
					active_listings: 7,
					benchmark_price: '$899,000',
					one_year_change: '- 14.2%'
				},
				'Garibaldi Estates': {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$704,900',
					one_year_change: '- 14.3%'
				},
				'Garibaldi Highlands': {
					sales: 2,
					active_listings: 1,
					benchmark_price: '$836,600',
					one_year_change: '- 13.8%'
				},
				'Hospital Hill': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Northyards: {
					sales: 2,
					active_listings: 4,
					benchmark_price: '$864,500',
					one_year_change: '- 14.1%'
				},
				'Paradise Valley': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Plateau: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Ring Creek': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Squamish Rural': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Tantalus: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$960,200',
					one_year_change: '- 14.1%'
				},
				'University Highlands': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Upper Squamish': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Valleycliffe: {
					sales: 8,
					active_listings: 8,
					benchmark_price: '$765,000',
					one_year_change: '- 14.5%'
				}
			}
		},

		Tsawwassen: {
			detached: {
				'Beach Grove': {
					sales: 0,
					active_listings: 16,
					benchmark_price: '$1,678,500',
					one_year_change: '+ 7.3%'
				},
				'Boundary Beach': {
					sales: 1,
					active_listings: 19,
					benchmark_price: '$1,640,900',
					one_year_change: '+ 5.1%'
				},
				'Cliff Drive': {
					sales: 2,
					active_listings: 13,
					benchmark_price: '$1,513,400',
					one_year_change: '+ 5.6%'
				},
				'English Bluff': {
					sales: 0,
					active_listings: 16,
					benchmark_price: '$1,909,700',
					one_year_change: '+ 3.9%'
				},
				'Pebble Hill': {
					sales: 3,
					active_listings: 25,
					benchmark_price: '$1,692,100',
					one_year_change: '+ 7.5%'
				},
				'Tsawwassen Central': {
					sales: 0,
					active_listings: 18,
					benchmark_price: '$1,524,700',
					one_year_change: '+ 5.9%'
				},
				'Tsawwassen East': {
					sales: 0,
					active_listings: 10,
					benchmark_price: '$1,634,200',
					one_year_change: '+ 5.8%'
				},
				'Tsawwassen North': {
					sales: 0,
					active_listings: 7,
					benchmark_price: '$1,782,100',
					one_year_change: '+ 5.5%'
				}
			},
			condos: {
				'Beach Grove': {
					sales: 0,
					active_listings: 17,
					benchmark_price: '$0',
					one_year_change: '- 9.1%'
				},
				'Boundary Beach': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$649,500',
					one_year_change: ''
				},
				'Cliff Drive': {
					sales: 2,
					active_listings: 10,
					benchmark_price: '$572,500',
					one_year_change: '- 8.7%'
				},
				'English Bluff': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Pebble Hill': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Tsawwassen Central': {
					sales: 1,
					active_listings: 6,
					benchmark_price: '$593,100',
					one_year_change: '- 8.3%'
				},
				'Tsawwassen East': {
					sales: 1,
					active_listings: 1,
					benchmark_price: '$618,200',
					one_year_change: '- 7.2%'
				},
				'Tsawwassen North': {
					sales: 11,
					active_listings: 43,
					benchmark_price: '$638,300',
					one_year_change: '- 10.4%'
				}
			},
			townhomes: {
				'Beach Grove': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Boundary Beach': {
					sales: 2,
					active_listings: 9,
					benchmark_price: '$1,187,200',
					one_year_change: '- 0.1%'
				},
				'Cliff Drive': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$905,000',
					one_year_change: '+ 0.3%'
				},
				'English Bluff': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Pebble Hill': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Tsawwassen Central': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$1,114,200',
					one_year_change: '+ 0.7%'
				},
				'Tsawwassen East': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Tsawwassen North': {
					sales: 4,
					active_listings: 22,
					benchmark_price: '$974,300',
					one_year_change: '+ 0.4%'
				}
			}
		},

		Whistler: {
			detached: {
				'Alpine Meadows': {
					sales: 3,
					active_listings: 16,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Alta Vista': {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Bayshores: {
					sales: 0,
					active_listings: 6,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Benchlands: {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Black Tusk - Pinecrest': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Blueberry Hill': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Brio: {
					sales: 0,
					active_listings: 5,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Cheakamus Crossing': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Emerald Estates': {
					sales: 1,
					active_listings: 6,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Function Junction': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Green Lake Estates': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Nesters: {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Nordic: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Rainbow: {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Spring Creek': {
					sales: 0,
					active_listings: 7,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Spruce Grove': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				WedgeWoods: {
					sales: 0,
					active_listings: 6,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Westside: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whistler Cay Estates': {
					sales: 0,
					active_listings: 6,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whistler Cay Heights': {
					sales: 1,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whistler Creek': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whistler Village': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'White Gold': {
					sales: 0,
					active_listings: 6,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			condos: {
				'Alpine Meadows': {
					sales: 1,
					active_listings: 13,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Alta Vista': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Bayshores: {
					sales: 8,
					active_listings: 7,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Benchlands: {
					sales: 9,
					active_listings: 37,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Black Tusk - Pinecrest': {
					sales: 4,
					active_listings: 26,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Blueberry Hill': {
					sales: 3,
					active_listings: 12,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Brio: {
					sales: 1,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Cheakamus Crossing': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Emerald Estates': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Function Junction': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Green Lake Estates': {
					sales: 1,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Nesters: {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Nordic: {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Rainbow: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Spring Creek': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Spruce Grove': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				WedgeWoods: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Westside: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whistler Cay Estates': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whistler Cay Heights': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whistler Creek': {
					sales: 6,
					active_listings: 16,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whistler Village': {
					sales: 12,
					active_listings: 55,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'White Gold': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				}
			},
			townhomes: {
				'Alpine Meadows': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Alta Vista': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Bayshores: {
					sales: 0,
					active_listings: 3,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Benchlands: {
					sales: 2,
					active_listings: 13,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Black Tusk - Pinecrest': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Blueberry Hill': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Brio: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Cheakamus Crossing': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Emerald Estates': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Function Junction': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Green Lake Estates': {
					sales: 0,
					active_listings: 2,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Nesters: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Nordic: {
					sales: 2,
					active_listings: 19,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Rainbow: {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Spring Creek': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Spruce Grove': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				WedgeWoods: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				Westside: {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whistler Cay Estates': {
					sales: 0,
					active_listings: 0,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whistler Cay Heights': {
					sales: 0,
					active_listings: 4,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whistler Creek': {
					sales: 0,
					active_listings: 8,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'Whistler Village': {
					sales: 4,
					active_listings: 19,
					benchmark_price: '$0',
					one_year_change: ''
				},
				'White Gold': {
					sales: 0,
					active_listings: 1,
					benchmark_price: '$0',
					one_year_change: ''
				}
			}
		}
	}
};
