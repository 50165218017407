import React, { useState, useEffect } from 'react';
import { getLetterTemplates, getPdfOfCustomMultipleListing } from '../../../api/serverRequests';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	CircularProgress,
	Typography,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormControlLabel,
	Checkbox,
	Button,
	makeStyles
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme) => ({
	customDialog: {
		maxWidth: '500px',
		width: '100%'
	},
	dialogContent: {
		padding: theme.spacing(2),
		minWidth: '300px',
		minHeight: '250px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'stretch'
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		margin: '2rem'
	},
	button: {
		marginTop: theme.spacing(2),
		backgroundColor: '#004F2B',
		color: 'white',
		'&:hover': {
			backgroundColor: '#003920'
		}
	},
	formControl: {
		marginBottom: theme.spacing(2)
	},
	checkboxContainer: {
		marginBottom: theme.spacing(1)
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: theme.spacing(2),
		paddingBottom: 0
	},
	helpButton: {
		marginLeft: theme.spacing(1),
		padding: theme.spacing(0.5)
	}
}));

const CustomOwnerLetterDialog = ({
	open,
	onClose,
	popupLoading,
	setPopupLoading,
	pdfCustomOwnerUrl,
	setPdfCustomOwnerUrl,
	ownerLetterError,
	setOwnerLetterError,
	tutorialOpen,
	onTutorialClose,
	userState,
	extractMlNums
}) => {
	const classes = useStyles();
	const [letterTemplates, setLetterTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState('');
	const [checkedLetterhead, setCheckedLetterhead] = useState(true);
	const [checkedUsername, setCheckedUsername] = useState(false);
	const [loading, setLoading] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		const lastSelectedTemplate = localStorage.getItem('lastSelectedTemplate');
		getLetterTemplates()
			.then((res) => {
				if (Array.isArray(res.data)) {
					setLetterTemplates(res.data);
					setSelectedTemplate(lastSelectedTemplate || res.data[0]?.Id || '');
				} else {
					console.error('Letter templates data is not an array:', res.data);
					setLetterTemplates([]);
				}
			})
			.catch((error) => {
				console.error('Error fetching letter templates:', error);
				setLetterTemplates([]);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const handleTemplateChange = (e) => {
		const newTemplateId = e.target.value;
		setSelectedTemplate(newTemplateId);
		localStorage.setItem('lastSelectedTemplate', newTemplateId);
	};

	const handleGeneratePdf = async () => {
		const mlNumArr = extractMlNums();
		setPopupLoading(true);
		setButtonLoading(true);
		try {
			const res = await getPdfOfCustomMultipleListing(
				mlNumArr,
				userState.user.name,
				'Generate PDF',
				checkedLetterhead,
				checkedUsername,
				selectedTemplate
			);
			if (res.status === 200) {
				const downloadUrl = window.URL.createObjectURL(res.data);
				setPdfCustomOwnerUrl(downloadUrl);
			}
		} catch (err) {
			console.error(err);
			setOwnerLetterError(true);
		} finally {
			setPopupLoading(false);
			setButtonLoading(false);
		}
	};

	const handleClose = () => {
		setPdfCustomOwnerUrl(null);
		onClose();
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				PaperProps={{ classes: { root: classes.customDialog } }}
			>
				<div className={classes.titleContainer}>
					<Typography variant="h6">Download Customized Owner Letter</Typography>
					<IconButton
						onClick={() => onTutorialClose(true)}
						className={classes.helpButton}
						aria-label="tutorial"
					>
						<HelpOutlineIcon />
					</IconButton>
				</div>
				<DialogContent className={classes.dialogContent}>
					{loading ? (
						<CircularProgress className={classes.spinner} />
					) : pdfCustomOwnerUrl ? (
						<a
							href={pdfCustomOwnerUrl}
							target="_blank"
							rel="noopener noreferrer"
							download="Owner Letters"
						>
							<Button
								variant="contained"
								fullWidth
								className={classes.button}
								onClick={handleClose}
							>
								Download Custom PDF
							</Button>
						</a>
					) : ownerLetterError ? (
						<Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
							Sorry, pdf could not be generated
						</Typography>
					) : (
						<>
							{letterTemplates && letterTemplates.length > 0 && (
								<FormControl fullWidth className={classes.formControl}>
									<InputLabel id="template-select-label">
										Select Template
									</InputLabel>
									<Select
										labelId="template-select-label"
										id="template-select"
										value={selectedTemplate}
										onChange={handleTemplateChange}
									>
										{letterTemplates.map((template) => (
											<MenuItem key={template.Id} value={template.Id}>
												{template.Name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
							<div className={classes.checkboxContainer}>
								<FormControlLabel
									label="Letterhead"
									control={
										<Checkbox
											color="primary"
											checked={checkedLetterhead}
											onChange={(e) => setCheckedLetterhead(e.target.checked)}
										/>
									}
								/>
							</div>
							<div className={classes.checkboxContainer}>
								<FormControlLabel
									label="First and Last name"
									control={
										<Checkbox
											color="primary"
											checked={checkedUsername}
											onChange={(e) => setCheckedUsername(e.target.checked)}
										/>
									}
								/>
							</div>
							<Button
								variant="contained"
								fullWidth
								className={classes.button}
								onClick={handleGeneratePdf}
								disabled={buttonLoading}
							>
								{buttonLoading ? (
									<CircularProgress size={24} />
								) : (
									'Generate Custom PDF'
								)}
							</Button>
						</>
					)}
				</DialogContent>
			</Dialog>

			<Dialog
				open={tutorialOpen}
				onClose={() => onTutorialClose(false)}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<div style={{ position: 'relative', paddingTop: '56.25%' }}>
						<iframe
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%'
							}}
							src="https://www.youtube.com/embed/MwDQnB9jKqY"
							title="Owner Letter Tutorial"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default CustomOwnerLetterDialog;
