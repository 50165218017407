import { useMemo } from 'react';
import { Typography, Box, Table, TableRow, Grid } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import { makeStyles, withStyles } from '@material-ui/styles';
import { currencyParser } from '../../../../helper/parser';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		overflowX: 'auto'
	},
	firstTitle: {
		margin: '0.4rem 0',
		paddingBottom: 0
	},
	title: {
		margin: '2.2rem 0 1rem',
		paddingBottom: 0
	},
	listingDetailsTable: {
		margin: '1rem 2rem 0 0',
		width: 'calc(32vw + 10rem)',
		'@media (max-width: 700px)': {
			width: '100%',
			margin: '0'
		}
	},
	propertyDetailsTable: {
		width: '100%',
		maxWidth: 'calc(32vw + 10rem)',
		margin: '0 auto',
		backgroundColor: 'white',
		borderRadius: '4px',
		'@media (max-width: 700px)': {
			width: '100%',
			margin: '0'
		}
	},
	tableCell: {
		fontSize: '1rem',
		fontWeight: 500,
		padding: 0,
		lineHeight: 1.5
	},
	quickStats: {
		display: 'flex',
		gap: '2rem',
		marginBottom: '2rem',
		flexWrap: 'wrap'
	},
	statItem: {
		display: 'flex',
		alignItems: 'center',
		gap: '0.5rem'
	},
	statIcon: {
		fontSize: '1.2rem',
		color: '#666'
	},
	statValue: {
		fontSize: '1.1rem',
		fontWeight: 500
	},
	statLabel: {
		fontSize: '0.75rem',
		color: '#666',
		marginLeft: '0.25rem',
		textTransform: 'lowercase'
	}
}));

const TableCell = withStyles({
	root: {
		borderBottom: '1px solid rgba(224, 224, 224, 0.3)',
		padding: '0.5rem 1rem',
		'&:first-child': {
			paddingLeft: '1rem'
		}
	}
})(MuiTableCell);

const getAssessmentLink = (searchToken) => {
	return (
		<>
			Assessment{' '}
			<a
				target="_blank"
				rel="noopener noreferrer"
				href={`https://www.bcassessment.ca/Property/Info/${searchToken}`}
				style={{
					verticalAlign: 'middle',
					display: 'inline-flex',
					marginLeft: '4px'
				}}
			>
				<OpenInNewIcon style={{ fontSize: '1rem' }} />
			</a>
		</>
	);
};

export default function LeadDetailTab({ listing }) {
	const classes = useStyles();

	const propertyDetails = useMemo(
		() => [
			{
				label: listing.searchToken ? getAssessmentLink(listing.searchToken) : 'Assessment',
				value: listing.assessment ? `${currencyParser(listing.assessment)}` : ''
			},
			{ label: 'Year Built', value: listing.YrBlt },
			...(listing.FrontageFeet && Math.round(listing.FrontageFeet) !== 0
				? [{ label: 'Frontage', value: `${Math.round(listing.FrontageFeet)} ft` }]
				: []),
			...(listing.LotSzSqFt && Math.round(listing.LotSzSqFt) !== 0
				? [{ label: 'Land size', value: `${Math.round(listing.LotSzSqFt)} sqft` }]
				: [])
		],
		[listing]
	);

	const renderPropertyDetails = () => {
		return propertyDetails
			.filter((detail) => detail.value)
			.map((detail) => (
				<TableRow key={detail.label}>
					<TableCell component="th" scope="row">
						<Typography className={classes.tableCell} component="h6">
							{detail.label}
						</Typography>
					</TableCell>
					<TableCell align="left">
						<Typography className={classes.tableCell} component="h6">
							{detail.value}
						</Typography>
					</TableCell>
				</TableRow>
			));
	};

	return (
		<Box className={classes.root}>
			<Box style={{ overflowX: 'auto' }}>
				<Table className={classes.propertyDetailsTable}>{renderPropertyDetails()}</Table>
			</Box>
		</Box>
	);
}
