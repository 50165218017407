import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseLayout, Map } from '../../';
import { useParams } from 'react-router-dom';
import {
	Paper,
	Typography,
	CircularProgress,
	Snackbar,
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	ButtonGroup,
	Button,
	Card,
	CardMedia,
	CardContent,
	CardActionArea
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import LandscapeIcon from '@material-ui/icons/Landscape';
import BorderHorizontalIcon from '@material-ui/icons/BorderHorizontal';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DirectionsTransitIcon from '@material-ui/icons/DirectionsTransit';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';

const cottage_court_multiple_detached_single_family_dwellings_on_one_lot = require('../../../assets/images/ssmuh/Cottage_Court_multiple_detached_single_family_dwellings_on_one_lot.png');
const duplex_dwellings_two_sets_of_duplexes_or_one_set_with_secondary_suites = require('../../../assets/images/ssmuh/Duplex_Dwellings_two_sets_of_duplexes_or_one_set_with_secondary_suites.png');
const multiplex_dwellings_3_to_6_dwelling_units_in_one_building = require('../../../assets/images/ssmuh/Multiplex_Dwellings_3_to_6_dwelling_units_in_one_building.png');
const rowhouse_dwellings_small_lots_with_no_side_yard_and_up_to_3_dwellings = require('../../../assets/images/ssmuh/Rowhouse_Dwellings_small_lots_with_no_side_yard_and_up_to_3_dwellings.png');
const single_family_dwellings_plus_secondary_suite_and_or_laneway_home = require('../../../assets/images/ssmuh/Single_Family_Dwellings_plus_secondary_suite_and_or_laneway_home.png');

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[2],
		borderRadius: theme.shape.borderRadius,
		position: 'relative'
	},
	ribbon: {
		position: 'absolute',
		top: 0,
		right: 0,
		width: '150px',
		height: '150px',
		overflow: 'hidden',
		zIndex: 10
	},
	ribbonBanner: {
		position: 'absolute',
		top: '30px',
		right: '-35px',
		transform: 'rotate(45deg)',
		backgroundColor: '#e74c3c',
		color: 'white',
		textAlign: 'center',
		width: '200px',
		padding: '8px 0',
		fontWeight: 'bold',
		fontSize: '16px',
		letterSpacing: '1px',
		boxShadow: '0 3px 10px rgba(0,0,0,0.3)',
		textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(2)
	},
	addressContainer: {
		border: '2px solid #27642E',
		borderRadius: '40px',
		padding: theme.spacing(1, 3),
		display: 'inline-block',
		margin: '0 auto',
		marginBottom: theme.spacing(2),
		maxWidth: '100%'
	},
	address: {
		fontWeight: 600,
		fontSize: '1.3rem',
		color: '#333',
		textAlign: 'center'
	},
	propertyStatsContainer: {
		display: 'flex',
		justifyContent: 'center',
		gap: theme.spacing(4),
		marginTop: theme.spacing(1),
		flexWrap: 'wrap'
	},
	statItem: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(0.5)
	},
	statIcon: {
		fontSize: '1.5rem',
		color: '#666'
	},
	statValue: {
		fontSize: '1.2rem',
		fontWeight: 500,
		color: '#333'
	},
	propertyInfoSection: {
		width: '100%',
		marginBottom: theme.spacing(3),
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: theme.spacing(2)
	},
	propertyInfo: {
		flex: '1 1 45%',
		minWidth: '300px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	propertyMap: {
		flex: '1 1 45%',
		minWidth: '300px',
		height: '250px'
	},
	tablesLayout: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: theme.spacing(2),
		width: '100%'
	},
	costsSection: {
		flex: '1 1 45%',
		minWidth: '300px'
	},
	scenariosSection: {
		flex: '1 1 45%',
		minWidth: '300px',
		display: 'flex',
		flexDirection: 'column'
	},
	tableContainer: {
		marginTop: theme.spacing(2),
		width: '100%'
	},
	tableTitle: {
		padding: theme.spacing(0.5),
		fontWeight: 'bold',
		textAlign: 'center',
		backgroundColor: '#FFEB3B',
		marginBottom: theme.spacing(0.5)
	},
	scenarioLowTable: {
		marginTop: theme.spacing(2),
		backgroundColor: '#FFFDE7'
	},
	scenarioFlatTable: {
		marginTop: theme.spacing(1.5),
		backgroundColor: '#E0F7FA'
	},
	scenarioHighTable: {
		marginTop: theme.spacing(1.5),
		backgroundColor: '#F3E5F5'
	},
	scenarioHeader: {
		textAlign: 'center',
		fontWeight: 'bold',
		padding: theme.spacing(0.5)
	},
	numberCell: {
		textAlign: 'right'
	},
	variationSelector: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: theme.spacing(3),
		width: '100%',
		flexWrap: 'wrap',
		gap: theme.spacing(2)
	},
	variationCard: {
		width: 170,
		height: 180,
		transition: 'all 0.3s ease',
		border: '2px solid transparent',
		'&.selected': {
			borderColor: theme.palette.primary.main,
			backgroundColor: theme.palette.primary.light + '20'
		}
	},
	rankDisplay: {
		position: 'absolute',
		top: '5px',
		right: '5px',
		width: '25px',
		height: '25px',
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'white',
		fontWeight: 'bold',
		fontSize: '14px',
		zIndex: 1
	},
	rank1: {
		backgroundColor: '#2E7D32', // Dark green
		borderColor: '#2E7D32'
	},
	rank2: {
		backgroundColor: '#689F38', // Light green
		borderColor: '#689F38'
	},
	rank3: {
		backgroundColor: '#FBC02D', // Yellow
		borderColor: '#FBC02D'
	},
	rank4: {
		backgroundColor: '#F57C00', // Orange
		borderColor: '#F57C00'
	},
	rank5: {
		backgroundColor: '#D32F2F', // Red
		borderColor: '#D32F2F'
	},
	variationMedia: {
		height: 110,
		backgroundSize: 'contain'
	},
	variationTitle: {
		fontSize: '0.875rem',
		textAlign: 'center',
		fontWeight: 'bold'
	},
	variationContent: {
		padding: theme.spacing(1),
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	}
}));

export default function Proforma() {
	const classes = useStyles();
	const { homeid } = useParams();
	const [propertyData, setPropertyData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [point, setPoint] = useState(null);
	const [selectedVariation, setSelectedVariation] = useState(0);
	const [tabLoading, setTabLoading] = useState(false);
	const [visibleVariation, setVisibleVariation] = useState(0);

	// Building images for each variation
	const buildingImages = [
		{
			image: multiplex_dwellings_3_to_6_dwelling_units_in_one_building,
			label: 'Multiplex Dwellings (3-6 units in one building)'
		},
		{
			image: duplex_dwellings_two_sets_of_duplexes_or_one_set_with_secondary_suites,
			label: 'Duplex Dwellings (two sets or one with secondary suites)'
		},
		{
			image: cottage_court_multiple_detached_single_family_dwellings_on_one_lot,
			label: 'Cottage Court (multiple detached dwellings on one lot)'
		},
		{
			image: rowhouse_dwellings_small_lots_with_no_side_yard_and_up_to_3_dwellings,
			label: 'Rowhouse Dwellings (small lots, no side yard, up to 3 units)'
		},
		{
			image: single_family_dwellings_plus_secondary_suite_and_or_laneway_home,
			label: 'Single Family Dwellings (with secondary suite/laneway)'
		},
		{
			image: cottage_court_multiple_detached_single_family_dwellings_on_one_lot,
			label: 'Cottage Court (multiple detached dwellings on one lot)'
		}
	];

	// Define 5 different proforma variations
	const [proformaVariations, setProformaVariations] = useState([
		{
			name: 'Multiplex Dwellings (3-6 units in one building)',
			image: buildingImages[0].image,
			label: buildingImages[0].label,
			rank: 1,
			costs: {
				landCost: 2500000,
				hardCost: 5200000,
				offsites: 350000,
				softCost: 1450000,
				financing: 820000,
				totalCosts: 10320000,
				totalEquityRequired: 3096000
			},
			scenarios: [
				{
					name: 'Low',
					pricePerSqft: 850,
					netRevenue: 3210000,
					returnOnCosts: 31.1,
					returnOnEquity: 103.7,
					returnYearly: 41.5
				},
				{
					name: 'Flat',
					pricePerSqft: 950,
					netRevenue: 4725000,
					returnOnCosts: 45.8,
					returnOnEquity: 152.6,
					returnYearly: 61.0
				},
				{
					name: 'High',
					pricePerSqft: 1050,
					netRevenue: 6240000,
					returnOnCosts: 60.5,
					returnOnEquity: 201.5,
					returnYearly: 80.6
				}
			]
		},
		{
			name: 'Duplex Dwellings (two sets or one with secondary suites)',
			image: buildingImages[1].image,
			label: buildingImages[1].label,
			rank: 2,
			costs: {
				landCost: 2300000,
				hardCost: 4750000,
				offsites: 275000,
				softCost: 1250000,
				financing: 730000,
				totalCosts: 9305000,
				totalEquityRequired: 2791500
			},
			scenarios: [
				{
					name: 'Low',
					pricePerSqft: 825,
					netRevenue: 2950000,
					returnOnCosts: 31.7,
					returnOnEquity: 105.7,
					returnYearly: 42.3
				},
				{
					name: 'Flat',
					pricePerSqft: 925,
					netRevenue: 4250000,
					returnOnCosts: 45.7,
					returnOnEquity: 152.2,
					returnYearly: 60.9
				},
				{
					name: 'High',
					pricePerSqft: 1025,
					netRevenue: 5550000,
					returnOnCosts: 59.6,
					returnOnEquity: 198.8,
					returnYearly: 79.5
				}
			]
		},
		{
			name: 'Cottage Court (multiple detached dwellings on one lot)',
			image: buildingImages[2].image,
			label: buildingImages[2].label,
			rank: 3,
			costs: {
				landCost: 2400000,
				hardCost: 4900000,
				offsites: 320000,
				softCost: 1400000,
				financing: 780000,
				totalCosts: 9800000,
				totalEquityRequired: 2940000
			},
			scenarios: [
				{
					name: 'Low',
					pricePerSqft: 875,
					netRevenue: 3125000,
					returnOnCosts: 31.9,
					returnOnEquity: 106.3,
					returnYearly: 42.5
				},
				{
					name: 'Flat',
					pricePerSqft: 975,
					netRevenue: 4580000,
					returnOnCosts: 46.7,
					returnOnEquity: 155.8,
					returnYearly: 62.3
				},
				{
					name: 'High',
					pricePerSqft: 1075,
					netRevenue: 6035000,
					returnOnCosts: 61.6,
					returnOnEquity: 205.3,
					returnYearly: 82.1
				}
			]
		},
		{
			name: 'Rowhouse Dwellings (small lots, no side yard, up to 3 units)',
			image: buildingImages[3].image,
			label: buildingImages[3].label,
			rank: 4,
			costs: {
				landCost: 2350000,
				hardCost: 4870000,
				offsites: 310000,
				softCost: 1390000,
				financing: 690000,
				totalCosts: 9610000,
				totalEquityRequired: 2883000
			},
			scenarios: [
				{
					name: 'Low',
					pricePerSqft: 830,
					netRevenue: 2875000,
					returnOnCosts: 29.9,
					returnOnEquity: 99.7,
					returnYearly: 39.9
				},
				{
					name: 'Flat',
					pricePerSqft: 930,
					netRevenue: 4320000,
					returnOnCosts: 45.0,
					returnOnEquity: 149.8,
					returnYearly: 59.9
				},
				{
					name: 'High',
					pricePerSqft: 1030,
					netRevenue: 5765000,
					returnOnCosts: 60.0,
					returnOnEquity: 200.0,
					returnYearly: 80.0
				}
			]
		},
		{
			name: 'Single Family Dwellings (with secondary suite/laneway)',
			image: buildingImages[4].image,
			label: buildingImages[4].label,
			rank: 5,
			costs: {
				landCost: 2375000,
				hardCost: 5600000,
				offsites: 315000,
				softCost: 1530000,
				financing: 870000,
				totalCosts: 10690000,
				totalEquityRequired: 3207000
			},
			scenarios: [
				{
					name: 'Low',
					pricePerSqft: 920,
					netRevenue: 3850000,
					returnOnCosts: 36.0,
					returnOnEquity: 120.0,
					returnYearly: 48.0
				},
				{
					name: 'Flat',
					pricePerSqft: 1020,
					netRevenue: 5350000,
					returnOnCosts: 50.0,
					returnOnEquity: 166.8,
					returnYearly: 66.7
				},
				{
					name: 'High',
					pricePerSqft: 1120,
					netRevenue: 6850000,
					returnOnCosts: 64.1,
					returnOnEquity: 213.6,
					returnYearly: 85.4
				}
			]
		}
	]);

	// Current selected proforma data - this is what's actually displayed
	const proformaData = proformaVariations[visibleVariation];

	const handleVariationChange = (index) => {
		if (index !== selectedVariation) {
			// Immediately update the selected variation for visual feedback
			setSelectedVariation(index);

			// Only show loading and update content if it's a different tab
			if (index !== visibleVariation) {
				setTabLoading(true);
				setTimeout(() => {
					setVisibleVariation(index);
					setTabLoading(false);
				}, 2000);
			}
		}
	};

	useEffect(() => {
		const fetchPropertyData = async () => {
			try {
				const apiUrl = `/api/get_most_recent_listing_by_homeId/${homeid}`;
				console.log(`Attempting to fetch from: ${apiUrl}`);

				const response = await fetch(apiUrl);

				console.log('Response status:', response.status);
				console.log('Response headers:', [...response.headers.entries()]);

				const contentType = response.headers.get('content-type');
				if (!contentType || !contentType.includes('application/json')) {
					const textResponse = await response.text();
					console.error(
						'Received non-JSON response:',
						textResponse.substring(0, 500) + '...'
					);
					throw new Error(
						'API returned HTML instead of JSON. Backend endpoint may not exist.'
					);
				}

				const data = await response.json();
				console.log('Property data:', data);
				setPropertyData(data);

				if (data.Latitude && data.Longitude) {
					setPoint(`${data.Latitude} ${data.Longitude}`);
				} else if (data.latitude && data.longitude) {
					setPoint(`${data.latitude} ${data.longitude}`);
				}
			} catch (err) {
				console.error('Error fetching property data:', err);
				setError(
					`API Error: ${err.message}. Check that your backend API exists and is accessible.`
				);
				setOpenSnackbar(true);
			} finally {
				setLoading(false);
			}
		};

		if (homeid) {
			fetchPropertyData();
		} else {
			setError('No home ID provided');
			setOpenSnackbar(true);
			setLoading(false);
		}
	}, [homeid]);

	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	const formatCurrency = (value) => {
		return value
			? value.toLocaleString('en-US', {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0
			  })
			: '0';
	};

	const formatPercent = (value) => {
		return value ? Math.round(value).toString() : '0';
	};

	// Get the CSS class for rank border color
	const getRankColorClass = (rank) => {
		switch (rank) {
			case 1:
				return classes.rank1;
			case 2:
				return classes.rank2;
			case 3:
				return classes.rank3;
			case 4:
				return classes.rank4;
			case 5:
				return classes.rank5;
			default:
				return '';
		}
	};

	return (
		<BaseLayout>
			<Paper className={classes.paper}>
				<div className={classes.ribbon}>
					<div className={classes.ribbonBanner}>BETA</div>
				</div>

				{loading && (
					<div className={classes.loadingContainer}>
						<CircularProgress />
					</div>
				)}

				{propertyData && (
					<React.Fragment>
						<div className={classes.propertyInfoSection}>
							<div className={classes.propertyInfo}>
								<Box className={classes.addressContainer}>
									<Typography className={classes.address}>
										{propertyData.Address}, {propertyData.City}
									</Typography>
								</Box>

								<Box className={classes.propertyStatsContainer}>
									<Box className={classes.statItem}>
										<EventIcon className={classes.statIcon} />
										<Typography className={classes.statValue}>
											{propertyData.YrBlt}
										</Typography>
									</Box>

									<Box className={classes.statItem}>
										<LandscapeIcon className={classes.statIcon} />
										<Typography className={classes.statValue}>
											{propertyData.LotSzSqFt}
											<span className={classes.statUnit}>sqft</span>
										</Typography>
									</Box>

									<Box className={classes.statItem}>
										<BorderHorizontalIcon className={classes.statIcon} />
										<Typography className={classes.statValue}>
											{propertyData.FrontageFeet}
											<span className={classes.statUnit}>ft</span>
										</Typography>
									</Box>

									<Box className={classes.statItem}>
										<AttachMoneyIcon className={classes.statIcon} />
										<Typography className={classes.statValue}>
											{propertyData.Price
												? propertyData.Price.toLocaleString('en-US', {
														minimumFractionDigits: 0,
														maximumFractionDigits: 0
												  })
												: '0'}
										</Typography>
									</Box>

									{propertyData.distanceToClosestSkytrainStation && (
										<Box className={classes.statItem}>
											<DirectionsTransitIcon className={classes.statIcon} />
											<Typography className={classes.statValue}>
												{Math.round(
													propertyData.distanceToClosestSkytrainStation
												)}
												<span className={classes.statUnit}>m</span>
											</Typography>
										</Box>
									)}

									{propertyData.distanceToFTN &&
										propertyData.distanceToFTN < 400 && (
											<Box className={classes.statItem}>
												<DirectionsBusIcon className={classes.statIcon} />
												<Typography className={classes.statValue}>
													{Math.round(propertyData.distanceToFTN)}
													<span className={classes.statUnit}>m</span>
												</Typography>
											</Box>
										)}
								</Box>
							</div>
						</div>

						{/* Variation Selector */}
						<div className={classes.variationSelector}>
							{proformaVariations.map((variation, index) => (
								<Card
									key={index}
									className={`${classes.variationCard} ${
										selectedVariation === index ? 'selected' : ''
									}`}
									onClick={() => handleVariationChange(index)}
									style={{
										borderColor:
											selectedVariation === index
												? undefined
												: variation.rank
												? getRankColorClass(variation.rank).borderColor
												: 'transparent'
									}}
								>
									<CardActionArea>
										<Box position="relative">
											<div
												className={`${
													classes.rankDisplay
												} ${getRankColorClass(variation.rank)}`}
											>
												{variation.rank}
											</div>
											<CardMedia
												className={classes.variationMedia}
												image={variation.image}
												title={variation.label}
											/>
										</Box>
										<CardContent className={classes.variationContent}>
											<Typography className={classes.variationTitle}>
												{variation.name}
											</Typography>
										</CardContent>
									</CardActionArea>
								</Card>
							))}
						</div>

						<div className={classes.tablesLayout}>
							{tabLoading ? (
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
										padding: '50px 0'
									}}
								>
									<CircularProgress />
								</div>
							) : (
								<>
									{/* Left side - Costs */}
									<div className={classes.costsSection}>
										<TableContainer className={classes.tableContainer}>
											<Typography variant="h6" className={classes.tableTitle}>
												Costs
											</Typography>
											<Table size="small" aria-label="costs table">
												<TableBody>
													<TableRow>
														<TableCell component="th" scope="row">
															Land cost
														</TableCell>
														<TableCell className={classes.numberCell}>
															$
															{formatCurrency(
																proformaData.costs.landCost
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Hard cost
														</TableCell>
														<TableCell className={classes.numberCell}>
															$
															{formatCurrency(
																proformaData.costs.hardCost
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Offsites
														</TableCell>
														<TableCell className={classes.numberCell}>
															$
															{formatCurrency(
																proformaData.costs.offsites
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Soft cost
														</TableCell>
														<TableCell className={classes.numberCell}>
															$
															{formatCurrency(
																proformaData.costs.softCost
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Financing
														</TableCell>
														<TableCell className={classes.numberCell}>
															$
															{formatCurrency(
																proformaData.costs.financing
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															<strong>Total Costs</strong>
														</TableCell>
														<TableCell className={classes.numberCell}>
															<strong>
																$
																{formatCurrency(
																	proformaData.costs.totalCosts
																)}
															</strong>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															<strong>Total Equity Required</strong>
														</TableCell>
														<TableCell className={classes.numberCell}>
															<strong>
																$
																{formatCurrency(
																	proformaData.costs
																		.totalEquityRequired
																)}
															</strong>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</div>

									{/* Right side - Scenarios */}
									<div className={classes.scenariosSection}>
										{/* Low Scenario Table */}
										<TableContainer className={classes.scenarioLowTable}>
											<Typography
												variant="subtitle1"
												className={classes.scenarioHeader}
											>
												Low: ${proformaData.scenarios[0].pricePerSqft}/sqf
											</Typography>
											<Table size="small" aria-label="low scenario table">
												<TableBody>
													<TableRow>
														<TableCell component="th" scope="row">
															Net Revenue
														</TableCell>
														<TableCell className={classes.numberCell}>
															$
															{formatCurrency(
																proformaData.scenarios[0].netRevenue
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Return on costs
														</TableCell>
														<TableCell className={classes.numberCell}>
															{formatPercent(
																proformaData.scenarios[0]
																	.returnOnCosts
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Return on equity
														</TableCell>
														<TableCell className={classes.numberCell}>
															{formatPercent(
																proformaData.scenarios[0]
																	.returnOnEquity
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Return yearly (2.5 years)
														</TableCell>
														<TableCell className={classes.numberCell}>
															{formatPercent(
																proformaData.scenarios[0]
																	.returnYearly
															)}
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>

										{/* Flat Scenario Table */}
										<TableContainer className={classes.scenarioFlatTable}>
											<Typography
												variant="subtitle1"
												className={classes.scenarioHeader}
											>
												Flat: ${proformaData.scenarios[1].pricePerSqft}/sqf
											</Typography>
											<Table size="small" aria-label="flat scenario table">
												<TableBody>
													<TableRow>
														<TableCell component="th" scope="row">
															Net Revenue
														</TableCell>
														<TableCell className={classes.numberCell}>
															$
															{formatCurrency(
																proformaData.scenarios[1].netRevenue
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Return on costs
														</TableCell>
														<TableCell className={classes.numberCell}>
															{formatPercent(
																proformaData.scenarios[1]
																	.returnOnCosts
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Return on equity
														</TableCell>
														<TableCell className={classes.numberCell}>
															{formatPercent(
																proformaData.scenarios[1]
																	.returnOnEquity
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Return yearly (2.5 years)
														</TableCell>
														<TableCell className={classes.numberCell}>
															{formatPercent(
																proformaData.scenarios[1]
																	.returnYearly
															)}
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>

										{/* High Scenario Table */}
										<TableContainer className={classes.scenarioHighTable}>
											<Typography
												variant="subtitle1"
												className={classes.scenarioHeader}
											>
												High: ${proformaData.scenarios[2].pricePerSqft}/sqf
											</Typography>
											<Table size="small" aria-label="high scenario table">
												<TableBody>
													<TableRow>
														<TableCell component="th" scope="row">
															Net Revenue
														</TableCell>
														<TableCell className={classes.numberCell}>
															$
															{formatCurrency(
																proformaData.scenarios[2].netRevenue
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Return on costs
														</TableCell>
														<TableCell className={classes.numberCell}>
															{formatPercent(
																proformaData.scenarios[2]
																	.returnOnCosts
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Return on equity
														</TableCell>
														<TableCell className={classes.numberCell}>
															{formatPercent(
																proformaData.scenarios[2]
																	.returnOnEquity
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell component="th" scope="row">
															Return yearly (2.5 years)
														</TableCell>
														<TableCell className={classes.numberCell}>
															{formatPercent(
																proformaData.scenarios[2]
																	.returnYearly
															)}
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</div>
								</>
							)}
						</div>
					</React.Fragment>
				)}

				<Snackbar
					open={openSnackbar}
					autoHideDuration={6000}
					onClose={handleCloseSnackbar}
					message={error}
				/>
			</Paper>
		</BaseLayout>
	);
}
