import React, { useState, useRef } from 'react';
import {
	Drawer,
	Typography,
	IconButton,
	Button,
	makeStyles,
	Box,
	Divider,
	Modal,
	Backdrop,
	Fade
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LaunchIcon from '@material-ui/icons/Launch';
import GetAppIcon from '@material-ui/icons/GetApp';
import QRCode from 'react-qr-code';

const useStyles = makeStyles((theme) => ({
	propertyReportDrawer: {
		width: 300,
		padding: theme.spacing(3)
	},
	propertyReportButton: {
		margin: theme.spacing(1, 0),
		width: '100%'
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(2)
	},
	copySuccess: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(1),
		backgroundColor: theme.palette.success.light,
		borderRadius: theme.spacing(1),
		textAlign: 'center'
	},
	qrCodeContainer: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	qrCode: {
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		backgroundColor: '#fff',
		borderRadius: theme.spacing(1),
		boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
		cursor: 'pointer'
	},
	sectionDivider: {
		margin: theme.spacing(3, 0)
	},
	sectionTitle: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1)
	},
	qrButtonsContainer: {
		display: 'flex',
		gap: theme.spacing(1),
		marginTop: theme.spacing(1)
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	modalContent: {
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.95)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative'
	},
	modalQrCode: {
		padding: theme.spacing(2),
		backgroundColor: '#fff',
		boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
		borderRadius: theme.spacing(1)
	},
	closeModalButton: {
		position: 'absolute',
		top: theme.spacing(3),
		right: theme.spacing(3),
		backgroundColor: 'rgba(255, 255, 255, 0.8)',
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 1)'
		}
	},
	propertyAddress: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		maxWidth: '80%'
	}
}));

const PropertyReportDrawer = ({ open, onClose, userId, userOrganizationId, listing }) => {
	const classes = useStyles();
	const [copySuccess, setCopySuccess] = useState(false);
	const [activeUrl, setActiveUrl] = useState('');
	const [copyQrSuccess, setCopyQrSuccess] = useState(false);
	const [qrModalOpen, setQrModalOpen] = useState(false);
	const qrCodeRef = useRef(null);

	const getPropertyReportUrl = (isRealtorView = false) => {
		const domain = window.location.origin;
		const baseUrl = `${domain}/leadcapture?userid=${userId}&orgId=${userOrganizationId}&address=${encodeURIComponent(
			listing.Address
		)}&city=${encodeURIComponent(listing.City)}&homeId=${listing.HomeId}`;

		return isRealtorView ? `${baseUrl}&realtor=1` : baseUrl;
	};

	const customerViewUrl = getPropertyReportUrl(false);
	const realtorViewUrl = getPropertyReportUrl(true);

	React.useEffect(() => {
		setActiveUrl(realtorViewUrl);
	}, [realtorViewUrl]);

	const handleCopyLink = (url) => {
		navigator.clipboard
			.writeText(url)
			.then(() => {
				setActiveUrl(url);
				setCopySuccess(true);
				setTimeout(() => setCopySuccess(false), 3000);
			})
			.catch((err) => console.error('Failed to copy:', err));
	};

	const handleViewReport = (url) => {
		window.open(url, '_blank');
		onClose();
	};

	const handleOpenQrModal = () => {
		setQrModalOpen(true);
	};

	const handleCloseQrModal = () => {
		setQrModalOpen(false);
	};

	const convertQrToCanvas = () => {
		return new Promise((resolve, reject) => {
			if (qrCodeRef.current) {
				const canvas = document.createElement('canvas');
				const svg = qrCodeRef.current.querySelector('svg');
				const svgData = new XMLSerializer().serializeToString(svg);

				const svgSize = svg.getBoundingClientRect();
				const scale = 4;

				canvas.width = svgSize.width * scale;
				canvas.height = svgSize.height * scale;

				const ctx = canvas.getContext('2d');
				ctx.fillStyle = '#FFFFFF';
				ctx.fillRect(0, 0, canvas.width, canvas.height);

				const img = new Image();
				const blob = new Blob([svgData], { type: 'image/svg+xml' });
				const url = URL.createObjectURL(blob);

				img.onload = function () {
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
					URL.revokeObjectURL(url);
					resolve(canvas);
				};

				img.onerror = function () {
					reject('Failed to load QR code image');
				};

				img.src = url;
			} else {
				reject('QR code reference not available');
			}
		});
	};

	const handleDownloadQRCode = () => {
		convertQrToCanvas()
			.then((canvas) => {
				const dataUrl = canvas.toDataURL('image/png');
				const downloadLink = document.createElement('a');
				downloadLink.href = dataUrl;
				downloadLink.download = `property-report-qr-${listing.HomeId}.png`;
				document.body.appendChild(downloadLink);
				downloadLink.click();
				document.body.removeChild(downloadLink);
			})
			.catch((err) => console.error(err));
	};

	const handleCopyQRCode = async () => {
		try {
			const canvas = await convertQrToCanvas();
			canvas.toBlob(async (blob) => {
				try {
					if (navigator.clipboard && navigator.clipboard.write) {
						const item = new ClipboardItem({
							'image/png': blob
						});
						await navigator.clipboard.write([item]);
						setCopyQrSuccess(true);
					} else {
						const dataUrl = canvas.toDataURL('image/png');
						await navigator.clipboard.writeText(
							`QR Code for property report (${listing.Address}): ${customerViewUrl}`
						);
						setCopyQrSuccess(true);
					}
					setTimeout(() => setCopyQrSuccess(false), 3000);
				} catch (err) {
					console.error('Failed to copy QR code:', err);
				}
			});
		} catch (err) {
			console.error(err);
		}
	};

	const getQrCodeSize = () => {
		if (typeof window !== 'undefined') {
			const smallerDimension = Math.min(window.innerWidth, window.innerHeight);
			return Math.floor(smallerDimension * 0.7);
		}
		return 600;
	};

	return (
		<>
			<Drawer anchor="right" open={open} onClose={onClose}>
				<div className={classes.propertyReportDrawer}>
					<div className={classes.drawerHeader}>
						<Typography variant="h6">
							{' '}
							What would you like to do with this property report?
						</Typography>
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</div>

					<Typography variant="subtitle1" className={classes.sectionTitle}>
						Realtor View (no lead form):
					</Typography>

					<Button
						variant="contained"
						color="primary"
						startIcon={<LaunchIcon />}
						className={classes.propertyReportButton}
						onClick={() => handleViewReport(realtorViewUrl)}
					>
						View Realtor Report
					</Button>

					<Button
						variant="outlined"
						startIcon={<FileCopyIcon />}
						className={classes.propertyReportButton}
						onClick={() => handleCopyLink(realtorViewUrl)}
					>
						Copy Realtor Link
					</Button>

					<Divider className={classes.sectionDivider} />
					<Typography variant="subtitle1" className={classes.sectionTitle}>
						Customer View (with lead form):
					</Typography>

					<div className={classes.qrCodeContainer}>
						<Box className={classes.qrCode} ref={qrCodeRef} onClick={handleOpenQrModal}>
							<QRCode
								value={customerViewUrl}
								size={200}
								level="H"
								bgColor="#FFFFFF"
								fgColor="#000000"
							/>
						</Box>
						<div className={classes.qrButtonsContainer}>
							<Button
								variant="outlined"
								size="small"
								startIcon={<GetAppIcon />}
								onClick={handleDownloadQRCode}
							>
								Download
							</Button>
							<Button
								variant="outlined"
								size="small"
								startIcon={<FileCopyIcon />}
								onClick={handleCopyQRCode}
							>
								Copy
							</Button>
						</div>
						{copyQrSuccess && (
							<div className={classes.copySuccess}>
								<Typography variant="body2">
									QR code copied to clipboard!
								</Typography>
							</div>
						)}
					</div>

					<Button
						variant="contained"
						color="primary"
						startIcon={<LaunchIcon />}
						className={classes.propertyReportButton}
						onClick={() => handleViewReport(customerViewUrl)}
					>
						View Customer Report
					</Button>

					<Button
						variant="outlined"
						startIcon={<FileCopyIcon />}
						className={classes.propertyReportButton}
						onClick={() => handleCopyLink(customerViewUrl)}
					>
						Copy Customer Link
					</Button>

					{copySuccess && (
						<div className={classes.copySuccess}>
							<Typography variant="body2">Link copied to clipboard!</Typography>
						</div>
					)}
				</div>
			</Drawer>

			<Modal
				className={classes.modal}
				open={qrModalOpen}
				onClose={handleCloseQrModal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in={qrModalOpen}>
					<div className={classes.modalContent}>
						<IconButton
							className={classes.closeModalButton}
							onClick={handleCloseQrModal}
						>
							<CloseIcon />
						</IconButton>
						<div className={classes.modalQrCode}>
							<QRCode
								value={customerViewUrl}
								size={getQrCodeSize()}
								level="H"
								bgColor="#FFFFFF"
								fgColor="#000000"
							/>
						</div>
						<Typography variant="h6" className={classes.propertyAddress}>
							{listing.Address}, {listing.City}
						</Typography>
					</div>
				</Fade>
			</Modal>
		</>
	);
};

export default PropertyReportDrawer;
