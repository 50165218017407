import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import {
	Grid,
	Paper,
	TextField,
	Button,
	Typography,
	Box,
	Container,
	AppBar,
	Toolbar,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { sendDataToBackend, fetchUserDetails } from '../../../api/serverRequests';
import DefaultImage from '../../../assets/images/lead_logos/default.png';
import BackgroundImage from '../../../assets/images/background.jpg';
import HomeIcon from '@material-ui/icons/Home';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SearchIcon from '@material-ui/icons/Search';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import {
	getListingPropertyReport,
	getMLNumberByHomeIdPropertyReport,
	getAllNearbyActiveListings,
	getAllNearbySoldListings
} from '../../../api/serverRequests';
import LeadDetailTab from '../../comps/LeadPropertyDetails/LeadDetails';
import LeadNearbyActiveTab from '../../comps/LeadPropertyDetails/LeadNearbyActive';
import LeadNearbySoldTab from '../../comps/LeadPropertyDetails/LeadNearbySold';
import { useUserState } from '../../../hook/customerHook';
import BedIcon from '@material-ui/icons/Hotel';
import BathIcon from '@material-ui/icons/Bathtub';
import KitchenIcon from '@material-ui/icons/Kitchen';
import LeadMarketUpdate from '../../comps/LeadPropertyDetails/LeadMarketUpdate';
import LeadCityMarketUpdate from '../../comps/LeadPropertyDetails/LeadCityMarketUpdate';
import KulbirLeadCityMarketUpdate from '../../comps/LeadPropertyDetails/KulbirLeadCityMarketUpdate';
import KulbirLeadNeighbourhoodMarketUpdate from '../../comps/LeadPropertyDetails/KulbirLeadNeighbourhoodMarketUpdate';
import LeadShowingTips from '../../comps/LeadPropertyDetails/LeadShowingTips';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		overflowX: 'hidden',
		width: '100%',
		background: `url(${BackgroundImage}) no-repeat center center fixed`,
		backgroundSize: 'cover',
		color: '#fff'
	},
	container: {
		padding: theme.spacing(4),
		margin: '0 auto',
		maxWidth: '1000px',
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		borderRadius: '15px'
	},
	paper: {
		padding: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%'
	},
	heroImage: {
		width: '100%',
		height: 'auto',
		marginBottom: theme.spacing(4),
		borderRadius: '10px'
	},
	textField: {
		width: '100%',
		marginBottom: theme.spacing(2),
		borderRadius: '10px',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#004F2B'
			},
			'&:hover fieldset': {
				borderColor: '#00391E'
			},
			'&.Mui-focused fieldset': {
				borderColor: '#004F2B'
			}
		}
	},
	formWrapper: {
		marginTop: theme.spacing(2),
		width: '100%'
	},
	title: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
		textAlign: 'center',
		color: '#333'
	},
	sectionTitle: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		color: '#555'
	},
	submit: {
		marginTop: theme.spacing(3),
		backgroundColor: '#004F2B',
		width: '200px',
		height: '50px',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		color: '#FFFFFF',
		borderRadius: '25px',
		boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
		transition: 'background-color 0.3s, transform 0.3s',
		'&:hover': {
			backgroundColor: '#00391E',
			transform: 'scale(1.05)'
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: 'auto'
		}
	},
	appBar: {
		backgroundColor: 'transparent',
		boxShadow: 'none'
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		color: '#fff'
	},
	addressField: {
		border: '2px solid #004F2B',
		borderRadius: '15px',
		padding: '10px',
		fontSize: '1.2rem',
		color: '#333',
		textAlign: 'center',
		marginBottom: theme.spacing(1),
		backgroundColor: '#f9f9f9'
	},
	inviteText: {
		color: '#555',
		textAlign: 'center',
		fontSize: '1.1rem',
		marginBottom: theme.spacing(3)
	},
	quickStats: {
		display: 'flex',
		justifyContent: 'center',
		gap: '2rem',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		flexWrap: 'wrap'
	},
	statItem: {
		display: 'flex',
		alignItems: 'center',
		gap: '0.5rem'
	},
	statIcon: {
		fontSize: '1.2rem',
		color: '#666'
	},
	statValue: {
		fontSize: '1.1rem',
		fontWeight: 500,
		color: '#333'
	},
	statLabel: {
		fontSize: '0.75rem',
		color: '#666',
		marginLeft: '0.25rem',
		textTransform: 'lowercase'
	},
	loadingContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(4),
		gap: theme.spacing(2)
	},
	loadingText: {
		color: '#333',
		fontWeight: 500,
		textAlign: 'center',
		marginBottom: theme.spacing(2)
	},
	dialogPaper: {
		borderRadius: '15px',
		padding: theme.spacing(2),
		maxWidth: '600px',
		width: '90%'
	},
	formTitle: {
		color: '#333',
		textAlign: 'center',
		marginBottom: theme.spacing(3)
	},
	submitButton: {
		backgroundColor: '#004F2B',
		color: '#FFFFFF',
		'&:hover': {
			backgroundColor: '#00391E'
		}
	},
	banner: {
		backgroundColor: 'green',
		color: 'white',
		textAlign: 'center',
		padding: theme.spacing(1),
		marginTop: theme.spacing(2),
		borderRadius: theme.spacing(1)
	}
}));

export default function LeadCapture() {
	const history = useHistory();
	const classes = useStyles();
	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors }
	} = useForm();
	const [orgLogo, setOrgLogo] = useState(null);
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [userId, setUserId] = useState('');
	const [homeId, setHomeId] = useState('');
	const [orgId, setOrgId] = useState('');
	const [submissionSuccess, setSubmissionSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [openDialog, setOpenDialog] = useState(false);
	const [ml, setMlNum] = useState('');
	const [listing, setListing] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [activeListings, setActiveListings] = useState([]);
	const [isLoadingActiveListings, setIsLoadingActiveListings] = useState(true);
	const [isLoadingSoldListings, setIsLoadingSoldListings] = useState(true);
	const [soldListings, setSoldListings] = useState([]);
	const [userState, dispatchUser] = useUserState();
	const [showBanner, setShowBanner] = useState(false);
	const isAnyLoading = isLoading || isLoadingActiveListings || isLoadingSoldListings;
	const [hasScrolledToSoldListings, setHasScrolledToSoldListings] = useState(false);

	const validateName = (value) => {
		const nameRegex = /^[a-zA-Z\s'-]+$/;
		return nameRegex.test(value) || 'Invalid name format';
	};

	const validatePhoneNumber = (value) => {
		const phoneRegex = /^(?:\(\d{3}\)\s?|^\d{3}[-\s]?)\d{3}[-\s]?\d{4}$/;
		return phoneRegex.test(value) || 'Invalid phone number format';
	};

	const validateEmail = (value) => {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(value) || 'Invalid email address format';
	};

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const renderQuickStats = () => (
		<Box className={classes.quickStats}>
			<Box className={classes.statItem}>
				<BedIcon className={classes.statIcon} />
				<Typography className={classes.statValue}>{listing?.TotBR}</Typography>
			</Box>
			<Box className={classes.statItem}>
				<BathIcon className={classes.statIcon} />
				<Typography className={classes.statValue}>{listing?.TotBaths}</Typography>
			</Box>
			{listing?.NumKitchens > 0 && (
				<Box className={classes.statItem}>
					<KitchenIcon className={classes.statIcon} />
					<Typography className={classes.statValue}>{listing.NumKitchens}</Typography>
				</Box>
			)}
			<Box className={classes.statItem}>
				<HomeIcon className={classes.statIcon} />
				<Typography className={classes.statValue}>
					{listing?.TotFlArea}
					<span className={classes.statLabel}>sqft</span>
				</Typography>
			</Box>
		</Box>
	);

	const onSubmit = (data) => {
		//Close the Dialog
		handleCloseDialog();

		console.log('Form Data Submitted:', data);

		const fullData = {
			City: city,
			Address: address,
			UserId: userId,
			OrganizationId: orgId,
			HomeId: homeId,
			Data: { Name: data.name, Phone: data.phoneNumber, Email: data.emailAddress }
		};
		console.log('Full Data:', fullData);

		sendDataToBackend(fullData)
			.then((response) => {
				setSubmissionSuccess(true);
				setErrorMessage('');

				const params = new URLSearchParams({
					userid: userId,
					orgId,
					address,
					city,
					homeId
				});
			})
			.catch((error) => {
				console.error('Error sending data:', error);
				setErrorMessage('Error submitting form. Please try again.');
				setSubmissionSuccess(false);
			});
	};

	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);

		if (queryParams.get('realtor') !== '1') {
			const setupObserver = () => {
				const soldListingsElement = document.getElementById('lead-nearby-sold-section');

				if (soldListingsElement) {
					const observer = new IntersectionObserver(
						(entries) => {
							console.log('Intersection observed:', entries[0].isIntersecting);
							if (
								entries[0].isIntersecting &&
								!hasScrolledToSoldListings &&
								!openDialog
							) {
								console.log('Triggering dialog from scroll');
								setHasScrolledToSoldListings(true);
								handleOpenDialog();
							}
						},
						{ threshold: 0.3 }
					);

					observer.observe(soldListingsElement);
					return observer;
				}
				return null;
			};

			let observer = setupObserver();
			return () => {
				if (observer) {
					const soldListingsElement = document.getElementById('lead-nearby-sold-section');
					if (soldListingsElement) {
						observer.unobserve(soldListingsElement);
					}
				}
			};
		}
	}, [location, hasScrolledToSoldListings, openDialog, handleOpenDialog]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const address = queryParams.get('address');
		const city = queryParams.get('city');
		const userId = queryParams.get('userid');
		const homeId = queryParams.get('homeId');
		const orgId = queryParams.get('orgId');

		if (address) setAddress(address);
		if (city) setCity(city);
		if (userId) setUserId(userId);
		if (homeId) setHomeId(homeId);
		if (orgId) setOrgId(orgId);

		if (orgId || userId) {
			fetchUserDetails(orgId, userId)
				.then((res) => {
					if (res.data.success) {
						const userDetails = res.data.user_details;
						console.log('userDetails::', userDetails);
						const logoPath = require('../../../assets/images/lead_logos/' +
							userDetails.logo);
						setOrgLogo(logoPath);
					}
				})
				.catch((err) => {
					console.error('Error fetching user details:', err);
				});
		}

		if (city && address) {
			setValue('city', city);
			setValue('address', address);
			if (queryParams.get('realtor') !== '1') {
				sendDataToBackend({
					City: city,
					Address: address,
					UserId: userId,
					OrganizationId: orgId,
					HomeId: homeId
				})
					.then((data) => console.log(data))
					.catch((error) => console.error('Error:', error));
			}
		}
		if (homeId) {
			getMLNumberByHomeIdPropertyReport(homeId)
				.then((response) => {
					if (response.data) {
						console.log(response.data);
						setMlNum(response.data.mlnum);
					}
				})
				.catch((error) => {
					console.error('Error fetching mlNum:', error);
				});
		}
	}, [location, setValue]);

	useEffect(() => {
		const fetchListingData = async () => {
			try {
				const res = await getListingPropertyReport(ml || homeId);
				if (res.status === 200) {
					console.log(res.data.listing);
					setListing(res.data.listing);
				}
			} catch (err) {
				console.error('Error fetching listing:', err.message);
			} finally {
				setIsLoading(false);
			}
		};
		fetchListingData();
	}, [ml]);

	useEffect(() => {
		setIsLoadingActiveListings(true);
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getAllNearbyActiveListings(listing.HomeId)
					.then((res) => {
						if (res.status === 200) setActiveListings(res.data.active_listings);
						if (!unmounted) setIsLoadingActiveListings(false);
					})
					.catch((err) => {
						console.log(err);
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingActiveListings(false);
						}
						console.error(err.message);
					});
			}
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, [listing]);

	useEffect(() => {
		setIsLoadingSoldListings(true);
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getAllNearbySoldListings(listing.HomeId)
					.then((res) => {
						if (res.status === 200) setSoldListings(res.data.sold_listings);
						if (!unmounted) setIsLoadingSoldListings(false);
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingSoldListings(false);
						}
						console.error(err.message);
					});
			}
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, [listing]);

	useEffect(() => {
		if (submissionSuccess) {
			setShowBanner(true);
			const timer = setTimeout(() => {
				setShowBanner(false);
			}, 5000); // 5 seconds

			return () => clearTimeout(timer); // Cleanup on unmount or if submissionSuccess changes
		}
	}, [submissionSuccess]);

	useEffect(() => {
		if (address && city) {
			document.title = `Property Report for ${address}, ${city}`;
		} else {
			document.title = 'Property Report';
		}
	}, [address, city]);

	return (
		<Box className={classes.root}>
			<AppBar position="static" className={classes.appBar}>
				<Toolbar className={classes.toolbar}></Toolbar>
			</AppBar>
			<Container maxWidth="md">
				<img
					src={orgLogo ? orgLogo : DefaultImage}
					alt="Hero"
					className={classes.heroImage}
				/>
				<Grid container justifyContent="center" className={classes.container}>
					<Paper className={classes.paper} elevation={3}>
						<Typography variant="h5" component="h2" className={classes.title}>
							Here is your Detailed Report for
						</Typography>
						{address && (
							<Typography
								variant="h4"
								component="h2"
								className={classes.addressField}
							>
								{address}, {city}
							</Typography>
						)}
						{listing && renderQuickStats()}

						{showBanner && (
							<Typography variant="h6" className={classes.banner}>
								Form submitted successfully!
							</Typography>
						)}

						{errorMessage && (
							<Typography
								variant="h6"
								style={{ color: 'red', textAlign: 'center', marginTop: '20px' }}
							>
								{errorMessage}
							</Typography>
						)}

						<Dialog
							open={openDialog}
							onClose={handleCloseDialog}
							fullWidth
							maxWidth="md"
							disableBackdropClick
							disableEscapeKeyDown
						>
							<DialogTitle></DialogTitle>
							<DialogContent>
								{!submissionSuccess && (
									<div className={classes.formWrapper}>
										<Grid item xs={12} container justifyContent="center">
											<Grid item xs={12} sm={8} md={6}>
												<Typography
													variant="body1"
													style={{ color: '#555', marginBottom: '5px' }}
												>
													<ul
														style={{
															listStyleType: 'none',
															padding: 0,
															margin: 0
														}}
													>
														{[
															{
																text: 'Detailed Property Information',
																icon: <HomeIcon />
															},
															{
																text: 'Current Assessment Values',
																icon: <AssessmentIcon />
															},
															{
																text: 'Nearby Homes for Sale',
																icon: <SearchIcon />
															},
															{
																text: 'Recent Nearby Solds',
																icon: <MonetizationOnIcon />
															},
															{
																text: `Market Statistics in ${city}`,
																icon: <TrendingUpIcon />
															}
														].map(({ text, icon }) => (
															<li
																key={text}
																style={{
																	marginBottom: '8px',
																	display: 'flex',
																	alignItems: 'center'
																}}
															>
																<span
																	style={{
																		color: '#004F2B',
																		marginRight: '10px',
																		display: 'flex',
																		alignItems: 'center'
																	}}
																>
																	{React.cloneElement(icon, {
																		style: {
																			fontSize: '1.2rem'
																		}
																	})}
																</span>
																{text}
															</li>
														))}
													</ul>
												</Typography>
											</Grid>
										</Grid>
										<form onSubmit={handleSubmit(onSubmit)}>
											<Grid container spacing={2}>
												{!address && (
													<Grid item xs={12}>
														<Typography
															variant="h6"
															className={classes.sectionTitle}
														>
															Address Details:
														</Typography>
													</Grid>
												)}
												{!address && (
													<Grid item xs={12} sm={6}>
														<Controller
															name="address"
															control={control}
															defaultValue=""
															render={({ onChange, value }) => (
																<TextField
																	id="addressValue"
																	className={classes.textField}
																	label="Street Address"
																	required
																	value={value}
																	variant="outlined"
																	onChange={(e) =>
																		onChange(e.target.value)
																	}
																/>
															)}
														/>
													</Grid>
												)}
												{!city ? (
													<Grid item xs={12} sm={6}>
														<Controller
															name="city"
															control={control}
															defaultValue=""
															render={({ onChange, value }) => (
																<TextField
																	id="cityInformation"
																	className={classes.textField}
																	label="City"
																	required
																	value={value}
																	variant="outlined"
																	onChange={(e) =>
																		onChange(e.target.value)
																	}
																/>
															)}
														/>
													</Grid>
												) : (
													''
												)}
												<Grid item xs={12}>
													<Typography
														variant="h6"
														className={classes.sectionTitle}
													></Typography>
												</Grid>
												<Grid item xs={12} sm={6}>
													<Controller
														name="name"
														control={control}
														defaultValue=""
														rules={{ validate: validateName }}
														render={({ onChange, value }) => (
															<TextField
																id="name"
																className={classes.textField}
																label="Name"
																required
																error={!!errors.name}
																helperText={
																	errors.name
																		? errors.name.message
																		: ''
																}
																value={value}
																variant="outlined"
																onChange={(e) =>
																	onChange(e.target.value)
																}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<Controller
														name="emailAddress"
														control={control}
														defaultValue=""
														rules={{ validate: validateEmail }}
														render={({ onChange, value }) => (
															<TextField
																id="emailAddress"
																className={classes.textField}
																label="Email Address"
																error={!!errors.emailAddress}
																required
																helperText={
																	errors.emailAddress
																		? errors.emailAddress
																				.message
																		: ''
																}
																value={value}
																variant="outlined"
																onChange={(e) =>
																	onChange(e.target.value)
																}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<Controller
														name="phoneNumber"
														control={control}
														defaultValue=""
														rules={{
															validate: (value) =>
																value === '' ||
																validatePhoneNumber(value)
														}}
														render={({ onChange, value }) => (
															<TextField
																id="phoneNumber"
																className={classes.textField}
																label="Phone Number (optional)"
																error={!!errors.phoneNumber}
																helperText={
																	errors.phoneNumber
																		? errors.phoneNumber.message
																		: ''
																}
																value={value}
																variant="outlined"
																onChange={(e) =>
																	onChange(e.target.value)
																}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<Button
														type="submit"
														variant="contained"
														color="primary"
														className={classes.submit}
													>
														View Full Report
													</Button>
												</Grid>
											</Grid>
										</form>
									</div>
								)}
							</DialogContent>
						</Dialog>
						{isAnyLoading ? (
							<Paper className={classes.paper} elevation={3}>
								<Box className={classes.loadingContainer}>
									<Typography variant="h4" className={classes.loadingText}>
										Please wait while we generate your report...
									</Typography>
									<CircularProgress size={40} style={{ color: '#004F2B' }} />
								</Box>
							</Paper>
						) : (
							<>
								{listing && (
									<Paper className={classes.paper} elevation={3}>
										<LeadDetailTab listing={listing} />
									</Paper>
								)}

								<Paper className={classes.paper} elevation={3}>
									<LeadNearbyActiveTab
										listing={listing}
										activeListings={activeListings}
										isLoadingActiveListings={isLoadingActiveListings}
									/>
								</Paper>

								<Paper className={classes.paper} elevation={3}>
									<div id="lead-nearby-sold-section">
										<LeadNearbySoldTab
											listing={listing}
											soldListings={soldListings}
											isLoadingSoldListings={isLoadingSoldListings}
										/>
									</div>
								</Paper>
								{listing && listing.Region == 'Fraser Valley' && (
									<>
										{listing && <LeadMarketUpdate listing={listing} />}
										{listing && <LeadCityMarketUpdate listing={listing} />}
									</>
								)}
								{listing && listing.Region == 'Greater Vancouver' && (
									<>
										{listing && (
											<KulbirLeadNeighbourhoodMarketUpdate
												listing={listing}
											/>
										)}
										{listing && (
											<KulbirLeadCityMarketUpdate listing={listing} />
										)}
									</>
								)}
								<Paper className={classes.paper} elevation={3}>
									<LeadShowingTips />
								</Paper>
							</>
						)}
					</Paper>
				</Grid>
			</Container>
		</Box>
	);
}
