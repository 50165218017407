import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseLayout } from '../../';
import {
	Paper,
	Typography,
	Link,
	Grid,
	Card,
	CardContent,
	CardActionArea,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	IconButton,
	Dialog,
	DialogContent,
	CardMedia,
	CardActions,
	Button
} from '@material-ui/core';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[2],
		borderRadius: theme.shape.borderRadius
	},
	title: {
		marginBottom: theme.spacing(3),
		color: theme.palette.primary.dark,
		fontWeight: 600
	},
	link: {
		textDecoration: 'none',
		color: theme.palette.text.primary,
		width: '100%',
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	sectionHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(2),
		color: theme.palette.primary.main,
		fontWeight: 500
	},
	card: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: theme.spacing(2),
		boxShadow: theme.shadows[1],
		transition: 'transform 0.3s',
		maxWidth: '100%',
		margin: 'auto',
		borderRadius: theme.shape.borderRadius,
		'&:hover': {
			transform: 'scale(1.02)',
			boxShadow: theme.shadows[4]
		}
	},
	cardMedia: {
		width: 120,
		height: 80,
		borderTopLeftRadius: theme.shape.borderRadius,
		borderBottomLeftRadius: theme.shape.borderRadius
	},
	cardContent: {
		flexGrow: 1,
		padding: theme.spacing(2),
		textAlign: 'left',
		color: theme.palette.text.primary
	},
	cardActions: {
		justifyContent: 'flex-end',
		padding: theme.spacing(1, 2)
	},
	checkIcon: {
		color: theme.palette.success.main,
		marginLeft: theme.spacing(1)
	},
	iconContainer: {
		marginBottom: theme.spacing(2)
	},
	icon: {
		fontSize: 48,
		color: theme.palette.primary.main
	},
	description: {
		marginTop: theme.spacing(1),
		fontSize: '0.875rem',
		color: theme.palette.text.secondary
	},
	button: {
		color: theme.palette.secondary.main
	}
}));

const learnTopics = [
	{
		header: 'Overview of Saige',
		topics: [
			{
				title: 'All features in one video (16 minutes)',
				url: 'https://youtu.be/5ZQYCedtcpQ',
				icon: <YouTubeIcon />,
				description: 'Learn about Saige'
			}
		]
	},
	{
		header: 'Property Search',
		topics: [
			{
				title: 'Condo/Attached Search (under one minute)',
				url: 'https://youtu.be/Bm7N59uxvb0?t=6',
				icon: <YouTubeIcon />,
				description: 'Find condos and attached properties'
			},
			{
				title: 'Homes closed 5 years ago with no activity after (one minute)',
				url: 'https://youtu.be/8f64KxYHg6M?t=2',
				icon: <YouTubeIcon />,
				description: 'Find single family homes'
			},
			{
				title: 'Property Report and QR code',
				url: 'https://youtu.be/Uq52fvIFkOQ',
				icon: <AssessmentIcon />,
				description: 'Property Report and QR code'
			}
		]
	},
	{
		header: 'Door Knocking',
		topics: [
			{
				title: 'Door Knocking',
				url: 'https://youtu.be/xHUGRuRx4T0',
				icon: <YouTubeIcon />,
				description: 'Learn how Saige can help you door knock'
			},
			{
				title: 'Route Planning',
				url: 'https://youtu.be/vWU87I62WqU',
				icon: <YouTubeIcon />,
				description: 'Learn how Saige can help you plan your route'
			},
			{
				title: 'Master Route Optimization in 2 Minutes: Effortless Property Tours',
				url: 'https://youtu.be/yuxf-ElnTVg',
				icon: <YouTubeIcon />,
				description:
					'Streamline your property tours with our easy-to-use route optimization tool!'
			}
		]
	},
	{
		header: 'Organize Your Leads',
		topics: [
			{
				title: 'Organize properties',
				url: 'https://youtu.be/gyKfNDl0SjU',
				icon: <YouTubeIcon />,
				description: 'Learn how Saige can help you door knock'
			},
			{
				title: 'Property Status',
				url: 'https://youtu.be/PSzqCNkrXNA',
				icon: <YouTubeIcon />,
				description: 'Property status and how to user them'
			}
		]
	},
	{
		header: 'Automated Owner Letter',
		topics: [
			{
				title: 'Owner Letter QR Code',
				url: 'https://youtu.be/MwDQnB9jKqY',
				icon: <YouTubeIcon />,
				description: 'Where are my leads after Owner Scans QR Code?'
			}
		]
	},
	{
		header: 'Notifications',
		topics: [
			{
				title: 'Notifications',
				url: 'https://youtu.be/ASlFb1Ond6c',
				icon: <YouTubeIcon />,
				description: 'How to get notified when a lead is scanned'
			}
		]
	},
	{
		header: 'CRM Integration',
		topics: [
			{
				title: 'Send leads to your CRM',
				url: 'https://youtu.be/Fdho7B_4GvY',
				icon: <YouTubeIcon />,
				description: 'Send leads to your CRM'
			},
			{
				title: 'Reach CRM Integration',
				url: 'https://youtu.be/weZQ0tdpC-Y',
				icon: <YouTubeIcon />,
				description: 'Reach CRM Integration'
			}
		]
	},
	{
		header: 'Enrich Data',
		topics: [
			{
				title: 'Enrich Data',
				url: 'https://youtu.be/mS8nWvFH_0U',
				icon: <YouTubeIcon />,
				description: 'How to enrich data of your account'
			}
		]
	}
];

export default function Learn() {
	const classes = useStyles();
	const [openSections, setOpenSections] = React.useState({});
	const [openDialog, setOpenDialog] = React.useState(false);
	const [currentVideoUrl, setCurrentVideoUrl] = React.useState('');
	const [completedTopics, setCompletedTopics] = React.useState(() => {
		const saved = Cookies.get('completedTopics');
		return saved ? JSON.parse(saved) : {};
	});

	const handleToggle = (section) => {
		setOpenSections((prevOpenSections) => ({
			...prevOpenSections,
			[section]: !prevOpenSections[section]
		}));
	};

	const handleClickOpen = (url, title) => {
		const videoId = url.includes('youtu.be')
			? url.split('/').pop().split('?')[0]
			: url.split('v=')[1].split('&')[0];
		const timeParam =
			url.includes('?t=') || url.includes('&t=') ? `?start=${url.split('t=')[1]}` : '';
		setCurrentVideoUrl(`https://www.youtube.com/embed/${videoId}${timeParam}`);
		setOpenDialog(true);
		toggleCompletion(title);
	};

	const handleClose = () => {
		setOpenDialog(false);
	};

	const toggleCompletion = (title) => {
		setCompletedTopics((prev) => {
			const updated = { ...prev, [title]: !prev[title] };
			Cookies.set('completedTopics', JSON.stringify(updated), { expires: 365 });
			return updated;
		});
	};

	return (
		<BaseLayout>
			<Paper className={classes.paper}>
				{/* Learn Topics Grid */}
				<Grid container spacing={3} direction="column" style={{ marginBottom: 24 }}>
					{learnTopics.map((section) => (
						<React.Fragment key={section.header}>
							<Typography
								variant="h6"
								component="h2"
								className={classes.sectionHeader}
								style={{ marginTop: 16, marginBottom: 8 }}
							>
								{section.header}
							</Typography>
							<List component="nav" aria-labelledby="nested-list-subheader">
								{section.topics.map((topic) => (
									<Card key={topic.title} className={classes.card}>
										<CardMedia
											className={classes.cardMedia}
											image={`https://img.youtube.com/vi/${
												topic.url.includes('youtu.be')
													? topic.url.split('/').pop().split('?')[0]
													: topic.url.split('v=')[1].split('&')[0]
											}/0.jpg`}
											title={topic.title}
										/>
										<CardContent className={classes.cardContent}>
											<Typography gutterBottom variant="h6" component="h2">
												{topic.title}
												<CheckCircleIcon
													className={classes.checkIcon}
													onClick={() => toggleCompletion(topic.title)}
													style={{
														cursor: 'pointer',
														opacity: completedTopics[topic.title]
															? 1
															: 0.3
													}}
												/>
											</Typography>
											<Typography
												variant="body2"
												color="textSecondary"
												component="p"
											>
												{topic.description}
											</Typography>
										</CardContent>
										<CardActions className={classes.cardActions}>
											<Button
												size="small"
												className={classes.button}
												onClick={() =>
													handleClickOpen(topic.url, topic.title)
												}
											>
												Watch Video
											</Button>
										</CardActions>
									</Card>
								))}
							</List>
						</React.Fragment>
					))}
				</Grid>
			</Paper>
			<Dialog open={openDialog} onClose={handleClose} maxWidth="md" fullWidth>
				<DialogContent>
					<iframe
						width="100%"
						height="400"
						src={currentVideoUrl}
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						title="YouTube video player"
					/>
				</DialogContent>
			</Dialog>
		</BaseLayout>
	);
}
