import React from 'react';
import {
	Typography,
	Box,
	Grid,
	Paper,
	makeStyles,
	Divider,
	CircularProgress
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import { currencyParser } from '../../../helper/parser';
import SalesRatioProgressBar from './SalesRatioProgressBar';
import { de } from 'date-fns/locale';
import { marketDataGVRpercityAllMonths } from './KulbirNeighbourhoodMarketData';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		marginBottom: theme.spacing(3)
	},
	root: {
		padding: theme.spacing(3),
		width: '100%'
	},
	title: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(3),
		color: '#333',
		textAlign: 'center'
	},
	subtitle: {
		color: '#666',
		marginBottom: theme.spacing(4),
		textAlign: 'center'
	},
	propertyTypeSection: {
		textAlign: 'center',
		marginBottom: theme.spacing(4)
	},
	propertyTypeTitle: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
		color: '#004F2B'
	},
	statsGrid: {
		marginBottom: theme.spacing(2)
	},
	statItem: {
		marginBottom: theme.spacing(1)
	},
	statLabel: {
		color: '#666',
		fontSize: '0.9rem'
	},
	statValue: {
		fontWeight: 'bold',
		fontSize: '1.1rem',
		color: '#333'
	},
	icon: {
		fontSize: 40,
		color: '#004F2B',
		marginBottom: theme.spacing(1)
	},
	cityStats: {
		marginTop: theme.spacing(4),
		padding: theme.spacing(2),
		backgroundColor: '#f5f5f5',
		borderRadius: theme.spacing(1)
	},
	ratioGrid: {
		textAlign: 'center'
	},
	ratioValue: {
		fontWeight: 'bold',
		color: '#004F2B',
		fontSize: '1.2rem'
	},
	noDataMessage: {
		textAlign: 'center',
		marginTop: theme.spacing(2),
		color: '#666'
	}
}));

export default function LeadCityMarketUpdate({ listing }) {
	const classes = useStyles();
	//gemini: In page 1 of this pdf, return market updates for detached, condo, townhomes, for feb of 2025 2024 and one-year-change in json format of {"February 2025": { "Vancouver East": {detached: {"total_active_listings": {"2025": 537, "2024": 410, "one-year-change": "+31.0%"}}}}}. dont need to process table with JAnuary header. do the same for all of these files and add them to this json

	if (!listing || !listing.City || !listing.Area) {
		return null;
	}
	const date = new Date();
	date.setMonth(date.getMonth() - 1); // Set to last month
	const lastMonth = date.toLocaleString('default', { month: 'long', year: 'numeric' });

	const twoMonthsAgoDate = new Date();
	twoMonthsAgoDate.setMonth(twoMonthsAgoDate.getMonth() - 2);
	const twoMonthsAgo = twoMonthsAgoDate.toLocaleString('default', {
		month: 'long',
		year: 'numeric'
	});

	const getPropertyTypeKey = (dwellingClassification, type) => {
		dwellingClassification = dwellingClassification?.toLowerCase();
		type = type?.toLowerCase();
		if (dwellingClassification === 'detached') return 'detached';
		if (
			dwellingClassification === 'attached' &&
			['1/2 duplex', 'duplex', 'triplex', 'townhouse'].includes(type)
		)
			return 'townhomes';
		if (
			dwellingClassification === 'attached' &&
			['apartment/', 'apartment/condo', 'apartment complex (10+ units)'].includes(type)
		)
			return 'condos';
		return null;
	};
	const marketDataGVRpercity =
		marketDataGVRpercityAllMonths[lastMonth] ||
		marketDataGVRpercityAllMonths[twoMonthsAgo] ||
		{};
	const displayMonth = marketDataGVRpercityAllMonths[lastMonth] ? lastMonth : twoMonthsAgo;

	const propertyTypeKey = getPropertyTypeKey(listing.DwellingClassification, listing.Type);
	if (!propertyTypeKey) {
		return (
			<Paper className={classes.paper} elevation={3}>
				<Box className={classes.root}>
					<Typography variant="h5" className={classes.title}>
						{listing.Area ? listing.Area.toUpperCase() : 'AREA'} MARKET STATISTICS
					</Typography>
					<Typography variant="subtitle1" className={classes.subtitle}>
						{displayMonth.toUpperCase()}
					</Typography>
					<Box className={classes.noDataMessage}>
						<Typography variant="body1">
							No market data available for this property type.
						</Typography>
					</Box>
				</Box>
			</Paper>
		);
	}

	let data = {};
	try {
		if (
			marketDataGVRpercity &&
			marketDataGVRpercity[listing.Area] &&
			marketDataGVRpercity[listing.Area][propertyTypeKey]
		) {
			data = marketDataGVRpercity[listing.Area][propertyTypeKey];
		}
	} catch (error) {
		console.error('Error accessing market data:', error);
	}
	data = data[listing.subareaFull];
	console.log('data:::', data);
	const hasValidData = data && Object.keys(data).length > 0;

	if (!hasValidData) {
		return null; // Don't show anything when no data is available
	}

	const getPropertyIcon = (propertyType) => {
		if (!propertyType) return null;

		propertyType = propertyType.toLowerCase();
		if (propertyType === 'detached') return <HomeIcon className={classes.icon} />;
		if (propertyType === 'townhomes') return <BusinessIcon className={classes.icon} />;
		if (propertyType === 'condos') return <ApartmentIcon className={classes.icon} />;
		return <HomeIcon className={classes.icon} />; // Default fallback
	};

	const getChangeColor = (changeStr, isReversed = false) => {
		if (!changeStr) return '#666'; // Neutral color for no data

		const isPositive = changeStr.includes('+');

		if (isReversed) {
			return isPositive ? '#d32f2f' : '#2e7d32';
		} else {
			return isPositive ? '#2e7d32' : '#d32f2f';
		}
	};

	return (
		<Paper className={classes.paper} elevation={3}>
			<Box className={classes.root}>
				<Typography variant="h5" className={classes.title}>
					{listing.subareaFull ? listing.subareaFull.toUpperCase() : 'NEIGHBOURHOOD'}{' '}
					MARKET STATISTICS
				</Typography>
				<Typography variant="subtitle1" className={classes.subtitle}>
					{displayMonth.toUpperCase()}
				</Typography>

				<Box className={classes.propertyTypeSection}>
					{getPropertyIcon(propertyTypeKey)}
					<Typography variant="h6" className={classes.propertyTypeTitle}>
						{propertyTypeKey ? propertyTypeKey.toUpperCase() : 'PROPERTY'}
					</Typography>

					<Grid container spacing={3}>
						{/* Benchmark Price */}
						<Grid item xs={12} sm={6} md={4}>
							<Box
								className={classes.statItem}
								style={{
									padding: '16px',
									borderRadius: '8px',
									backgroundColor: '#f8f9fa',
									height: '100%'
								}}
							>
								<Typography
									className={classes.statLabel}
									style={{ marginBottom: '8px', fontWeight: 500 }}
								>
									Benchmark Price
								</Typography>
								<Typography
									className={classes.statValue}
									style={{ fontSize: '1.3rem', marginBottom: '4px' }}
								>
									{data?.benchmark_price || 'N/A'}
								</Typography>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
									mt={1}
								>
									<Typography
										variant="caption"
										style={{ color: '#666', marginRight: '6px' }}
									>
										Year Change:
									</Typography>
									{data?.one_year_change && (
										<Typography
											variant="caption"
											style={{
												color: getChangeColor(data.one_year_change),
												fontWeight: 'bold'
											}}
										>
											{data.one_year_change}
										</Typography>
									)}
								</Box>
							</Box>
						</Grid>

						{/* Total Sales */}
						{data?.sales !== null && (
							<Grid item xs={12} sm={6} md={4}>
								<Box
									className={classes.statItem}
									style={{
										padding: '16px',
										borderRadius: '8px',
										backgroundColor: '#f8f9fa',
										height: '100%'
									}}
								>
									<Typography
										className={classes.statLabel}
										style={{ marginBottom: '8px', fontWeight: 500 }}
									>
										Total Sales
									</Typography>
									<Typography
										className={classes.statValue}
										style={{ fontSize: '1.3rem', marginBottom: '4px' }}
									>
										{data?.sales >= 0 ? data?.sales : 'N/A'}
									</Typography>
								</Box>
							</Grid>
						)}

						{/* Active Listings */}
						<Grid item xs={12} sm={6} md={4}>
							<Box
								className={classes.statItem}
								style={{
									padding: '16px',
									borderRadius: '8px',
									backgroundColor: '#f8f9fa',
									height: '100%'
								}}
							>
								<Typography
									className={classes.statLabel}
									style={{ marginBottom: '8px', fontWeight: 500 }}
								>
									Active Listings
								</Typography>
								<Typography
									className={classes.statValue}
									style={{ fontSize: '1.3rem', marginBottom: '4px' }}
								>
									{data?.active_listings || 'N/A'}
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Paper>
	);
}
