import React, { useEffect, useState } from 'react';
import {
	Box,
	makeStyles,
	Typography,
	IconButton,
	withStyles,
	Tabs,
	Tab,
	Divider,
	CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationSettings from './NotificationSettings';
import Notification from './Notification';
import InitialSetup from './SetupNotificationScreens/InitialSetup';
import SelectNotificationPreferences from './SetupNotificationScreens/SelectNotificationPreferences';
import { getAllUserNotifications } from '../../../../api/serverRequests';
import { useUserState } from '../../../../hook/customerHook';

const useStyles = makeStyles((theme) => ({
	root: {
		overflowY: 'scroll',
		position: 'absolute',
		top: 70, // the height of the app bar plus a little more
		right: 20,
		width: 450,
		height: '87vh',
		backgroundColor: theme.palette.background.paper,
		borderRadius: 10,
		border: '1px solid #ABABAB',
		boxShadow: '0px 4px 4px 0px #00000040',
		padding: 15,
		paddingLeft: 25,
		'@media (max-width: 470px)': {
			width: '95vw',
			right: 10,
			paddingLeft: 10
		},
		scrollbarWidth: 'none', // Hide scrollbar for Firefox
		'&::-webkit-scrollbar': {
			width: '0.5em' // Hide scrollbar for Chrome, Safari, and Opera
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'transparent' // Hide scrollbar thumb for Chrome, Safari, and Opera
		}
	},
	loadingSpinner: {
		display: 'flex',
		width: '100%',
		height: '100%',
		justifyContent: 'space-around',
		alignItems: 'center'
	},
	tabsRoot: {
		flexGrow: 1
	},
	tabs: {
		fontWeight: theme.typography.fontWeightRegular,
		backgroundColor: theme.palette.white.secondary,
		fontSize: 'clamp(0.9rem, 0.9vw + 0.3rem, 1rem) !important',
		'& .MuiTab-root.Mui-selected': {
			fontWeight: theme.typography.fontWeightBold
		}
	},
	headerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 7
	}
}));

const CustomTab = withStyles({
	root: {
		textTransform: 'none',
		minWidth: 100,
		fontSize: 16
	}
})(Tab);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box style={{ paddingTop: 20 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`
	};
}

export default function NotificationDropdown({
	isNotificationDropdownOpen,
	userNotificationPreferences,
	isLoadingNotificationPreferences,
	reFetchPreferences,
	setReFetchPreferences
}) {
	const classes = useStyles();
	const [dispatchUser] = useUserState();
	const [value, setValue] = useState(0);

	const [isNotificationSettingsOpen, setIsNotificationSettingsOpen] = useState(false); // control settings menu opening/closing

	const [notificationSetupButton, setNotificationSetupButton] = useState(false);

	const [notifications, setNotifications] = useState(null);
	const [loadingNotifications, setLoadingNotifications] = useState(false);
	const [tabHeader1, setTabHeader1] = useState('');
	const [tabHeader2, setTabHeader2] = useState('');

	const handleChange = (event, newValue) => {
		// handles changine between the all notfications tab and unread tab
		setValue(newValue);
	};

	useEffect(() => {
		if (notifications) {
			setTabHeader1(`All •${getLengthOfAllNotifications(notifications)}`);
			setTabHeader2(`Unread •${getLengthOfUnreadNotifications(notifications)}`);
		}
	}, [notifications]);

	useEffect(() => {
		// handles fetching all user notifications
		setLoadingNotifications(true);
		const fetchAllUserNotifications = () => {
			getAllUserNotifications()
				.then((res) => {
					if (res.status === 200) {
						setNotifications(formatNotification(res.data.notifications));
						setLoadingNotifications(false);
					}
				})
				.catch((err) => {
					if (err.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
						setLoadingNotifications(false);
						return;
					} else {
						console.error(err.message);
						setLoadingNotifications(false);
					}
				});
		};
		fetchAllUserNotifications();
	}, []);

	const formatNotification = (notifications) => {
		// formats each notification in notifications so that the appropriate data can be accessed in <Notification/>
		const formattedNotifications = notifications.map((notification) => {
			return {
				notificationId: notification.NotificationId,
				readStatus: notification.ReadByUser ? 1 : 0,
				initial: getInitials(notification.Editor),
				title: notification.Editor,
				message: {
					noHighlight: 'New changes on ',
					highlight: `${notification.Address}, ${notification.City} `,
					noHighlight2: convertActionsArray(notification.Actions)
				},
				time: notification.TimeOfEdit,
				propertyUrl: notification.MLNum
					? `/user/listing/${notification.MLNum}`
					: `/user/home/${notification.HomeId}`
			};
		});

		return formattedNotifications.reverse();
	};

	const getInitials = (fullName) => {
		// gets the initals of the notification editor (used for the mui avatar)
		if (typeof fullName !== 'string' || fullName.trim() === '') {
			return '';
		}

		const matches = fullName.trim().match(/\b\w/g) || [];
		return matches.join('').toUpperCase();
	};

	const convertActionsArray = (str) => {
		// handles converting the actiopns array to a readable form
		const stringWithoutBrackets = str.slice(1, -1);
		const resultArray = stringWithoutBrackets
			.split(',')
			.map((item) => item.trim().slice(1, -1));
		return resultArray.join(',\n');
	};

	const getLengthOfUnreadNotifications = (notificationsArray) => {
		const filteredArray = notificationsArray.filter((item) => item.readStatus === 0);
		const lengthOfFilteredArray = filteredArray.length;
		return lengthOfFilteredArray;
	};
	const getLengthOfAllNotifications = (notificationsArray) => {
		const lengthOfAllNotifications = notificationsArray.length;
		return lengthOfAllNotifications;
	};

	return (
		<>
			{isNotificationDropdownOpen && (
				<Box className={classes.root}>
					{isLoadingNotificationPreferences || loadingNotifications ? (
						<Box className={classes.loadingSpinner}>
							<CircularProgress />
						</Box>
					) : userNotificationPreferences ? (
						<>
							{/* HEADER FOR NOTIFICATION DROPDOWN */}
							<Box className={classes.headerContainer}>
								<Typography component="h4" variant="h4">
									Notifications
								</Typography>
								<Box>
									<IconButton
										aria-label="delete"
										size="small"
										onClick={() =>
											setIsNotificationSettingsOpen(
												!isNotificationSettingsOpen
											)
										}
									>
										<SettingsIcon fontSize="medium" />
									</IconButton>
									<NotificationSettings
										setIsNotificationSettingsOpen={
											setIsNotificationSettingsOpen
										}
										isNotificationSettingsOpen={isNotificationSettingsOpen}
										userNotificationPreferences={userNotificationPreferences}
									/>
								</Box>
							</Box>

							{/* TABS FOR READ AND UNREAD NOTIFICATIONS */}
							<div className={classes.tabsRoot}>
								<Tabs
									value={value}
									onChange={handleChange}
									aria-label="notification tabs"
									indicatorColor="primary"
									textColor="primary"
									className={classes.tabs}
								>
									<CustomTab label={tabHeader1} {...a11yProps(0)} />
									<CustomTab label={tabHeader2} {...a11yProps(1)} />
								</Tabs>
								<Divider />
								<TabPanel value={value} index={0}>
									<Notification
										notifications={notifications}
										setNotifications={setNotifications}
										onlyUnread={false}
									/>
								</TabPanel>
								<TabPanel value={value} index={1}>
									<Notification
										notifications={notifications}
										setNotifications={setNotifications}
										onlyUnread={true}
									/>
								</TabPanel>
							</div>
						</>
					) : // IF THE USER MUST FIRST SETUP NOTIFICATIONS
					!notificationSetupButton ? (
						<InitialSetup setNotificationSetupButton={setNotificationSetupButton} />
					) : (
						<SelectNotificationPreferences
							isLoadingNotificationPreferences={isLoadingNotificationPreferences}
							reFetchPreferences={reFetchPreferences}
							setReFetchPreferences={setReFetchPreferences}
						/>
					)}
				</Box>
			)}
		</>
	);
}
