import React, { useState } from 'react';
import {
	Typography,
	Box,
	Table,
	TableRow,
	CircularProgress,
	Tab,
	Tabs,
	Grid
} from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import { makeStyles, withStyles } from '@material-ui/styles';
import { formatPrice } from '../../../../helper/parser';
import moment from 'moment';

function AssessmentTab({ assessmentDetails, isLoadingAssessment }) {
	const useStyles = makeStyles((theme) => ({
		root: {
			//
		},
		firstTitle: {
			margin: '0.4rem 0',
			paddingBottom: 0
		},
		title: {
			margin: '2.2rem 0 1rem',
			paddingBottom: 0
		},
		listingDetailsTable: {
			margin: '1rem 2rem 1rem 0',
			width: 'calc(20vw + 10rem)',

			'@media (max-width: 700px)': {
				width: 'calc(60vw + 10rem)'
			}
		},
		propertyDetailsTitleContainer: {
			margin: '2.2rem 0 1rem',
			paddingBottom: 0,
			display: 'flex',
			alignItems: 'center'
		},
		propertyDetailsTable: {
			width: 'calc(32vw + 10rem)',
			margin: '1rem 2rem 1rem 0',

			'@media (min-width: 700px)': {
				display: 'flex'
			}
		},
		spacer: {
			marginLeft: '2rem',
			'@media (max-width: 699px)': {
				marginLeft: 0
			}
		},
		buttonSelected: {
			color: '#004F2B',
			textDecoration: 'underline',
			textDecorationColor: '#004F2B'
		},
		centerItem: {
			marginTop: 40,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%'
		},
		noActiveTitle: {
			color: theme.palette.grey['KN_light_grey'],
			textAlign: 'center',
			lineHeight: 2
		},
		lastModified: {
			margin: '1.5rem 0'
		}
	}));

	const classes = useStyles();

	const [selectedTab, setSelectedTab] = useState(0); // State to manage the selected tab index

	// If no assessment data is found, display a message
	if (!assessmentDetails) {
		return (
			<Typography
				variant="h4"
				component="h4"
				className={`${classes.centerItem} ${classes.noActiveTitle}`}
			>
				No Assessment Data Found.
			</Typography>
		);
	}

	const selectedAssessment = assessmentDetails[selectedTab] || {};

	const assessmentDetailsLeft = {
		'Area Jurisdiction Roll': selectedAssessment.Area_Jurisdiction_Roll || '-',
		'Total Value': selectedAssessment.Total_Value
			? formatPrice(selectedAssessment.Total_Value)
			: '-',
		Land: selectedAssessment.Land ? formatPrice(selectedAssessment.Land) : '-',
		Buildings: selectedAssessment.Buildings ? formatPrice(selectedAssessment.Buildings) : '-',
		'Previous Year Value': selectedAssessment.Previous_year_value
			? formatPrice(selectedAssessment.Previous_year_value)
			: '-',
		'Previous Year Buildings': selectedAssessment.Previous_year_buildings
			? formatPrice(selectedAssessment.Previous_year_buildings)
			: '-',
		'Year Built': selectedAssessment.Year_built || '-',
		'No - Of Apartment Units': selectedAssessment.No_of_apartment_units || '-',
		'Parcel ID': selectedAssessment.Parcel_ID || '-'
	};

	const assessmentDetailsRight = {
		Bedrooms: selectedAssessment.Bedrooms || '-',
		Baths: selectedAssessment.Baths || '-',
		Carports: selectedAssessment.Carports || '-',
		'Land Size': selectedAssessment.Land_size || '-',
		'First Floor Area': selectedAssessment.First_floor_area || '-',
		'Second Floor Area': selectedAssessment.Second_floor_area || '-',
		'Basement Finish Area': selectedAssessment.Basement_finish_area || '-',
		'Strata Area': selectedAssessment.Strata_area || '-',
		'Building Storeys': selectedAssessment.Building_Storeys || '-',
		'Gross Leasable Area': selectedAssessment.Gross_leasable_area || '-',
		'Net Leaseable Area': selectedAssessment.Net_leasable_area || '-'
	};

	const AssessmentTableCell = withStyles({
		root: {
			borderBottom: 'none',
			padding: '0.5rem 0'
		}
	})(MuiTableCell);

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	const renderAssessmentDetails = (assessmentDetails) => {
		return Object.entries(assessmentDetails).map(([label, value]) => (
			<TableRow key={label} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				<AssessmentTableCell component="th" scope="row">
					<Typography variant="subtitle1" component="h5">
						{label}
					</Typography>
				</AssessmentTableCell>
				<AssessmentTableCell align="left">
					<Typography variant="subtitle1" component="h5">
						{value}
					</Typography>
				</AssessmentTableCell>
			</TableRow>
		));
	};

	const areDetailsEmpty = (details) => {
		//Checks if the Assessment Detail Info is {null/"-"/""}
		return Object.values(details).every(
			(value) => value === '-' || value === null || value === ''
		);
	};

	const isEmptyDetails =
		areDetailsEmpty(assessmentDetailsLeft) && areDetailsEmpty(assessmentDetailsRight);

	return isLoadingAssessment ? (
		<div className={classes.centerItem}>
			<CircularProgress />
		</div>
	) : isEmptyDetails ? (
		<div className={classes.centerItem}>
			<Typography variant="h4" component="h4" className={`${classes.noActiveTitle}`}>
				No Assessment Data Found
				<br />
				{selectedAssessment.Search_token && (
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`https://www.bcassessment.ca/Property/Info/${selectedAssessment.Search_token}`}
					>
						See assessment value here
					</a>
				)}
			</Typography>
		</div>
	) : (
		<div className={classes.root}>
			<Tabs
				value={selectedTab}
				onChange={handleTabChange}
				aria-label="scrollable tabs"
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="on"
			>
				{assessmentDetails.map((detail, index) => (
					<Tab key={index} label={detail.Year} />
				))}
			</Tabs>

			<Box>
				<a
					target="_blank"
					href={
						'https://www.bcassessment.ca/Property/Info/' +
						selectedAssessment.Search_token
					}
				>
					<Typography variant="h4" component="h4" className={classes.title}>
						{selectedAssessment.Description || 'No Description Found'}
					</Typography>
				</a>
				<Box className={classes.propertyDetailsTable}>
					<Table>{renderAssessmentDetails(assessmentDetailsLeft)}</Table>
					<Table className={classes.spacer}>
						{renderAssessmentDetails(assessmentDetailsRight)}
					</Table>
				</Box>
				{(() => {
					try {
						const salesHistory = selectedAssessment.Sales_history
							? JSON.parse(selectedAssessment.Sales_history)
							: [];
						return (
							salesHistory.length >= 1 && (
								<>
									<Typography
										variant="h4"
										component="h4"
										className={classes.title}
									>
										Sales history (last 3 years)
									</Typography>
									<Table style={{ width: '50%' }}>
										{salesHistory.map((sale, index) => (
											<TableRow key={index}>
												<AssessmentTableCell component="th" scope="row">
													<Typography variant="subtitle1" component="h5">
														{moment(sale.sale_date).format(
															'YYYY-MM-DD'
														)}
													</Typography>
												</AssessmentTableCell>
												<AssessmentTableCell align="left">
													<Typography variant="subtitle1" component="h5">
														{sale.sale_price
															? '$' + formatPrice(sale.sale_price)
															: ''}
													</Typography>
												</AssessmentTableCell>
											</TableRow>
										))}
									</Table>
								</>
							)
						);
					} catch (error) {
						console.error('Error parsing sales history:', error);
						return null;
					}
				})()}

				<Typography variant="h4" component="h4" className={classes.title}>
					Legal Description
				</Typography>
				<Typography variant="subtitle1" component="h5">
					{selectedAssessment.Legal_description || '-'}
				</Typography>
				<Typography variant="subtitle2" component="h5" className={classes.lastModified}>
					{`Last Modified: ${moment
						.utc(selectedAssessment.Last_updated || '-')
						.local()
						.fromNow()}`}
				</Typography>
			</Box>
		</div>
	);
}

export default AssessmentTab;
