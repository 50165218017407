import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseLayout } from '../../';
import {
	Paper,
	Typography,
	Grid,
	TextField,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Snackbar
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[2],
		borderRadius: theme.shape.borderRadius
	},
	searchContainer: {
		marginBottom: theme.spacing(4),
		display: 'flex',
		gap: theme.spacing(2),
		alignItems: 'center'
	},
	searchField: {
		flexGrow: 1
	},
	searchButton: {
		minWidth: 120
	},
	resultCard: {
		marginTop: theme.spacing(2)
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(4)
	}
}));

export default function Flyer() {
	const classes = useStyles();
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [propertyData, setPropertyData] = useState(null);
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const handleSearch = async () => {
		if (!address || !city) {
			setError('Please enter both address and city');
			setOpenSnackbar(true);
			return;
		}

		setLoading(true);
		setError(null);

		try {
			const response = await fetch(
				`/api/geocode?address=${encodeURIComponent(address)}&city=${encodeURIComponent(
					city
				)}`
			);
			const data = await response.json();

			if (!response.ok) {
				throw new Error(data.message || 'Failed to fetch property data');
			}

			setPropertyData(data);
		} catch (err) {
			setError(err.message);
			setOpenSnackbar(true);
		} finally {
			setLoading(false);
		}
	};

	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	return (
		<BaseLayout>
			<Paper className={classes.paper}>
				<Typography variant="h5" gutterBottom>
					Property Search
				</Typography>

				<div className={classes.searchContainer}>
					<TextField
						className={classes.searchField}
						label="Address"
						variant="outlined"
						value={address}
						onChange={(e) => setAddress(e.target.value)}
						placeholder="Enter property address"
					/>
					<TextField
						className={classes.searchField}
						label="City"
						variant="outlined"
						value={city}
						onChange={(e) => setCity(e.target.value)}
						placeholder="Enter city"
					/>
					<Button
						variant="contained"
						color="primary"
						className={classes.searchButton}
						onClick={handleSearch}
						disabled={loading}
						startIcon={<SearchIcon />}
					>
						Search
					</Button>
				</div>

				{loading && (
					<div className={classes.loadingContainer}>
						<CircularProgress />
					</div>
				)}

				{propertyData && (
					<Card className={classes.resultCard}>
						<CardContent>
							<Typography variant="h6" gutterBottom>
								Property Details
							</Typography>
							<Typography>Address: {propertyData.address}</Typography>
							<Typography>City: {propertyData.city}</Typography>
							<Typography>Latitude: {propertyData.latitude}</Typography>
							<Typography>Longitude: {propertyData.longitude}</Typography>
						</CardContent>
					</Card>
				)}

				<Snackbar
					open={openSnackbar}
					autoHideDuration={6000}
					onClose={handleCloseSnackbar}
					message={error}
				/>
			</Paper>
		</BaseLayout>
	);
}
