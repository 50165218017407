import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Box, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		marginTop: theme.spacing(3),
		borderRadius: '10px'
	},
	title: {
		fontWeight: 700,
		marginBottom: theme.spacing(2),
		textAlign: 'center',
		color: '#333'
	},
	introduction: {
		color: '#d32f2f',
		textAlign: 'center',
		marginBottom: theme.spacing(3),
		fontWeight: 500,
		[theme.breakpoints.down('xs')]: {
			fontSize: '0.9rem'
		}
	},
	footer: {
		color: '#d32f2f',
		textAlign: 'center',
		marginTop: theme.spacing(3),
		fontWeight: 500,
		[theme.breakpoints.down('xs')]: {
			fontSize: '0.9rem'
		}
	},
	sectionTitle: {
		fontWeight: 600,
		marginBottom: theme.spacing(1),
		textAlign: 'center',
		color: '#333'
	},
	tipList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none',
		textAlign: 'center'
	},
	tipItem: {
		marginBottom: theme.spacing(0.5),
		fontSize: '0.9rem',
		[theme.breakpoints.down('xs')]: {
			fontSize: '0.85rem'
		}
	},
	sectionContainer: {
		marginBottom: theme.spacing(2)
	}
}));

const LeadShowingTips = () => {
	const classes = useStyles();

	const tips = {
		exterior: [
			"Don't restrict access.",
			'Cut grass and remove all weeds.',
			'Straighten deck furniture.',
			'Remove or coil garden hose neatly.',
			'Place garbage bins out of sight.',
			'Remove seasonal decor.'
		],
		kitchens: [
			'Clean all counter tops.',
			'Remove dishes from the sink.',
			'Remove dish drying racks.',
			'Clean the refrigerator (Front, sides and top).',
			'Remove trash cans.',
			'Remove floor mats.'
		],
		general: [
			'Replace any burned out bulbs.',
			'Conceal cords.',
			'Remove small rugs from floors.',
			'Make beds and close closets.',
			'Remove clutter from all rooms.',
			'Address pet odors.',
			'Deodorize & neutralize.'
		],
		bathrooms: [
			'Clean the countertops.',
			'Remove toilet cleaning brushes.',
			'Remove plungers and trash cans.',
			'Toilet lids must be down.',
			'Remove all floor mats.',
			'Ensure towels are neatly hung.'
		]
	};

	return (
		<Paper className={classes.root} elevation={3}>
			<Typography variant="h5" className={classes.title}>
				TIPS FOR BEST SHOWING
			</Typography>

			<Typography variant="body1" className={classes.introduction}>
				Buyers are excited to see your home. They have high hopes that this will be the one!
				Everything you do to help bring that vision to a reality will benefit you in the
				long run.
			</Typography>

			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} className={classes.sectionContainer}>
					<Typography variant="h6" className={classes.sectionTitle}>
						EXTERIOR:
					</Typography>
					<ul className={classes.tipList}>
						{tips.exterior.map((tip, index) => (
							<li key={`exterior-${index}`} className={classes.tipItem}>
								{tip}
							</li>
						))}
					</ul>
				</Grid>

				<Grid item xs={12} sm={6} className={classes.sectionContainer}>
					<Typography variant="h6" className={classes.sectionTitle}>
						KITCHENS:
					</Typography>
					<ul className={classes.tipList}>
						{tips.kitchens.map((tip, index) => (
							<li key={`kitchen-${index}`} className={classes.tipItem}>
								{tip}
							</li>
						))}
					</ul>
				</Grid>

				<Grid item xs={12} sm={6} className={classes.sectionContainer}>
					<Typography variant="h6" className={classes.sectionTitle}>
						GENERAL:
					</Typography>
					<ul className={classes.tipList}>
						{tips.general.map((tip, index) => (
							<li key={`general-${index}`} className={classes.tipItem}>
								{tip}
							</li>
						))}
					</ul>
				</Grid>

				<Grid item xs={12} sm={6} className={classes.sectionContainer}>
					<Typography variant="h6" className={classes.sectionTitle}>
						BATHROOMS:
					</Typography>
					<ul className={classes.tipList}>
						{tips.bathrooms.map((tip, index) => (
							<li key={`bathroom-${index}`} className={classes.tipItem}>
								{tip}
							</li>
						))}
					</ul>
				</Grid>
			</Grid>

			<Typography variant="body1" className={classes.footer}>
				Store all removed items in the garage, closets, cabinets or pick one room in your
				home to store all the removed items.
			</Typography>
		</Paper>
	);
};

export default LeadShowingTips;
