import React, { useState, useEffect } from 'react';
import {
	Typography,
	Box,
	Grid,
	Paper,
	makeStyles,
	Divider,
	CircularProgress
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import { currencyParser } from '../../../helper/parser';
import SalesRatioProgressBar from './SalesRatioProgressBar';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		marginBottom: theme.spacing(3)
	},
	root: {
		padding: theme.spacing(3),
		width: '100%'
	},
	title: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(3),
		color: '#333',
		textAlign: 'center'
	},
	subtitle: {
		color: '#666',
		marginBottom: theme.spacing(4),
		textAlign: 'center'
	},
	propertyTypeSection: {
		textAlign: 'center',
		marginBottom: theme.spacing(4)
	},
	propertyTypeTitle: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
		color: '#004F2B'
	},
	statsGrid: {
		marginBottom: theme.spacing(2)
	},
	statItem: {
		marginBottom: theme.spacing(1)
	},
	statLabel: {
		color: '#666',
		fontSize: '0.9rem'
	},
	statValue: {
		fontWeight: 'bold',
		fontSize: '1.1rem',
		color: '#333'
	},
	icon: {
		fontSize: 40,
		color: '#004F2B',
		marginBottom: theme.spacing(1)
	},
	salesRatioSection: {
		marginTop: theme.spacing(4),
		padding: theme.spacing(2),
		backgroundColor: '#f5f5f5',
		borderRadius: theme.spacing(1)
	},
	ratioGrid: {
		textAlign: 'center',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	ratioValue: {
		fontWeight: 'bold',
		color: '#004F2B',
		fontSize: '1.2rem'
	},
	totalRatio: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
		fontWeight: 'bold'
	},
	centeredItem: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '200px',
		width: '100%'
	},
	errorMessage: {
		color: 'red',
		textAlign: 'center',
		padding: theme.spacing(2)
	}
}));

// Function to calculate sales ratio
function calculateSalesRatio(marketData, propertyType, region) {
	const sales = marketData[propertyType][region].sales || 0;
	const activeListings = marketData[propertyType][region].activeListings || 0;

	if (activeListings === 0) {
		return 0; // Avoid division by zero
	}

	const ratio = (100 * sales) / activeListings;
	return parseFloat(ratio.toFixed(1));
}

export default function LeadMarketUpdate({ listing }) {
	const classes = useStyles();
	const [marketData, setMarketData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [month, setMonth] = useState('');

	// Fetch market data from the backend
	useEffect(() => {
		const fetchMarketData = async () => {
			try {
				setLoading(true);
				const response = await axios.get('/api/market-data');
				if (response.data.status === 'success') {
					setMarketData(response.data.data);
					setMonth(response.data.month);
				} else {
					setError('Failed to fetch market data');
				}
				setLoading(false);
			} catch (err) {
				console.error('Error fetching market data:', err);
				setError('Error fetching market data. Please try again later.');
				setLoading(false);
			}
		};

		fetchMarketData();
	}, []);

	const getPropertyTypeKey = (dwellingClassification, type) => {
		dwellingClassification = dwellingClassification?.toLowerCase();
		type = type?.toLowerCase();
		if (dwellingClassification === 'detached') return 'detached';
		if (
			dwellingClassification === 'attached' &&
			['1/2 duplex', 'duplex', 'triplex', 'townhouse'].includes(type)
		)
			return 'attached';
		if (
			dwellingClassification === 'attached' &&
			['apartment/', 'apartment/condo', 'apartment complex (10+ units)'].includes(type)
		)
			return 'apartment';
		return null;
	};

	// If still loading, show loading indicator
	if (loading) {
		return (
			<Paper className={classes.paper} elevation={3}>
				<Box className={classes.loadingContainer}>
					<CircularProgress />
				</Box>
			</Paper>
		);
	}

	// If there was an error or no market data, show error message
	if (error || !marketData || !listing || !listing.Region) {
		return (
			<Paper className={classes.paper} elevation={3}>
				<Box className={classes.errorMessage}>
					{error || 'No market data available for this property.'}
				</Box>
			</Paper>
		);
	}
	const propertyTypeKey = getPropertyTypeKey(listing.DwellingClassification, listing.Type);
	const isDetachedMatch = propertyTypeKey === 'detached';
	const isAttachedMatch = propertyTypeKey === 'attached';
	const isApartmentMatch = propertyTypeKey === 'apartment';
	const PropertyTypeStatsComponent = ({ data, title, icon }) => (
		<Box className={classes.propertyTypeSection}>
			{icon}
			<Typography variant="h6" className={classes.propertyTypeTitle}>
				{title}
			</Typography>
			<Grid container spacing={2} className={classes.statsGrid}>
				<Grid item xs={6} sm={3}>
					<Box className={classes.statItem}>
						<Typography className={classes.statLabel}>Active Listings</Typography>
						<Typography className={classes.statValue}>
							{data.activeListings.toLocaleString()}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={6} sm={3}>
					<Box className={classes.statItem}>
						<Typography className={classes.statLabel}>Sales</Typography>
						<Typography className={classes.statValue}>
							{data.sales.toLocaleString()}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={6} sm={3}>
					<Box className={classes.statItem}>
						<Typography className={classes.statLabel}>Benchmark Price</Typography>
						<Typography className={classes.statValue}>
							{currencyParser(data.benchmarkPrice)}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={6} sm={3}>
					<Box className={classes.statItem}>
						<Typography className={classes.statLabel}>Avg. Days On Market</Typography>
						<Typography className={classes.statValue}>
							{data.avgDaysOnMarket}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);

	return (
		<Paper className={classes.paper} elevation={3}>
			<Box className={classes.root}>
				<Typography variant="h5" className={classes.title}>
					{listing.Region.toUpperCase()} MARKET HIGHLIGHTS
				</Typography>
				<Typography variant="subtitle1" className={classes.subtitle}>
					{month}
				</Typography>
				{isDetachedMatch && (
					<>
						<PropertyTypeStatsComponent
							data={marketData['detached'][listing.Region]}
							title="DETACHED"
							icon={<HomeIcon className={classes.icon} />}
						/>
						<Divider />
					</>
				)}
				{isAttachedMatch && (
					<>
						<PropertyTypeStatsComponent
							data={marketData['attached'][listing.Region]}
							title="ATTACHED"
							icon={<BusinessIcon className={classes.icon} />}
						/>
					</>
				)}
				{isApartmentMatch && (
					<>
						<PropertyTypeStatsComponent
							data={marketData['apartment'][listing.Region]}
							title="APARTMENT"
							icon={<ApartmentIcon className={classes.icon} />}
						/>
					</>
				)}
				<Box className={classes.salesRatioSection}>
					<Typography variant="h6" align="center" gutterBottom>
						Sales-to-Listings Ratio
					</Typography>
					{isDetachedMatch && (
						<SalesRatioProgressBar
							salesRatio={calculateSalesRatio(marketData, 'detached', listing.Region)}
							propertyType="DETACHED"
						/>
					)}
					{isAttachedMatch && (
						<SalesRatioProgressBar
							salesRatio={calculateSalesRatio(marketData, 'attached', listing.Region)}
							propertyType="ATTACHED"
						/>
					)}
					{isApartmentMatch && (
						<SalesRatioProgressBar
							salesRatio={calculateSalesRatio(
								marketData,
								'apartment',
								listing.Region
							)}
							propertyType="APARTMENT"
						/>
					)}
				</Box>
			</Box>
		</Paper>
	);
}
