import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

// Brand colors from the provided palette
const BRAND_COLORS = {
	darkGreen: '#004F2B',
	orange: '#FF9900',
	yellow: '#EEED6F',
	darkGray: '#262626',
	offWhite: '#F8F7F3'
};

const useStyles = makeStyles((theme) => ({
	progressBarContainer: {
		position: 'relative',
		height: 30,
		backgroundColor: BRAND_COLORS.offWhite,
		borderRadius: 4,
		width: '100%',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2)
	},
	progressBar: {
		height: '100%',
		borderRadius: 4,
		position: 'absolute',
		left: 0,
		top: 0
	},
	progressBarDivider: {
		width: 2,
		height: 30,
		backgroundColor: BRAND_COLORS.darkGray,
		position: 'absolute',
		top: 0
	},
	progressBarIndicator: {
		position: 'absolute',
		top: -20,
		transform: 'translateX(-50%)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	indicatorDot: {
		width: 12,
		height: 12,
		borderRadius: '50%',
		marginBottom: 5
	},
	indicatorLabel: {
		fontSize: '0.8rem',
		fontWeight: 'bold',
		color: BRAND_COLORS.darkGray
	},
	progressScale: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 5,
		color: BRAND_COLORS.darkGray
	},
	marketTypeLabel: {
		fontWeight: 'bold',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		textAlign: 'center'
	},
	legendContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1)
	},
	legendItem: {
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(2),
		fontSize: '0.8rem'
	},
	legendDot: {
		width: 10,
		height: 10,
		borderRadius: '50%',
		marginRight: theme.spacing(0.5)
	}
}));

const SalesRatioProgressBar = ({ salesRatio, propertyType }) => {
	const classes = useStyles();

	// Cap at 50% and scale the display ratio for visual representation
	const maxRatio = 50;
	const normalizedRatio = Math.min(salesRatio, maxRatio);
	const visualRatio = (normalizedRatio / maxRatio) * 100; // Convert to percentage of our visual scale

	// Define market type based on sales ratio
	let marketType = '';
	let marketTypeColor = '';

	if (salesRatio < 12) {
		marketType = "Buyer's Market";
		marketTypeColor = BRAND_COLORS.yellow;
	} else if (salesRatio >= 12 && salesRatio <= 20) {
		marketType = 'Balanced Market';
		marketTypeColor = BRAND_COLORS.orange;
	} else {
		marketType = "Seller's Market";
		marketTypeColor = BRAND_COLORS.darkGreen;
	}

	// Define color based on market type using brand colors
	let indicatorColor = marketTypeColor;

	// Calculate segment widths as percentages of our visual scale
	const buyerMarketWidth = (12 / maxRatio) * 100; // 0-12% (24% of visual width)
	const balancedMarketWidth = ((20 - 12) / maxRatio) * 100; // 12-20% (16% of visual width)
	const sellerMarketWidth = ((maxRatio - 20) / maxRatio) * 100; // 20-50% (60% of visual width)

	return (
		<Box mt={2} width="100%">
			<Typography
				variant="subtitle2"
				align="center"
				gutterBottom
				style={{ color: BRAND_COLORS.darkGreen, fontWeight: 'bold' }}
			>
				{propertyType} ({salesRatio}%)
			</Typography>

			<Typography className={classes.marketTypeLabel} style={{ color: marketTypeColor }}>
				{marketType}
			</Typography>

			<Box className={classes.progressBarContainer}>
				{/* Buyer's Market Zone (0-12%) */}
				<Box
					className={classes.progressBar}
					style={{ width: `${buyerMarketWidth}%`, backgroundColor: BRAND_COLORS.yellow }}
				/>

				{/* Balanced Market Zone (12-20%) */}
				<Box
					style={{
						position: 'absolute',
						left: `${buyerMarketWidth}%`,
						width: `${balancedMarketWidth}%`,
						height: '100%',
						background: `repeating-linear-gradient(45deg, ${BRAND_COLORS.yellow}, ${BRAND_COLORS.yellow} 5px, ${BRAND_COLORS.orange} 5px, ${BRAND_COLORS.orange} 10px)`,
						zIndex: 1
					}}
				/>

				{/* Seller's Market Zone (20-50%) */}
				<Box
					style={{
						position: 'absolute',
						left: `${buyerMarketWidth + balancedMarketWidth}%`,
						width: `${sellerMarketWidth}%`,
						height: '100%',
						backgroundColor: BRAND_COLORS.darkGreen,
						zIndex: 0
					}}
				/>

				{/* Position indicator for the current ratio */}
				<Box className={classes.progressBarIndicator} style={{ left: `${visualRatio}%` }}>
					<Box
						className={classes.indicatorDot}
						style={{ backgroundColor: indicatorColor }}
					/>
					<Typography className={classes.indicatorLabel}>{salesRatio}%</Typography>
				</Box>

				{/* Dividers for 12% and 20% */}
				<Box
					className={classes.progressBarDivider}
					style={{ left: `${buyerMarketWidth}%` }}
				/>
				<Box
					className={classes.progressBarDivider}
					style={{ left: `${buyerMarketWidth + balancedMarketWidth}%` }}
				/>
			</Box>

			{/* Scale labels */}
			<Box className={classes.progressScale}>
				<Typography variant="caption">0%</Typography>
				<Typography variant="caption" style={{ marginLeft: `${buyerMarketWidth - 4}%` }}>
					12%
				</Typography>
				<Typography variant="caption" style={{ marginLeft: `${balancedMarketWidth - 4}%` }}>
					20%
				</Typography>
				<Typography variant="caption" style={{ marginLeft: 'auto' }}>
					50%
				</Typography>
			</Box>

			{/* Legend */}
			<Box className={classes.legendContainer}>
				<Box className={classes.legendItem}>
					<Box
						className={classes.legendDot}
						style={{ backgroundColor: BRAND_COLORS.yellow }}
					></Box>
					<Typography variant="caption">Below 12%: Buyer's Market</Typography>
				</Box>
				<Box className={classes.legendItem}>
					<Box
						className={classes.legendDot}
						style={{ backgroundColor: BRAND_COLORS.orange }}
					></Box>
					<Typography variant="caption">12-20%: Balanced Market</Typography>
				</Box>
				<Box className={classes.legendItem}>
					<Box
						className={classes.legendDot}
						style={{ backgroundColor: BRAND_COLORS.darkGreen }}
					></Box>
					<Typography variant="caption">Above 20%: Seller's Market</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default SalesRatioProgressBar;
