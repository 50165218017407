import React from 'react';
import {
	Typography,
	Box,
	Grid,
	Paper,
	makeStyles,
	Divider,
	CircularProgress
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import { marketDataGVRpercityAllMonths } from './KulbirLeadCityMarketData';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		marginBottom: theme.spacing(3)
	},
	root: {
		padding: theme.spacing(3),
		width: '100%'
	},
	title: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(3),
		color: '#333',
		textAlign: 'center'
	},
	subtitle: {
		color: '#666',
		marginBottom: theme.spacing(4),
		textAlign: 'center'
	},
	propertyTypeSection: {
		textAlign: 'center',
		marginBottom: theme.spacing(4)
	},
	propertyTypeTitle: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
		color: '#004F2B'
	},
	statsGrid: {
		marginBottom: theme.spacing(2)
	},
	statItem: {
		marginBottom: theme.spacing(1)
	},
	statLabel: {
		color: '#666',
		fontSize: '0.9rem'
	},
	statValue: {
		fontWeight: 'bold',
		fontSize: '1.1rem',
		color: '#333'
	},
	icon: {
		fontSize: 40,
		color: '#004F2B',
		marginBottom: theme.spacing(1)
	},
	cityStats: {
		marginTop: theme.spacing(4),
		padding: theme.spacing(2),
		backgroundColor: '#f5f5f5',
		borderRadius: theme.spacing(1)
	},
	ratioGrid: {
		textAlign: 'center'
	},
	ratioValue: {
		fontWeight: 'bold',
		color: '#004F2B',
		fontSize: '1.2rem'
	},
	noDataMessage: {
		textAlign: 'center',
		marginTop: theme.spacing(2),
		color: '#666'
	}
}));

export default function LeadCityMarketUpdate({ listing }) {
	const classes = useStyles();

	if (!listing || !listing.City || !listing.Area) {
		return null;
	}
	const date = new Date();
	date.setMonth(date.getMonth() - 1); // Set to last month
	const lastMonth = date.toLocaleString('default', { month: 'long', year: 'numeric' });

	const twoMonthsAgoDate = new Date();
	twoMonthsAgoDate.setMonth(twoMonthsAgoDate.getMonth() - 2);
	const twoMonthsAgo = twoMonthsAgoDate.toLocaleString('default', {
		month: 'long',
		year: 'numeric'
	});

	const getPropertyTypeKey = (dwellingClassification, type) => {
		dwellingClassification = dwellingClassification?.toLowerCase();
		type = type?.toLowerCase();
		if (dwellingClassification === 'detached') return 'detached';
		if (
			dwellingClassification === 'attached' &&
			['1/2 duplex', 'duplex', 'triplex', 'townhouse'].includes(type)
		)
			return 'townhomes';
		if (
			dwellingClassification === 'attached' &&
			['apartment/', 'apartment/condo', 'apartment complex (10+ units)'].includes(type)
		)
			return 'condo';
		return null;
	};
	console.log(
		'Size of marketDataGVRpercityAllMonths',
		Object.keys(marketDataGVRpercityAllMonths).length
	);
	const marketDataGVRpercity =
		marketDataGVRpercityAllMonths[lastMonth] ||
		marketDataGVRpercityAllMonths[twoMonthsAgo] ||
		{};
	const displayMonth = marketDataGVRpercityAllMonths[lastMonth] ? lastMonth : twoMonthsAgo;
	const propertyTypeKey = getPropertyTypeKey(listing.DwellingClassification, listing.Type);
	if (!propertyTypeKey) {
		return (
			<Paper className={classes.paper} elevation={3}>
				<Box className={classes.root}>
					<Typography variant="h5" className={classes.title}>
						{listing.Area ? listing.Area.toUpperCase() : 'AREA'} MARKET STATISTICS
					</Typography>
					<Typography variant="subtitle1" className={classes.subtitle}>
						{displayMonth.toUpperCase()}
					</Typography>
					<Box className={classes.noDataMessage}>
						<Typography variant="body1">
							No market data available for this property type.
						</Typography>
					</Box>
				</Box>
			</Paper>
		);
	}

	// Safely access nested data with fallbacks
	let data = {};
	try {
		if (marketDataGVRpercity) {
			const areaKey = Object.keys(marketDataGVRpercity).find(
				(key) => key.toLowerCase() === listing.Area?.toLowerCase()
			);

			if (
				areaKey &&
				marketDataGVRpercity[areaKey] &&
				marketDataGVRpercity[areaKey][propertyTypeKey]
			) {
				data = marketDataGVRpercity[areaKey][propertyTypeKey];
			}
		}
	} catch (error) {
		console.error('Error accessing market data:', error);
	}

	// Support for new data format
	const isNewDataFormat =
		data && (data.active_listings !== undefined || data.benchmark_price !== undefined);

	// Check if we have any valid data to display
	const hasValidData = data && Object.keys(data).length > 0;

	if (!hasValidData) {
		return null; // Don't show anything when no data is available
	}

	const getPropertyIcon = (propertyType) => {
		if (!propertyType) return null;

		propertyType = propertyType.toLowerCase();
		if (propertyType === 'detached') return <HomeIcon className={classes.icon} />;
		if (propertyType === 'townhomes') return <BusinessIcon className={classes.icon} />;
		if (propertyType === 'condo') return <ApartmentIcon className={classes.icon} />;
		return <HomeIcon className={classes.icon} />; // Default fallback
	};

	// Helper function to safely get data values with fallbacks
	const getDataValue = (dataObj, year, fallback = 'N/A') => {
		if (isNewDataFormat) {
			// Handle new data format
			if (dataObj === 'benchmark_price') return data.benchmark_price || fallback;
			if (dataObj === 'active_listings') return data.active_listings || fallback;
			if (dataObj === 'sales') return data.sales || fallback;
			return fallback;
		} else {
			// Handle old data format
			if (!dataObj || !dataObj[year]) return fallback;
			return dataObj[year];
		}
	};

	// Helper function to get year-over-year change
	const getYearOverYearChange = (metric) => {
		if (isNewDataFormat) {
			return data.one_year_change || '';
		} else {
			return data[metric] && data[metric]['one-year-change']
				? data[metric]['one-year-change']
				: '';
		}
	};

	// Helper function to determine color for year-over-year change
	const getChangeColor = (changeStr, isReversed = false) => {
		if (!changeStr) return '#666'; // Neutral color for no data

		const isPositive = changeStr.includes('+');

		if (isReversed) {
			// For metrics where decrease is good (like days on market)
			return isPositive ? '#d32f2f' : '#2e7d32';
		} else {
			// For metrics where increase is good
			return isPositive ? '#2e7d32' : '#d32f2f';
		}
	};

	function calculateSalesRatio(sales, activeListings) {
		// Ensure both values are numbers
		sales = Number(sales);
		activeListings = Number(activeListings);

		if (isNaN(sales) || isNaN(activeListings) || activeListings === 0) {
			return 0; // Avoid division by zero or NaN results
		}

		const ratio = (100 * sales) / activeListings;
		return parseFloat(ratio.toFixed(1));
	}

	return (
		<Paper className={classes.paper} elevation={3}>
			<Box className={classes.root}>
				<Typography variant="h5" className={classes.title}>
					{listing.Area ? listing.Area.toUpperCase() : 'AREA'} MARKET STATISTICS
				</Typography>
				<Typography variant="subtitle1" className={classes.subtitle}>
					{displayMonth.toUpperCase()}
				</Typography>

				<Box className={classes.propertyTypeSection}>
					{getPropertyIcon(propertyTypeKey)}
					<Typography variant="h6" className={classes.propertyTypeTitle}>
						{propertyTypeKey ? propertyTypeKey.toUpperCase() : 'PROPERTY'}
					</Typography>

					<Grid container spacing={3}>
						{/* Benchmark Price */}
						<Grid item xs={12} sm={6} md={4}>
							<Box
								className={classes.statItem}
								style={{
									padding: '16px',
									borderRadius: '8px',
									backgroundColor: '#f8f9fa',
									height: '100%'
								}}
							>
								<Typography
									className={classes.statLabel}
									style={{ marginBottom: '8px', fontWeight: 500 }}
								>
									Benchmark Price
								</Typography>
								<Typography
									className={classes.statValue}
									style={{ fontSize: '1.3rem', marginBottom: '4px' }}
								>
									{isNewDataFormat
										? data.benchmark_price
										: getDataValue(data.mls_hpi_benchmark_price, '2025')}
								</Typography>
								{isNewDataFormat && data.one_year_change && (
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
										mt={1}
									>
										<Typography
											variant="caption"
											style={{
												color: getChangeColor(
													data.one_year_change.includes('-')
														? `-${data.one_year_change}`
														: `+${data.one_year_change}`
												),
												fontWeight: 'bold'
											}}
										>
											Year-over-year change: {data.one_year_change}
										</Typography>
									</Box>
								)}
								{!isNewDataFormat && (
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
										mt={1}
									>
										<Typography
											variant="caption"
											style={{ color: '#666', marginRight: '6px' }}
										>
											Last Year:{' '}
											{getDataValue(data.mls_hpi_benchmark_price, '2024')}
										</Typography>
										{data.mls_hpi_benchmark_price &&
											data.mls_hpi_benchmark_price['one-year-change'] && (
												<Typography
													variant="caption"
													style={{
														color: getChangeColor(
															data.mls_hpi_benchmark_price[
																'one-year-change'
															]
														),
														fontWeight: 'bold'
													}}
												>
													(
													{
														data.mls_hpi_benchmark_price[
															'one-year-change'
														]
													}
													)
												</Typography>
											)}
									</Box>
								)}
							</Box>
						</Grid>

						{/* Total Sales */}
						<Grid item xs={12} sm={6} md={4}>
							<Box
								className={classes.statItem}
								style={{
									padding: '16px',
									borderRadius: '8px',
									backgroundColor: '#f8f9fa',
									height: '100%'
								}}
							>
								<Typography
									className={classes.statLabel}
									style={{ marginBottom: '8px', fontWeight: 500 }}
								>
									Sales
								</Typography>
								<Typography
									className={classes.statValue}
									style={{ fontSize: '1.3rem', marginBottom: '4px' }}
								>
									{isNewDataFormat
										? data.sales
										: getDataValue(data.sales, '2025')}
								</Typography>
								{!isNewDataFormat && (
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
										mt={1}
									>
										<Typography
											variant="caption"
											style={{ color: '#666', marginRight: '6px' }}
										>
											Last Year: {getDataValue(data.sales, '2024')}
										</Typography>
										{data.sales && data.sales['one-year-change'] && (
											<Typography
												variant="caption"
												style={{
													color: getChangeColor(
														data.sales['one-year-change']
													),
													fontWeight: 'bold'
												}}
											>
												({data.sales['one-year-change']})
											</Typography>
										)}
									</Box>
								)}
							</Box>
						</Grid>

						{/* Active Listings */}
						<Grid item xs={12} sm={6} md={4}>
							<Box
								className={classes.statItem}
								style={{
									padding: '16px',
									borderRadius: '8px',
									backgroundColor: '#f8f9fa',
									height: '100%'
								}}
							>
								<Typography
									className={classes.statLabel}
									style={{ marginBottom: '8px', fontWeight: 500 }}
								>
									Total Active Listings
								</Typography>
								<Typography
									className={classes.statValue}
									style={{ fontSize: '1.3rem', marginBottom: '4px' }}
								>
									{isNewDataFormat
										? data.active_listings
										: getDataValue(data.total_active_listings, '2025')}
								</Typography>
								{!isNewDataFormat && (
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
										mt={1}
									>
										<Typography
											variant="caption"
											style={{ color: '#666', marginRight: '6px' }}
										>
											Last Year:{' '}
											{getDataValue(data.total_active_listings, '2024')}
										</Typography>
										{data.total_active_listings &&
											data.total_active_listings['one-year-change'] && (
												<Typography
													variant="caption"
													style={{
														color: getChangeColor(
															data.total_active_listings[
																'one-year-change'
															]
														),
														fontWeight: 'bold'
													}}
												>
													({data.total_active_listings['one-year-change']}
													)
												</Typography>
											)}
									</Box>
								)}
							</Box>
						</Grid>

						{/* Days on Market - Only show for old data format */}
						{!isNewDataFormat && data.days_on_market_average && (
							<Grid item xs={12} sm={6} md={4}>
								<Box
									className={classes.statItem}
									style={{
										padding: '16px',
										borderRadius: '8px',
										backgroundColor: '#f8f9fa',
										height: '100%'
									}}
								>
									<Typography
										className={classes.statLabel}
										style={{ marginBottom: '8px', fontWeight: 500 }}
									>
										Days on Market (Avg)
									</Typography>
									<Typography
										className={classes.statValue}
										style={{ fontSize: '1.3rem', marginBottom: '4px' }}
									>
										{getDataValue(data.days_on_market_average, '2025')}
									</Typography>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
										mt={1}
									>
										<Typography
											variant="caption"
											style={{ color: '#666', marginRight: '6px' }}
										>
											Last Year:{' '}
											{getDataValue(data.days_on_market_average, '2024')}
										</Typography>
										{data.days_on_market_average &&
											data.days_on_market_average['one-year-change'] && (
												<Typography
													variant="caption"
													style={{
														color: getChangeColor(
															data.days_on_market_average[
																'one-year-change'
															],
															true
														),
														fontWeight: 'bold'
													}}
												>
													(
													{data.days_on_market_average['one-year-change']}
													)
												</Typography>
											)}
									</Box>
								</Box>
							</Grid>
						)}

						{/* Sales to Active Listings Ratio */}
						{((isNewDataFormat && data.sales && data.active_listings) ||
							(!isNewDataFormat && data.sales && data.total_active_listings)) && (
							<Grid item xs={12} sm={6} md={4}>
								<Box
									className={classes.statItem}
									style={{
										padding: '16px',
										borderRadius: '8px',
										backgroundColor: '#f8f9fa',
										height: '100%'
									}}
								>
									<Typography
										className={classes.statLabel}
										style={{ marginBottom: '8px', fontWeight: 500 }}
									>
										Sales to Active Listings Ratio
									</Typography>
									<Typography
										className={classes.statValue}
										style={{ fontSize: '1.3rem', marginBottom: '4px' }}
									>
										{isNewDataFormat
											? calculateSalesRatio(data.sales, data.active_listings)
											: calculateSalesRatio(
													getDataValue(data.sales, '2025', 0),
													getDataValue(
														data.total_active_listings,
														'2025',
														0
													)
											  )}
										%
									</Typography>
									{!isNewDataFormat && (
										<Box
											display="flex"
											alignItems="center"
											justifyContent="center"
											mt={1}
										>
											<Typography variant="caption" style={{ color: '#666' }}>
												Last Year:{' '}
												{calculateSalesRatio(
													getDataValue(data.sales, '2024', 0),
													getDataValue(
														data.total_active_listings,
														'2024',
														0
													)
												)}
												%
											</Typography>
										</Box>
									)}
								</Box>
							</Grid>
						)}
					</Grid>
				</Box>
				<Typography
					variant="body2"
					align="center"
					style={{ marginTop: '16px', color: '#666', fontStyle: 'italic' }}
				>
					Data source: Real Estate Board of Greater Vancouver
				</Typography>
			</Box>
		</Paper>
	);
}
