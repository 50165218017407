import React from 'react';
import {
	Typography,
	Box,
	Grid,
	Paper,
	makeStyles,
	Divider,
	CircularProgress
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import { currencyParser } from '../../../helper/parser';
import SalesRatioProgressBar from './SalesRatioProgressBar';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		marginBottom: theme.spacing(3)
	},
	root: {
		padding: theme.spacing(3),
		width: '100%'
	},
	title: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(3),
		color: '#333',
		textAlign: 'center'
	},
	subtitle: {
		color: '#666',
		marginBottom: theme.spacing(4),
		textAlign: 'center'
	},
	propertyTypeSection: {
		textAlign: 'center',
		marginBottom: theme.spacing(4)
	},
	propertyTypeTitle: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
		color: '#004F2B'
	},
	statsGrid: {
		marginBottom: theme.spacing(2)
	},
	statItem: {
		marginBottom: theme.spacing(1)
	},
	statLabel: {
		color: '#666',
		fontSize: '0.9rem'
	},
	statValue: {
		fontWeight: 'bold',
		fontSize: '1.1rem',
		color: '#333'
	},
	icon: {
		fontSize: 40,
		color: '#004F2B',
		marginBottom: theme.spacing(1)
	},
	cityStats: {
		marginTop: theme.spacing(4),
		padding: theme.spacing(2),
		backgroundColor: '#f5f5f5',
		borderRadius: theme.spacing(1)
	},
	ratioGrid: {
		textAlign: 'center'
	},
	ratioValue: {
		fontWeight: 'bold',
		color: '#004F2B',
		fontSize: '1.2rem'
	},
	noDataMessage: {
		textAlign: 'center',
		marginTop: theme.spacing(2),
		color: '#666'
	}
}));

export default function LeadCityMarketUpdate({ listing }) {
	const classes = useStyles();

	const benchmarkPriceGVRpercityAllMonths = {
		'February 2025': {
			detached: {
				'Lower Mainland': {
					benchmarkPrice: 1792400
				},
				'Greater Vancouver': {
					benchmarkPrice: 2006100
				},
				'Bowen Island': {
					benchmarkPrice: 1507600
				},
				'Burnaby East': {
					benchmarkPrice: 1959700
				},
				'Burnaby North': {
					benchmarkPrice: 2085400
				},
				'Burnaby South': {
					benchmarkPrice: 2207300
				},
				Coquitlam: {
					benchmarkPrice: 1796600
				},
				Ladner: {
					benchmarkPrice: 1398600
				},
				'Maple Ridge': {
					benchmarkPrice: 1250400
				},
				'New Westminster': {
					benchmarkPrice: 1574200
				},
				'North Vancouver': {
					benchmarkPrice: 2232600
				},
				'Pitt Meadows': {
					benchmarkPrice: 1179600
				},
				'Port Coquitlam': {
					benchmarkPrice: 1409400
				},
				'Port Moody': {
					benchmarkPrice: 2089700
				},
				Richmond: {
					benchmarkPrice: 2139000
				},
				Squamish: {
					benchmarkPrice: 1464000
				},
				'Sunshine Coast': {
					benchmarkPrice: 927700
				},
				Tsawwassen: {
					benchmarkPrice: 1654800
				},
				'Vancouver East': {
					benchmarkPrice: 1862500
				},
				'Vancouver West': {
					benchmarkPrice: 3493200
				},
				'West Vancouver': {
					benchmarkPrice: 3127700
				},
				Whistler: {
					benchmarkPrice: 2159300
				}
			},
			attached: {
				'Lower Mainland': {
					benchmarkPrice: 959200
				},
				'Greater Vancouver': {
					benchmarkPrice: 1087100
				},
				'Burnaby East': {
					benchmarkPrice: 915800
				},
				'Burnaby North': {
					benchmarkPrice: 911000
				},
				'Burnaby South': {
					benchmarkPrice: 1108700
				},
				Coquitlam: {
					benchmarkPrice: 1056100
				},
				Ladner: {
					benchmarkPrice: 1041400
				},
				'Maple Ridge': {
					benchmarkPrice: 771100
				},
				'New Westminster': {
					benchmarkPrice: 950700
				},
				'North Vancouver': {
					benchmarkPrice: 1366100
				},
				'Pitt Meadows': {
					benchmarkPrice: 836400
				},
				'Port Coquitlam': {
					benchmarkPrice: 956300
				},
				'Port Moody': {
					benchmarkPrice: 1011300
				},
				Richmond: {
					benchmarkPrice: 1109300
				},
				Squamish: {
					benchmarkPrice: 863200
				},
				'Sunshine Coast': {
					benchmarkPrice: 629200
				},
				Tsawwassen: {
					benchmarkPrice: 1007800
				},
				'Vancouver East': {
					benchmarkPrice: 1169800
				},
				'Vancouver West': {
					benchmarkPrice: 1414300
				},
				Whistler: {
					benchmarkPrice: 1384800
				}
			},
			apartment: {
				'Lower Mainland': {
					benchmarkPrice: 695000
				},
				'Greater Vancouver': {
					benchmarkPrice: 747500
				},
				'Burnaby East': {
					benchmarkPrice: 784900
				},
				'Burnaby North': {
					benchmarkPrice: 732000
				},
				'Burnaby South': {
					benchmarkPrice: 823100
				},
				Coquitlam: {
					benchmarkPrice: 732000
				},
				Ladner: {
					benchmarkPrice: 624500
				},
				'Maple Ridge': {
					benchmarkPrice: 522500
				},
				'New Westminster': {
					benchmarkPrice: 657600
				},
				'North Vancouver': {
					benchmarkPrice: 804100
				},
				'Pitt Meadows': {
					benchmarkPrice: 599800
				},
				'Port Coquitlam': {
					benchmarkPrice: 630700
				},
				'Port Moody': {
					benchmarkPrice: 718000
				},
				Richmond: {
					benchmarkPrice: 733800
				},
				Squamish: {
					benchmarkPrice: 544000
				},
				'Sunshine Coast': {
					benchmarkPrice: 447000
				},
				Tsawwassen: {
					benchmarkPrice: 619200
				},
				'Vancouver East': {
					benchmarkPrice: 684800
				},
				'Vancouver West': {
					benchmarkPrice: 815700
				},
				'West Vancouver': {
					benchmarkPrice: 1215800
				},
				Whistler: {
					benchmarkPrice: 588000
				}
			}
		}
	};
	const marketDataGVRpercityAllMonths = {
		'February 2025': {
			detached: {
				'Bowen Island': {
					listings: 6,
					sales: 7,
					salesToListings: '117%',
					median_selling_price: 'n/a'
				},
				Burnaby: {
					listings: 112,
					sales: 35,
					salesToListings: '31%',
					median_selling_price: '$1,950,000'
				},
				Coquitlam: {
					listings: 112,
					sales: 40,
					salesToListings: '36%',
					median_selling_price: '$1,722,500'
				},
				'Delta - South': {
					listings: 73,
					sales: 17,
					salesToListings: '23%',
					median_selling_price: 'n/a'
				},
				'Islands - Gulf': {
					listings: 11,
					sales: 4,
					salesToListings: '36%',
					median_selling_price: 'n/a'
				},
				'Maple Ridge/Pitt Meadows': {
					listings: 181,
					sales: 63,
					salesToListings: '35%',
					median_selling_price: '$1,400,000'
				},
				'New Westminster': {
					listings: 26,
					sales: 11,
					salesToListings: '42%',
					median_selling_price: 'n/a'
				},
				'North Vancouver': {
					listings: 108,
					sales: 48,
					salesToListings: '44%',
					median_selling_price: '$2,262,751'
				},
				'Port Coquitlam': {
					listings: 43,
					sales: 18,
					salesToListings: '42%',
					median_selling_price: 'n/a'
				},
				'Port Moody/Belcarra': {
					listings: 31,
					sales: 10,
					salesToListings: '32%',
					median_selling_price: 'n/a'
				},
				Richmond: {
					listings: 153,
					sales: 41,
					salesToListings: '27%',
					median_selling_price: '$2,288,000'
				},
				Squamish: {
					listings: 24,
					sales: 18,
					salesToListings: '75%',
					median_selling_price: 'n/a'
				},
				'Sunshine Coast': {
					listings: 74,
					sales: 39,
					salesToListings: '53%',
					median_selling_price: '$875,000'
				},
				'Vancouver East': {
					listings: 171,
					sales: 54,
					salesToListings: '32%',
					median_selling_price: '$1,900,000'
				},
				'Vancouver West': {
					listings: 172,
					sales: 36,
					salesToListings: '21%',
					median_selling_price: '$3,550,000'
				},
				'West Vancouver/Howe Sound': {
					listings: 126,
					sales: 28,
					salesToListings: '22%',
					median_selling_price: '$3,375,000'
				},
				'Whistler/Pemberton': {
					listings: 21,
					sales: 8,
					salesToListings: '38%',
					median_selling_price: 'n/a'
				}
			},
			attached: {
				'Bowen Island': {
					listings: 0,
					sales: 0,
					salesToListings: 'n/a',
					median_selling_price: 'n/a'
				},
				Burnaby: {
					listings: 88,
					sales: 34,
					salesToListings: '39%',
					median_selling_price: '$1,099,500'
				},
				Coquitlam: {
					listings: 92,
					sales: 33,
					salesToListings: '36%',
					median_selling_price: '$1,249,900'
				},
				'Delta - South': {
					listings: 39,
					sales: 17,
					salesToListings: '44%',
					median_selling_price: 'n/a'
				},
				'Islands - Gulf': {
					listings: 0,
					sales: 0,
					salesToListings: 'n/a',
					median_selling_price: 'n/a'
				},
				'Maple Ridge/Pitt Meadows': {
					listings: 72,
					sales: 43,
					salesToListings: '60%',
					median_selling_price: '$822,000'
				},
				'New Westminster': {
					listings: 19,
					sales: 9,
					salesToListings: '47%',
					median_selling_price: 'n/a'
				},
				'North Vancouver': {
					listings: 67,
					sales: 27,
					salesToListings: '40%',
					median_selling_price: '$1,335,000'
				},
				'Port Coquitlam': {
					listings: 32,
					sales: 12,
					salesToListings: '38%',
					median_selling_price: 'n/a'
				},
				'Port Moody/Belcarra': {
					listings: 19,
					sales: 10,
					salesToListings: '53%',
					median_selling_price: 'n/a'
				},
				Richmond: {
					listings: 125,
					sales: 39,
					salesToListings: '31%',
					median_selling_price: '$1,038,000'
				},
				Squamish: {
					listings: 30,
					sales: 23,
					salesToListings: '77%',
					median_selling_price: '$1,100,000'
				},
				'Sunshine Coast': {
					listings: 10,
					sales: 5,
					salesToListings: '50%',
					median_selling_price: 'n/a'
				},
				'Vancouver East': {
					listings: 148,
					sales: 58,
					salesToListings: '39%',
					median_selling_price: '$1,358,500'
				},
				'Vancouver West': {
					listings: 141,
					sales: 35,
					salesToListings: '25%',
					median_selling_price: '$1,525,000'
				},
				'West Vancouver/Howe Sound': {
					listings: 17,
					sales: 4,
					salesToListings: '24%',
					median_selling_price: 'n/a'
				},
				'Whistler/Pemberton': {
					listings: 28,
					sales: 10,
					salesToListings: '36%',
					median_selling_price: 'n/a'
				}
			},
			apartment: {
				'Bowen Island': {
					listings: 0,
					sales: 0,
					salesToListings: 'n/a',
					median_selling_price: 'n/a'
				},
				Burnaby: {
					listings: 431,
					sales: 155,
					salesToListings: '36%',
					median_selling_price: '$740,000'
				},
				Coquitlam: {
					listings: 263,
					sales: 88,
					salesToListings: '33%',
					median_selling_price: '$685,000'
				},
				'Delta - South': {
					listings: 51,
					sales: 22,
					salesToListings: '43%',
					median_selling_price: '$732,500'
				},
				'Islands - Gulf': {
					listings: 0,
					sales: 2,
					salesToListings: 'n/a',
					median_selling_price: 'n/a'
				},
				'Maple Ridge/Pitt Meadows': {
					listings: 88,
					sales: 43,
					salesToListings: '49%',
					median_selling_price: '$533,476'
				},
				'New Westminster': {
					listings: 161,
					sales: 68,
					salesToListings: '42%',
					median_selling_price: '$580,000'
				},
				'North Vancouver': {
					listings: 172,
					sales: 78,
					salesToListings: '45%',
					median_selling_price: '$779,500'
				},
				'Port Coquitlam': {
					listings: 52,
					sales: 28,
					salesToListings: '54%',
					median_selling_price: '$580,000'
				},
				'Port Moody/Belcarra': {
					listings: 62,
					sales: 19,
					salesToListings: '31%',
					median_selling_price: 'n/a'
				},
				Richmond: {
					listings: 317,
					sales: 98,
					salesToListings: '31%',
					median_selling_price: '$664,000'
				},
				Squamish: {
					listings: 31,
					sales: 10,
					salesToListings: '32%',
					median_selling_price: 'n/a'
				},
				'Sunshine Coast': {
					listings: 7,
					sales: 3,
					salesToListings: '43%',
					median_selling_price: 'n/a'
				},
				'Vancouver East': {
					listings: 238,
					sales: 92,
					salesToListings: '39%',
					median_selling_price: '$702,500'
				},
				'Vancouver West': {
					listings: 751,
					sales: 236,
					salesToListings: '31%',
					median_selling_price: '$817,500'
				},
				'West Vancouver/Howe Sound': {
					listings: 29,
					sales: 7,
					salesToListings: '24%',
					median_selling_price: 'n/a'
				},
				'Whistler/Pemberton': {
					listings: 31,
					sales: 27,
					salesToListings: '87%',
					median_selling_price: '$635,000'
				}
			}
		}
	};

	const marketDataFVRBpercityAllMonths = {
		'February 2025': {
			detached: {
				Abbotsford: {
					sales: 48,
					newListings: 160,
					activeListings: 384,
					benchmarkPrice: 1204100,
					medianPrice: 1267500,
					averagePrice: 1243470
				},
				Mission: {
					sales: 18,
					newListings: 63,
					activeListings: 220,
					benchmarkPrice: 1056200,
					medianPrice: 1039500,
					averagePrice: 1062632
				},
				'South Surrey White Rock': {
					sales: 39,
					newListings: 163,
					activeListings: 454,
					benchmarkPrice: 1844400,
					medianPrice: 1900000,
					averagePrice: 2117166
				},
				Langley: {
					sales: 57,
					newListings: 194,
					activeListings: 400,
					benchmarkPrice: 1617700,
					medianPrice: 1450000,
					averagePrice: 1615749
				},
				'N. Delta': {
					sales: 16,
					newListings: 85,
					activeListings: 181,
					benchmarkPrice: 1398800,
					medianPrice: 1385000,
					averagePrice: 1475437
				},
				'City of Surrey - Combined*': {
					sales: 124,
					benchmarkPrice: 1622200,
					averagePrice: 1698154
				},
				'Surrey - Central': {
					sales: 46,
					newListings: 213,
					activeListings: 598,
					benchmarkPrice: 1520800,
					medianPrice: 1545500,
					averagePrice: 1610978
				},
				Cloverdale: {
					sales: 28,
					newListings: 75,
					activeListings: 150,
					benchmarkPrice: 1529200,
					medianPrice: 1359900,
					averagePrice: 1439764
				},
				'North Surrey': {
					sales: 18,
					newListings: 134,
					activeListings: 369,
					benchmarkPrice: 1524700,
					medianPrice: 1390000,
					averagePrice: 1565944
				}
			},
			attached: {
				Abbotsford: {
					sales: 36,
					newListings: 77,
					activeListings: 165,
					benchmarkPrice: 657600,
					medianPrice: 702500,
					averagePrice: 700266
				},
				Mission: {
					sales: 3,
					newListings: 10,
					activeListings: 36,
					benchmarkPrice: 667500,
					medianPrice: 799000,
					averagePrice: 777966
				},
				'South Surrey White Rock': {
					sales: 52,
					newListings: 121,
					activeListings: 219,
					benchmarkPrice: 927500,
					medianPrice: 975000,
					averagePrice: 1017718
				},
				Langley: {
					sales: 65,
					newListings: 145,
					activeListings: 234,
					benchmarkPrice: 864500,
					medianPrice: 870000,
					averagePrice: 882386
				},
				'N. Delta': {
					sales: 6,
					newListings: 20,
					activeListings: 39,
					benchmarkPrice: 899600,
					medianPrice: 920000,
					averagePrice: 917833
				},
				'City of Surrey - Combined*': {
					sales: 145,
					benchmarkPrice: 842700,
					averagePrice: 889712
				},
				'Surrey - Central': {
					sales: 54,
					newListings: 158,
					activeListings: 388,
					benchmarkPrice: 817600,
					medianPrice: 837500,
					averagePrice: 835103
				},
				Cloverdale: {
					sales: 24,
					newListings: 54,
					activeListings: 94,
					benchmarkPrice: 826500,
					medianPrice: 796500,
					averagePrice: 802804
				},
				'North Surrey': {
					sales: 15,
					newListings: 49,
					activeListings: 111,
					benchmarkPrice: 749000,
					medianPrice: 770000,
					averagePrice: 781606
				}
			},
			apartment: {
				Abbotsford: {
					sales: 48,
					newListings: 118,
					activeListings: 247,
					benchmarkPrice: 440100,
					medianPrice: 435000,
					averagePrice: 440877
				},
				Mission: {
					sales: 2,
					newListings: 29,
					activeListings: 51,
					benchmarkPrice: 453200,
					medianPrice: 448000,
					averagePrice: 448000
				},
				'South Surrey White Rock': {
					sales: 53,
					newListings: 175,
					activeListings: 365,
					benchmarkPrice: 609400,
					medianPrice: 615000,
					averagePrice: 757822
				},
				Langley: {
					sales: 78,
					newListings: 217,
					activeListings: 403,
					benchmarkPrice: 598500,
					medianPrice: 555000,
					averagePrice: 568720
				},
				'N. Delta': {
					sales: 5,
					newListings: 23,
					activeListings: 52,
					benchmarkPrice: 550500,
					medianPrice: 595000,
					averagePrice: 630000
				},
				'City of Surrey - Combined*': {
					sales: 126,
					benchmarkPrice: 525700,
					averagePrice: 575807
				},
				'Surrey - Central': {
					sales: 25,
					newListings: 83,
					activeListings: 212,
					benchmarkPrice: 546300,
					medianPrice: 555000,
					averagePrice: 558210
				},
				Cloverdale: {
					sales: 10,
					newListings: 42,
					activeListings: 60,
					benchmarkPrice: 582700,
					medianPrice: 547450,
					averagePrice: 627190
				},
				'North Surrey': {
					sales: 60,
					newListings: 270,
					activeListings: 723,
					benchmarkPrice: 481700,
					medianPrice: 477000,
					averagePrice: 490698
				}
			}
		}
	};
	if (!listing || !listing.City || !listing.Area) {
		return null;
	}
	const date = new Date();
	date.setMonth(date.getMonth() - 1); // Set to last month
	const lastMonth = date.toLocaleString('default', { month: 'long', year: 'numeric' });

	const getPropertyTypeKey = (dwellingClassification, type) => {
		dwellingClassification = dwellingClassification?.toLowerCase();
		type = type?.toLowerCase();
		if (dwellingClassification === 'detached') return 'detached';
		if (
			dwellingClassification === 'attached' &&
			['1/2 duplex', 'duplex', 'triplex', 'townhouse'].includes(type)
		)
			return 'attached';
		if (
			dwellingClassification === 'attached' &&
			['apartment/', 'apartment/condo', 'apartment complex (10+ units)'].includes(type)
		)
			return 'apartment';
		return null;
	};
	const marketDataFVRBpercity = marketDataFVRBpercityAllMonths[lastMonth];
	const marketDataGVRpercity = marketDataGVRpercityAllMonths[lastMonth];
	const propertyTypeKey = getPropertyTypeKey(listing.DwellingClassification, listing.Type);
	if (!propertyTypeKey || !marketDataGVRpercity[propertyTypeKey]) {
		return null;
	}

	// Use Area as fallback if City is not available
	let locationKey = listing.Area;
	let cityOrAreaBanner = listing.Area;

	// Function to find data by partial key match
	function findDataByLocationKey(marketData, propertyTypeKey, locationKey, city) {
		let data = marketData[propertyTypeKey];
		if (!data) return null;

		// Convert keys to lowercase for case-insensitive comparison
		const lowerCaseLocationKey = locationKey.toLowerCase();
		const lowerCaseCity = city.toLowerCase();

		// Check for exact match with locationKey
		const exactMatchKey = Object.keys(data).find(
			(key) => key.toLowerCase() === lowerCaseLocationKey
		);
		if (exactMatchKey) {
			return data[exactMatchKey];
		}

		// Check for exact match with city
		const exactMatchCity = Object.keys(data).find((key) => key.toLowerCase() === lowerCaseCity);
		if (exactMatchCity) {
			return data[exactMatchCity];
		}

		// Find a key that includes the locationKey
		const matchingKey = Object.keys(data).find((key) =>
			key.toLowerCase().includes(lowerCaseLocationKey)
		);
		if (matchingKey) {
			data = data[matchingKey];
			data.matchingKey = matchingKey;
			return data;
		}
		return null;
	}

	// Attempt to get data from both sources
	let data =
		findDataByLocationKey(marketDataGVRpercity, propertyTypeKey, locationKey, listing.City) ||
		findDataByLocationKey(marketDataFVRBpercity, propertyTypeKey, locationKey, listing.City);

	// Ensure data is initialized
	data = data || {};

	// Safely access the benchmark price with optional chaining
	let benchmarkPrice =
		benchmarkPriceGVRpercityAllMonths?.[lastMonth]?.[propertyTypeKey]?.[locationKey]
			?.benchmarkPrice || null;
	if (!benchmarkPrice) {
		benchmarkPrice =
			benchmarkPriceGVRpercityAllMonths?.[lastMonth]?.[propertyTypeKey]?.[listing.Area]
				?.benchmarkPrice || null;
	}
	// Assign the benchmark price to data
	if (data) {
		data.benchmarkPrice = benchmarkPrice;
	}

	if (!data) {
		return null;
	}

	const getPropertyIcon = (propertyType) => {
		if (propertyType === 'detached') return <HomeIcon className={classes.icon} />;
		if (propertyType === 'attached') return <BusinessIcon className={classes.icon} />;
		if (propertyType === 'apartment') return <ApartmentIcon className={classes.icon} />;
		return null;
	};

	function calculateSalesRatio(sales, activeListings) {
		if (activeListings === 0) {
			return 0; // Avoid division by zero
		}

		const ratio = (100 * sales) / activeListings;
		return parseFloat(ratio.toFixed(1));
	}

	return (
		<Paper className={classes.paper} elevation={3}>
			<Box className={classes.root}>
				<Typography variant="h5" className={classes.title}>
					{data.matchingKey
						? data.matchingKey.toUpperCase()
						: cityOrAreaBanner.toUpperCase()}{' '}
					MARKET STATISTICS
				</Typography>
				<Typography variant="subtitle1" className={classes.subtitle}>
					{lastMonth.toUpperCase()}
				</Typography>

				<Box className={classes.propertyTypeSection}>
					{getPropertyIcon(propertyTypeKey)}
					<Typography variant="h6" className={classes.propertyTypeTitle}>
						{propertyTypeKey.toUpperCase()}
					</Typography>

					<Grid container spacing={3}>
						{data.benchmarkPrice && (
							<Grid item xs={12} sm={4}>
								<Box className={classes.statItem}>
									<Typography className={classes.statLabel}>
										Benchmark Price
									</Typography>
									<Typography className={classes.statValue}>
										{currencyParser(data.benchmarkPrice)}
									</Typography>
								</Box>
							</Grid>
						)}
						{data.sales && (
							<Grid item xs={12} sm={4}>
								<Box className={classes.statItem}>
									<Typography className={classes.statLabel}>
										Total Sales
									</Typography>
									<Typography className={classes.statValue}>
										{data.sales.toLocaleString()}
									</Typography>
								</Box>
							</Grid>
						)}
						{data.listings && (
							<Grid item xs={12} sm={4}>
								<Box className={classes.statItem}>
									<Typography className={classes.statLabel}>
										Active Listings
									</Typography>
									<Typography className={classes.statValue}>
										{data.listings.toLocaleString()}
									</Typography>
								</Box>
							</Grid>
						)}
						{data.newListings && (
							<Grid item xs={12} sm={4}>
								<Box className={classes.statItem}>
									<Typography className={classes.statLabel}>
										New Listings
									</Typography>
									<Typography className={classes.statValue}>
										{data.newListings.toLocaleString()}
									</Typography>
								</Box>
							</Grid>
						)}
						{data.medianPrice && (
							<Grid item xs={12} sm={4}>
								<Box className={classes.statItem}>
									<Typography className={classes.statLabel}>
										Median Price
									</Typography>
									<Typography className={classes.statValue}>
										{currencyParser(data.medianPrice)}
									</Typography>
								</Box>
							</Grid>
						)}
						{data.averagePrice && (
							<Grid item xs={12} sm={4}>
								<Box className={classes.statItem}>
									<Typography className={classes.statLabel}>
										Average Price
									</Typography>
									<Typography className={classes.statValue}>
										{currencyParser(data.averagePrice)}
									</Typography>
								</Box>
							</Grid>
						)}
					</Grid>
				</Box>

				<Box className={classes.cityStats}>
					<Typography variant="h6" align="center" gutterBottom>
						Sales-to-Listings Ratio
					</Typography>

					{data.sales && (data.listings || data.activeListings) ? (
						<>
							<SalesRatioProgressBar
								salesRatio={calculateSalesRatio(
									data.sales,
									data.listings ?? data.activeListings
								)}
								propertyType={propertyTypeKey.toUpperCase() || 'PROPERTY'}
							/>

							<Divider style={{ margin: '16px 0' }} />
						</>
					) : (
						<>
							<Typography className={classes.noDataMessage}>
								Data not available
							</Typography>

							<Divider style={{ margin: '16px 0' }} />
						</>
					)}
				</Box>

				<Typography
					variant="body2"
					align="center"
					style={{ marginTop: '16px', color: '#666' }}
				>
					Data source: Real Estate Board of Greater Vancouver
				</Typography>
			</Box>
		</Paper>
	);
}
